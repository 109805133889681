import CustomButton from 'components/Buttons/CustomButton'
import { useState } from 'react'
import { AlertCircle } from 'react-feather'
import Select from 'react-select'
import { FormGroup, Label, Modal, ModalBody } from 'reactstrap'

export default function ModalPindahGudang({ isOpen, onClose, data }) {
  const [gudang, setGudang] = useState('')
  const [errMsg, setErrMsg] = useState('')

  return (
    <Modal className="p-4" isOpen={isOpen} toggle={onClose} centered>
      <ModalBody>
        <h5>Pindah Gudang</h5>
        <FormGroup>
          <Label>Gudang tujuan</Label>
          <Select
            name="gudang"
            id="gudang"
            value={gudang ?? ''}
            onChange={(e) => {
              setGudang(e.target.value)
              setErrMsg('')
            }}
            placeholder="Pilih gudang tujuan"
          />
        </FormGroup>

        {errMsg && (
          <small className="text-danger">
            <AlertCircle size={15} /> {errMsg}
          </small>
        )}
        <div className="d-flex gap-3">
          <CustomButton
            onClick={() => {
              onClose()
              setErrMsg('')
            }}
            // disabled={result?.isLoading}
            color="primary"
            outline
            block
          >
            Batal
          </CustomButton>
          <CustomButton
            // disabled={result?.isLoading}
            // onClick={handleSubmit}
            color="primary"
            block
          >
            Ubah
          </CustomButton>
        </div>
      </ModalBody>
    </Modal>
  )
}
