import emptyAnggota from 'assets/icons/empty-anggota.svg'
import BreadCrumbs from 'components/breadcrumbs'
import CustomButton from 'components/Buttons/CustomButton'
import PMBPagination from 'components/PMBPagination'
import { TableComponent as DataTable } from 'components/TableComponent'
import { Can } from 'context/Acl'
import { enqueueSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import {
  ArrowRightCircle,
  CheckCircle,
  ChevronDown,
  Edit2,
  Menu,
  MoreVertical,
  Trash2,
} from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Alert,
  Badge,
  Card,
  CardBody,
  CardText,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  UncontrolledDropdown,
} from 'reactstrap'
import { BUSINESS_FARMERS, MANAGE } from 'utils/subjectActions'
import { clearState } from '../forms/formSlice'
import { toggleSidebarFarmer } from '../groups-list/groupsListSlice'
import ModalChangeRole from '../staffList/ModalChangeRole'
import { toggleModal as toggleModalChangeRole } from '../staffList/staffListSlice'
import { useFetchFarmersQuery } from './farmersListApiSlice'
import {
  setFetchOption,
  toggleBanner,
  toggleModal,
  toggleModalDelete,
} from './farmersListSlice'
import ModalChangeName from './ModalChangeName'
import ModalConfirmDelete from './ModalConfirmDelete'

const FarmersTable = ({ groupList }) => {
  const { tag, isOpenBanner, bannerMsg } = useSelector((s) => s.farmersList)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const dataState = location.state

  let [page, setPage] = useState({ page: tag.page })
  const [selectedRow, setSelectedRow] = useState(null)
  const code = window.location.href.substring(
    window.location.href.lastIndexOf('/') + 1,
  )

  const { data, isLoading, refetch } = useFetchFarmersQuery(
    {
      groupCode: code,
      page: tag?.page,
      status: 'JOIN',
      size: tag?.size,
    },
    { skip: !code },
  )

  const { data: dataPending, isLoading: loadingPending } = useFetchFarmersQuery(
    {
      groupCode: code,
      page: tag?.page,
      status: 'PENDING',
    },
    { skip: !code },
  )

  const columns = [
    {
      name: <span>Nama</span>,
      width: '25%',
      cell: (row) => {
        return <span>{row?.name}</span>
      },
    },
    {
      name: <span>Nomor Telepon</span>,
      cell: (row) => {
        return <span>{row?.phoneNumber}</span>
      },
    },
    {
      name: <span>Status</span>,
      cell: (row) => {
        return (
          <Badge
            className={
              row?.status === 'VERIFIED' ? 'badge-verify' : 'badge-danger'
            }
          >
            {row?.status === 'VERIFIED'
              ? 'Terverifikasi'
              : 'Belum Terverfikasi'}
          </Badge>
        )
      },
    },
    {
      name: <span>Peran</span>,
      cell: (row) => {
        return <span>{row?.roleName}</span>
      },
    },
    {
      name: '',
      cell: (row) => {
        return (
          <div className="d-flex gap-2">
            <Can I={MANAGE} this={BUSINESS_FARMERS}>
              <span
                style={{ cursor: 'pointer' }}
                className="fw-semibold text-center"
                onClick={() => {
                  dispatch(toggleModalChangeRole())
                  setSelectedRow(row)
                }}
              >
                Ubah peran
              </span>
            </Can>
            <span
              style={{ cursor: 'pointer' }}
              className="fw-semibold text-center"
              onClick={() => navigate(`/farmers/${row?.userId}`)}
            >
              Lihat Detail
            </span>
          </div>
        )
      },
    },
  ]

  const handleFinishedChangeRole = () => {
    enqueueSnackbar('Peran berhasil diubah.', { variant: 'success' })
    refetch()
  }

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {},
    },
    rows: {
      style: {},
    },
  }

  useEffect(() => {
    dispatch(clearState())
  }, [])

  return (
    <div className="w-100 px-3">
      <div className="d-flex align-items-start gap-2 pt-3">
        <Menu
          onClick={() => dispatch(toggleSidebarFarmer())}
          className="pointer"
        />
        <BreadCrumbs
          breadCrumbActive={
            data?.data?.groupName ??
            data?.data?.items[0]?.groupname ??
            dataState?.name
          }
          breadCrumbParent={'Daftar Anggota'}
          breadCrumbParent2={'Grup'}
          breadCrumbParent2Link={'/farmers/groups'}
          breadCrumbParentLink={'/farmers'}
        />
      </div>

      <ModalChangeName />
      <ModalConfirmDelete />
      <ModalChangeRole
        isSubGroup
        row={selectedRow}
        handleFinishedChangeRole={handleFinishedChangeRole}
      />
      <Card className="border-0 shadow-sm">
        <CardBody>
          <div className="w-100">
            <div className="d-flex justify-content-between align-items-center mb-1">
              {isLoading ? (
                <Spinner color="primary" />
              ) : (
                <h5 className="fw-semibold">
                  {data?.data?.groupName ??
                    data?.data?.items[0]?.groupname ??
                    dataState?.name}{' '}
                  <Can I={MANAGE} this={BUSINESS_FARMERS}>
                    <UncontrolledDropdown group>
                      <DropdownToggle className="bg-transparent border-0">
                        <MoreVertical size={16} className="text-secondary" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => dispatch(toggleModal())}>
                          <Edit2 size={16} className="me-2" /> Ubah Nama Grup
                        </DropdownItem>
                        <DropdownItem
                          className="text-danger"
                          onClick={() => dispatch(toggleModalDelete())}
                        >
                          <Trash2 size={16} className="me-2" /> Hapus Grup
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Can>
                </h5>
              )}

              {data?.data?.totalItems < 1 ? (
                <></>
              ) : (
                <Can I={MANAGE} this={BUSINESS_FARMERS}>
                  <CustomButton
                    onClick={() =>
                      navigate('/farmers/check', { state: dataState })
                    }
                    size="sm"
                    color="primary"
                  >
                    + Tambah Anggota
                  </CustomButton>
                </Can>
              )}
            </div>
            <div>
              <Alert
                isOpen={isOpenBanner}
                toggle={() => dispatch(toggleBanner())}
                color="success"
                className="d-flex align-items-center mt-4 w-100"
              >
                <span>
                  <CheckCircle size={17} /> {bannerMsg}
                </span>
              </Alert>
            </div>
            {data?.data?.totalItems < 1 && dataPending?.data?.totalItems < 1 ? (
              <></>
            ) : (
              <Row className="d-flex flex-row justify-content-start mb-3 align-items-stretch gap-3">
                <Col lg="2" md="6" sm="12" className="d-flex">
                  <Card body className="w-100 border-0 shadow-sm">
                    <CardText tag="small" className="fw-semibold">
                      Jumlah Anggota
                    </CardText>
                    <CardText tag="h3" className="text-primary mt-2">
                      {isLoading ? (
                        <Spinner color="primary" />
                      ) : (
                        data?.data?.totalItems
                      )}
                    </CardText>
                  </Card>
                </Col>
                <Col lg="2" md="6" sm="12" className="d-flex">
                  <Card
                    onClick={() => navigate(`/farmers/pending/${code}`)}
                    body
                    style={{ cursor: 'pointer' }}
                    className="w-100 border-0 shadow-sm"
                  >
                    <CardText tag="small" className="fw-semibold">
                      Menunggu Konfirmasi
                    </CardText>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <div className="text-primary h3">
                        {loadingPending ? (
                          <Spinner color="primary" />
                        ) : (
                          dataPending?.data?.totalItems
                        )}
                      </div>
                      <div>
                        <ArrowRightCircle size={20} />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            )}
          </div>
          {data?.data?.totalItems > 0 ? (
            <DataTable
              customStyles={customStyles}
              columns={columns}
              data={data?.data?.items}
              progressPending={isLoading}
              responsive
              persistTableHead
              progressComponent={<Spinner />}
              sortIcon={<ChevronDown />}
              sortServer
              className="border p-0 border-1 rounded-top"
              noDataComponent={
                <p className="my-3 fw-semibold">
                  Belum ada pengguna yang didaftarkan
                </p>
              }
              pagination
              paginationServer
              paginationComponent={(props) => (
                <PMBPagination
                  rowCount={data?.data?.totalItems || 0}
                  currentPage={tag?.page}
                  rowsPerPage={tag?.size}
                  setCurrentPage={(e) => {
                    setPage({ page: e })
                    refetch()
                    dispatch(setFetchOption({ page: e }))
                  }}
                  setRowsPerPage={(e) => {
                    dispatch(setFetchOption({ size: e }))
                  }}
                  props={props}
                />
              )}
            />
          ) : (
            <Card className="bg-light border-0 shadow-sm">
              <CardBody>
                <div className="d-flex flex-column align-items-center gap-4">
                  <img alt="Groups" src={emptyAnggota} />
                  <span>Belum ada anggota di grup ini.</span>
                  <Can I={MANAGE} this={BUSINESS_FARMERS}>
                    <CustomButton
                      onClick={() =>
                        navigate('/farmers/check', { state: dataState })
                      }
                      color="primary"
                    >
                      + Tambah Anggota
                    </CustomButton>
                  </Can>
                </div>
              </CardBody>
            </Card>
          )}
        </CardBody>
      </Card>
    </div>
  )
}

export default FarmersTable
