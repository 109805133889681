import { createApiSlice } from 'app/createApiSlice'
import {
  API_CATCHES,
  API_FARMERS_LIST,
  API_FARMERS_PLOTTING,
  API_USER_DETAIL_VESSEL,
} from 'utils/apiList'

export const farmersListApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'farmersList/api',
  endpoints(builder) {
    return {
      fetchFarmers: builder.query({
        query({ page, status, size = 10, groupCode }) {
          return {
            url: API_FARMERS_LIST,
            params: {
              groupCode,
              page,
              status,
              size,
            },
          }
        },
      }),
      fetchFarmersDetail: builder.query({
        query({ id }) {
          return `${API_FARMERS_LIST}/${id}`
        },
      }),
      getInviteAgent: builder.query({
        query({ id }) {
          return {
            url: `api/v1/bussines/invite-agent`,
            params: {
              id,
            },
          }
        },
      }),
      confirmInviteAgent: builder.mutation({
        query({ id, approved }) {
          return {
            url: `api/v1/bussines/group/invite-agent/${id}/confirm`,
            method: 'POST',
            body: {
              approved,
            },
          }
        },
      }),

      fetchStaffList: builder.query({
        query({ page, size = 10 }) {
          return {
            url: `api/v1/bussines/team`,
            params: {
              page,
              size,
            },
          }
        },
      }),
      fetchFarmersPlot: builder.query({
        query({ id }) {
          return `${API_FARMERS_PLOTTING}?userId=${id}`
        },
      }),
      changeGrupName: builder.mutation({
        query({ name, code }) {
          return {
            url: `api/v1/bussines/group/management/${code}/update`,
            method: 'POST',
            body: {
              name,
            },
          }
        },
      }),
      deleteGroup: builder.mutation({
        query({ code }) {
          return {
            url: `api/v1/bussines/group/management/${code}`,
            method: 'DELETE',
          }
        },
      }),
      setRolePMB: builder.mutation({
        query({ userId, role }) {
          return {
            url: `api/v1/bussines/team/${userId}/set-role`,
            method: 'POST',
            body: {
              rolesId: role,
            },
          }
        },
      }),
      setRoleFarmers: builder.mutation({
        query({ userId, role }) {
          return {
            url: `api/v1/bussines/group/register-farmer/${userId}/set-role`,
            method: 'POST',
            body: {
              rolesId: role,
            },
          }
        },
      }),
      fetchUserVessel: builder.query({
        query({ id }) {
          return `${API_USER_DETAIL_VESSEL}?userId=${id}`
        },
      }),
      fetchUserCatches: builder.query({
        query({ id, page = 1, size = 10 }) {
          return {
            url: API_CATCHES,
            params: {
              userId: id,
              page,
              size,
            },
          }
        },
      }),
      inviteAgent: builder.mutation({
        query({ sellerUserId, agentUserId, isDefault }) {
          return {
            url: 'api/v1/bussines/group/invite-agent',
            method: 'POST',
            body: {
              sellerUserId,
              agentUserId,
              isDefault,
            },
          }
        },
      }),
      setDefaultAgent: builder.mutation({
        query({ id, sellerUserId }) {
          return {
            url: 'api/v1/bussines/group/set-default-agent',
            method: 'POST',
            body: {
              id,
              sellerUserId,
            },
          }
        },
      }),
      deleteAgent: builder.mutation({
        query({ id }) {
          return {
            url: `api/v1/bussines/group/invite-agent/${id}`,
            method: 'DELETE',
          }
        },
      }),
    }
  },
})

export const {
  useFetchFarmersQuery,
  useLazyFetchFarmersQuery,
  useLazyFetchFarmersDetailQuery,
  useFetchFarmersDetailQuery,
  useLazyGetInviteAgentQuery,
  useGetInviteAgentQuery,
  useConfirmInviteAgentMutation,
  useFetchFarmersPlotQuery,
  useChangeGrupNameMutation,
  useDeleteGroupMutation,
  useFetchStaffListQuery,
  useSetRoleFarmersMutation,
  useSetRolePMBMutation,
  useFetchUserVesselQuery,
  useFetchUserCatchesQuery,
  useInviteAgentMutation,
  useSetDefaultAgentMutation,
  useDeleteAgentMutation,
} = farmersListApiSlice
