import CustomButton from 'components/Buttons/CustomButton'
import { Plus } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, CardText, Col, Row } from 'reactstrap'
import { addItem } from '../sellCommoditySlice'
import Item from './Item'
import Item2 from './index2'

const ItemInfo = () => {
  const dispatch = useDispatch()
  const { addItemCount } = useSelector((state) => state.sellCommodity)

  return (
    <Row>
      <Col>
        <h5 className="my-2">Barang yang dijual</h5>
        {addItemCount.map((num, idx) => (
          <Item2 key={idx} fieldNum={num} idx={idx} />
        ))}

        <CustomButton
          size="sm"
          style={{ maxWidth: 186 }}
          color="primary"
          onClick={() => {
            dispatch(addItem())
          }}
        >
          <Plus size={16} />
          <span>Tambah Komoditas</span>
        </CustomButton>
      </Col>
    </Row>
  )
}

export default ItemInfo
