import { ButtonIcon } from 'components/Buttons'

import { HistoryLine } from 'components'
import LoadingV2 from 'components/LoadingV2'
import PMBModalTraceability from 'components/PMBModalTraceability'
import PMBTooltip from 'components/PMBTooltip'
import BreadCrumbs from 'components/breadcrumbs'
import { getLogs } from 'features/public-views/ap2hi/API/ap2hiActions'
import { parseExtraData, PMBFormatDate } from 'helpers'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Check, ChevronDown, Edit, Eye, Map } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, Spinner } from 'reactstrap'
import { newFormatThousandSeparator } from 'utils/formatNumber'
import { BUSINESS_TRIPS, MANAGE } from 'utils/subjectActions'
import ModalApprove from '../list-catches/ModalApprove'
import { getCatchesDetail } from '../list-catches/catchesListAction'
import { useFetchUserCatchDetailByIdQuery } from '../list-catches/catchesListApiSlice'
import {
  setIsOpenModalApprove,
  setIsOpenModalEdit,
} from '../list-catches/catchesListSlice'
import ModalEdit from './ModalEdit'
import ModalMovement from './ModalMovement'
import { setDataMovement, setIsOpenModalDetailMovement } from './detailSlice'

const MIN_MAX_HEIGHT = '320px'

// COMPONENT FOR DISPLAYING CARDS
const InformationCard = ({ title, info }) => (
  <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
    <Card
      className="border-0 shadow-sm"
      style={{
        minHeight: MIN_MAX_HEIGHT,
        maxHeight: MIN_MAX_HEIGHT,
        overflow: 'auto',
      }}
    >
      <CardBody className="d-flex flex-column gap-2">
        <h6>{title}</h6>
        {info?.map(({ label, value }, idx) => (
          <div key={idx} className="row">
            <div className="col-6">
              <span className="fw-semibold text-sm">{label}</span>
            </div>
            <div className="col-6">
              <p className="text-muted text-sm mb-0">{value}</p>
            </div>
          </div>
        ))}
      </CardBody>
    </Card>
  </div>
)

const DetailCathces = () => {
  const dispatch = useDispatch()
  const { isOpenModalApprove, isOpenModalEdit } = useSelector(
    (s) => s.catchesList,
  )

  const manageActionApproveBusinessTrips = useAbilityAccess({
    action: MANAGE,
    subject: BUSINESS_TRIPS,
  })
  const [logs, setLogs] = useState([])
  const [isOpenModalTraceability, setIsOpenModalTraceability] = useState(false)
  const [selectedProductId, setSelectedProductId] = useState(null)

  const handleOpenCloseModalTraceability = (productId = null) => {
    setSelectedProductId(productId)
    setIsOpenModalTraceability((prev) => !prev)
  }

  // const manageActionApproveBusinessVessel = useAbilityAccess({
  //   action: MANAGE,
  //   subject: BUSINESS_VESSELS,
  // })

  const catchId = window.location.href.substring(
    window.location.href.lastIndexOf('/') + 1,
  )

  const { data, isLoading, refetch } = useFetchUserCatchDetailByIdQuery(
    { id: catchId },
    { skip: !catchId },
  )

  // GET MASTER VESSEL BASED ON SHIPNUMBER (NOT FOR AT THE MOMENT / WAITING ADJUSTMENT FROM BE)
  // const shipNumber = data?.items?.[0]?.data?.vessel?.shipNumber
  // const encodedShipNumber = shipNumber ? encodeURIComponent(shipNumber) : null
  // const { data: dataVessel } = useFetchVesselByShipNumberQuery(
  //   { shipNumber: encodedShipNumber },
  //   { skip: !encodedShipNumber },
  // )

  const [rowFish, setRowFish] = useState(null)

  const userName = data?.profile?.name ?? '-'
  const tripInfo = data?.data || {}
  console.log({ tripInfo })
  const vesselInfo = tripInfo?.vessel || {}
  const unit = tripInfo?.fishes?.[0]?.selectedFish?.unit ?? 'kg'

  const tripDuration = moment
    .duration(moment(tripInfo.endFishing).diff(moment(tripInfo.startFishing)))
    .humanize()

  // const departureCoordinates =
  //   tripInfo?.fishes?.[0]?.latitude && tripInfo?.fishes?.[0]?.longitude
  //     ? `${tripInfo?.fishes[0].latitude}, ${tripInfo?.fishes[0].longitude}`
  //     : '-'

  const catchAreasWPP =
    tripInfo?.fishes?.length > 0
      ? [...new Set(tripInfo?.fishes?.map((fish) => fish?.catchArea))].join(
          ', ',
        )
      : '-'

  useEffect(() => {
    if (catchId) {
      dispatch(getCatchesDetail())
      dispatch(getLogs({ page: 0, catchId, size: 99 })).then((res) => {
        const logs = res?.payload?.items?.map((item) => {
          if (item.extraData) {
            item.parsedExtraData = parseExtraData(item.extraData)
          }
          return item
        })
        setLogs(logs)
      })
    }
  }, [catchId])

  const calculateTransferredWeight = (fishId) => {
    // FIND THE MOVEMENTS THAT INCLUDE THIS FISH
    const movementFishes = tripInfo?.moveReceipment
      ?.flatMap((movement) => movement.fishes)
      ?.filter((fish) => fish.id === fishId)

    // SUM THE WEIGHT OF ALL MATCHING FISHES IN THE MOVEMENT
    return movementFishes.reduce((total, fish) => total + (fish.weight || 0), 0)
  }

  const columns = [
    {
      name: <span>Nama Spesies</span>,
      cell: (row) => <span>{row?.selectedFish?.nameItem ?? '-'}</span>,
    },
    {
      name: <span>Kode Tangkapan</span>,
      cell: (row) => <span>{row?.id}</span>,
    },
    {
      name: <span>FAO Code</span>,
      cell: (row) => <span>{row?.selectedFish?.attribute?.code ?? '-'}</span>,
    },
    {
      name: <span>Berat Tangkapan</span>,
      cell: (row) => {
        return (
          <span>
            {newFormatThousandSeparator(row?.amount ?? 0)} {unit}
          </span>
        )
      },
    },
    {
      name: <span>Berat Dialihkan</span>,
      cell: (row) => {
        const transferredWeight = tripInfo?.moveReceipment?.length
          ? `${newFormatThousandSeparator(calculateTransferredWeight(row.id))} ${unit}`
          : '-'

        return <span>{transferredWeight ?? '-'}</span>
      },
    },
    {
      name: <span>Berat Bersih</span>,
      cell: (row) => {
        const transferredWeight = tripInfo?.moveReceipment?.length
          ? `${calculateTransferredWeight(row.id)}`
          : 0

        const totalNett =
          Number(row?.amount ?? 0) - Number(transferredWeight ?? 0)

        return (
          <span>
            {newFormatThousandSeparator(totalNett)} {unit}
          </span>
        )
      },
    },
    {
      name: <span>Waktu Penangkapan</span>,
      cell: (row) => <span>{PMBFormatDate(row?.createdAt)}</span>,
    },
    {
      name: <span>Koordinat Penangkapan</span>,
      cell: (row) => (
        <span>
          {row?.latitude ?? '-'}, {row?.longitude ?? '-'}
        </span>
      ),
    },
    {
      name: <span>Alih Muatan</span>,
      width: '100px',
      cell: (row) => {
        const movementFishes = tripInfo?.moveReceipment
          ?.flatMap((movement) => movement.fishes)
          ?.filter((fish) => fish?.id === row?.id)

        return (
          <div className="d-flex flex-grow-1 justify-content-center align-center">
            <Eye
              size={18}
              disabled={row?.id !== movementFishes?.[0]?.id}
              onClick={() => {
                dispatch(setIsOpenModalDetailMovement())
                dispatch(setDataMovement(movementFishes))
              }}
              id={`icon-detail-alih-muat-${row?.id}`}
              style={{ cursor: 'pointer' }}
            />
            <PMBTooltip
              target={`icon-detail-alih-muat-${row?.id}`}
              text="Lihat Detail"
            />
          </div>
        )
      },
    },
    {
      name: '',
      cell: (row) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Map
            id={`traceability-${row?.id}`}
            onClick={() => handleOpenCloseModalTraceability(row.id)}
            size={18}
            style={{ cursor: 'pointer' }}
          />
          <PMBTooltip
            target={`traceability-${row?.id}`}
            text="Lihat Keterlacakan"
          />

          {/* HANDLE EDIT */}
          {!tripInfo?.approved && manageActionApproveBusinessTrips && (
            <>
              <Edit
                className="mx-4"
                onClick={() => {
                  setRowFish(row)
                  dispatch(setIsOpenModalEdit())
                }}
                size={18}
                style={{ cursor: 'pointer' }}
                id={`icon-edit-alih-muat-${row?.id}`}
              />
              <PMBTooltip
                target={`icon-edit-alih-muat-${row?.id}`}
                text="Ubah"
              />
            </>
          )}
        </div>
      ),
    },
  ]

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {
        marginTop: '1px',
        marginBottom: '1px',
      },
    },
    rows: {
      style: {
        marginTop: '1px',
        marginBottom: '1px',
        '&:hover': {
          backgroundColor: '#F9FAFB',
        },
      },
    },
  }

  const isApprove = data?.data?.approved

  if (isLoading) {
    return <LoadingV2 />
  }

  const handleRefetch = async () => {
    try {
      await refetch()

      dispatch(getLogs({ page: 0, catchId, size: 99 })).then((result) => {
        const logs = result?.payload?.items?.map((item) => {
          if (item.extraData) {
            item.parsedExtraData = parseExtraData(item.extraData)
          }
          return item
        })

        setLogs(logs)
      })
    } catch (error) {
      console.error('Error during refetching and loading logs:', error)
    }
  }

  return (
    <>
      {/* MODAL TRACEABILITY */}
      <PMBModalTraceability
        isOpen={isOpenModalTraceability}
        toggle={handleOpenCloseModalTraceability}
        productId={selectedProductId}
      />

      {/* MODAL MOVEMENT */}
      <ModalMovement />

      {/* MODAL APPROVE */}
      <ModalApprove
        open={isOpenModalApprove}
        toggle={() => dispatch(setIsOpenModalApprove())}
        row={data}
        handleRefetch={handleRefetch}
        catchId={Number(catchId)}
      />

      {/* MODAL EDIT */}
      <ModalEdit
        open={isOpenModalEdit}
        toggle={() => dispatch(setIsOpenModalEdit())}
        row={rowFish}
        id={Number(catchId)}
        handleRefetch={handleRefetch}
      />

      <div className="w-100 px-3 gap-10">
        <div className="mt-3">
          <BreadCrumbs
            breadCrumbTitle="Beranda"
            breadCrumbParent2="Daftar Perjalanan"
            breadCrumbParent2Link={'/catches'}
            breadCrumbActive="Detail Perjalanan"
          />
        </div>

        <div className="py-2 d-flex justify-content-between align-items-center">
          <h5 className="w-100">Detail Perjalanan</h5>
          {!isApprove && manageActionApproveBusinessTrips && (
            <div className="w-20">
              <ButtonIcon
                icon={<Check size={16} />}
                btnTitle="Verifikasi"
                outline={false}
                btnProps={{
                  disabled: isApprove,
                  size: 'sm',
                  color: 'success',
                  onClick: () => dispatch(setIsOpenModalApprove()),
                }}
                btnTitleProps={{
                  className: 'fw-light fs-sm',
                }}
              />
            </div>
          )}
        </div>

        <div className="row">
          <InformationCard
            title="Informasi Perjalanan"
            info={[
              { label: 'ID Trip', value: data?.id || '-' },
              { label: 'Lama Trip', value: tripDuration || '-' },
              {
                label: 'Keberangkatan',
                value: PMBFormatDate(tripInfo.startFishing),
              },
              {
                label: 'Berlabuh',
                value: PMBFormatDate(tripInfo.endFishing),
              },
              {
                label: 'Koordinat Keberangkatan',
                value:
                  tripInfo.startLatitude && tripInfo.startLongitude
                    ? `${tripInfo.startLatitude}, ${tripInfo.startLongitude}`
                    : '-',
              },
              {
                label: 'Port Berangkat',
                value: tripInfo.startPort || '-',
              },
              {
                label: 'Port Berlabuh',
                value: tripInfo.endPort || '-',
              },
              { label: 'WPP', value: catchAreasWPP ?? '-' },
            ]}
          />

          <InformationCard
            title="Informasi Kapal"
            info={[
              { label: 'Ship Name', value: vesselInfo.shipName || '-' },
              { label: 'Nama Nelayan', value: userName },
              {
                label: 'Vessel Registration',
                value: vesselInfo.shipNumber || '-',
              },
              { label: 'UVI', value: vesselInfo.ap2hiUVI || '-' },
              { label: 'Vessel Flag', value: vesselInfo.vesselFlag || '-' },
              { label: 'Gear Type', value: vesselInfo.vesselGear || '-' },
              { label: 'Vessel Type', value: vesselInfo.vesselType || '-' },
              // { label: 'FIP', value: vesselInfo.vesselType || '-' },
            ]}
          />

          {/* UNCOMMENT THIS LINE WHEN COMPONENTS LOGS ALREADY DONE */}
          {/* HISTORY */}
          <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
            <Card
              style={{
                minHeight: MIN_MAX_HEIGHT,
                maxHeight: MIN_MAX_HEIGHT,
                overflow: 'auto',
              }}
            >
              <CardBody className="d-flex flex-column gap-2">
                <h6>History</h6>
                <div className="row py-1">
                  <HistoryLine logs={logs} />
                </div>
              </CardBody>
            </Card>
          </div>
        </div>

        {/* Table Fishes */}
        <Card className="border-0 shadow-sm">
          <CardBody className="d-flex flex-column gap-2">
            <h6>Informasi Hasil Tangkapan</h6>
            <DataTable
              customStyles={customStyles}
              columns={columns}
              data={tripInfo?.fishes || []}
              progressPending={isLoading}
              responsive
              persistTableHead
              paginationTotalRows={data?.totalItems}
              progressComponent={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    margin: '100px 0px',
                  }}
                >
                  <Spinner />
                </div>
              }
              sortIcon={<ChevronDown />}
              sortServer
              className="border p-0 border-1 rounded-top"
              noDataComponent={
                <p className="my-3 fw-semibold">
                  Belum ada daftar tangkapan nelayan
                </p>
              }
              // pagination
            />
          </CardBody>
        </Card>
      </div>
    </>
  )
}

export default DetailCathces
