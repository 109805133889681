import BreadCrumbs from 'components/breadcrumbs'
import CustomButton from 'components/Buttons/CustomButton'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { AlertCircle, MinusCircle, Plus } from 'react-feather'
import { useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import {
  Button,
  Card,
  CardBody,
  CardText,
  Container,
  FormGroup,
  Input,
  Label,
} from 'reactstrap'
import { URL_WAREHOUSE } from 'utils/pathUrl'
import {
  useCreateWarehouseMutation,
  useFetchStaffWarehouseQuery,
  useUpdateWarehouseMutation,
} from './warehouseApiSlice'

const FormWarehouse = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const location = useLocation()
  const dataState = location.state
  const [errMsg, setErrMsg] = useState('')
  const [payload, setPayload] = useState(null)
  const [position, setPosition] = useState(null)
  const [pics, setPics] = useState([''])
  const { data, isLoading, refetch } = useFetchStaffWarehouseQuery({})
  const [addWarehouse, resAddWarehouse] = useCreateWarehouseMutation()
  const [updateWarehouse, resUpdateWarehouse] = useUpdateWarehouseMutation()

  const handleSubmit = async () => {
    try {
      const resLoc = await getMyLocation()
      const body = {
        name: payload?.name,
        userIds: pics?.map((e) => e?.userId),
        latitude: resLoc?.latitude,
        longitude: resLoc?.longitude,
        address: payload?.address,
      }
      const result = dataState
        ? await updateWarehouse({ payload: body, id: dataState?.id })
        : await addWarehouse(body)
      if (result?.error) {
        throw result
      } else {
        navigate(URL_WAREHOUSE)
        enqueueSnackbar(
          `Gudang berhasil ${dataState ? 'diubah' : 'ditambah'}`,
          {
            variant: 'success',
          },
        )
      }
    } catch (error) {
      setErrMsg(error?.error?.data?.message ?? error?.message)
    }
  }

  const handleAdd = () => {
    setErrMsg('')
    setPics([...pics, ''])
  }

  const handleDelete = (index) => {
    setErrMsg('')
    setPics(pics.filter((_, i) => i !== index))
  }

  const getFilteredOptions = () => {
    const selectedIds = pics?.map((pic) => Number(pic?.userId))
    return data?.newData?.map((option) => ({
      ...option,
      isDisabled: selectedIds?.includes(option?.userId),
    }))
  }

  const getMyLocation = async () => {
    try {
      const location = window.navigator && window.navigator.geolocation

      if (!location) {
        throw new Error('Geolocation not supported')
      }

      const result = await new Promise((resolve, reject) => {
        location.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords?.latitude,
              longitude: position.coords?.longitude,
              locationBlocked: false,
            })
          },
          (error) => {
            if (error.code === error.PERMISSION_DENIED) {
              resolve({
                locationBlocked: true,
              })
            } else {
              reject(error)
            }
          },
        )
      })

      setPosition({
        latitude: result.latitude,
        longitude: result.longitude,
      })
      return result
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  useEffect(() => {
    if (data?.totalItems > data?.items?.length) {
      refetch({ size: data?.totalItems })
    }
  }, [data])

  useEffect(() => {
    if (dataState) {
      setPayload(dataState)
      setPics(
        dataState?.warehousemember?.map((e) => {
          return {
            ...e,
            value: e?.userId,
            label: e?.name,
          }
        }),
      )
    }
  }, [dataState])

  return (
    <>
      <Container className="custom-container d-flex flex-column gap-1 py-2">
        <div className="pt-3">
          <BreadCrumbs
            breadCrumbActive={'Tambah Gudang'}
            breadCrumbParent={'Gudang'}
            breadCrumbParentLink={URL_WAREHOUSE}
          />
        </div>
        <Card className="border-0 shadow-sm">
          <CardBody>
            <CardText tag="h5" className="pb-4">
              Tambah Gudang
            </CardText>
            <FormGroup>
              <Label>Nama gudang</Label>
              <Input
                onChange={(e) => {
                  setErrMsg('')
                  setPayload((prev) => ({
                    ...prev,
                    name: e.target?.value,
                  }))
                }}
                value={payload?.name}
                id="warehouseName"
                name="warehouseName"
                placeholder="Masukkan nama gudang"
              />
            </FormGroup>
            <FormGroup>
              <Label>Alamat</Label>
              <Input
                type="textarea"
                onChange={(e) => {
                  setErrMsg('')
                  setPayload((prev) => ({
                    ...prev,
                    address: e.target?.value,
                  }))
                }}
                value={payload?.address}
                id="warehouseAddress"
                name="warehouseAddress"
                placeholder="Masukkan alamat gudang"
              />
            </FormGroup>
            <hr></hr>

            <FormGroup>
              <Label className="fw-500">Masukkan PIC gudang</Label>
              <div className="d-flex flex-column gap-3">
                {pics?.map((pic, index) => (
                  <div
                    key={pic.id}
                    className="d-flex w-100 align-items-center gap-2"
                  >
                    <Select
                      className="flex-grow-1"
                      placeholder="Pilih anggota"
                      options={getFilteredOptions()}
                      value={pics[index]}
                      onChange={(e) => {
                        setErrMsg('')
                        const updatedPics = [...pics]
                        updatedPics[index] = e
                        setPics(updatedPics.filter((pic) => pic !== ''))
                      }}
                    />

                    <MinusCircle
                      onClick={() => handleDelete(index)}
                      style={{
                        visibility: index === 0 ? 'hidden' : '',
                      }}
                      className="text-danger pointer"
                    />
                  </div>
                ))}
              </div>
            </FormGroup>

            <FormGroup>
              <CustomButton
                onClick={handleAdd}
                outline
                color="primary"
                size="sm"
              >
                <Plus size={20} /> Tambah PIC
              </CustomButton>
            </FormGroup>
            <hr></hr>

            {errMsg && (
              <FormGroup>
                <small className="text-danger">
                  <AlertCircle size={15} /> {errMsg}
                </small>
              </FormGroup>
            )}

            <div className="d-flex gap-3">
              <Button
                onClick={() => navigate(URL_WAREHOUSE)}
                outline
                color="primary"
                block
              >
                Batal
              </Button>
              <CustomButton
                onClick={() => handleSubmit()}
                disabled={
                  isLoading ||
                  resAddWarehouse?.isLoading ||
                  resUpdateWarehouse?.isLoading ||
                  !payload?.name ||
                  pics[0] === ''
                }
                block
                color="primary"
              >
                Simpan
              </CustomButton>
            </div>
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default FormWarehouse
