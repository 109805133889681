import { createApiSlice } from 'app/createApiSlice'
import { API_WAREHOUSE, API_WAREHOUSE_SUMMARY } from 'utils/apiList'

export const warehouseSummaryApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'warehouseSummary/api',
  endpoints(builder) {
    return {
      fetchWarehouseById: builder.query({
        query({ id }) {
          if (!id) {
            throw new Error("Parameter 'warehouseId' is mandatory!.")
          }
          return {
            url: API_WAREHOUSE,
            params: {
              id,
            },
          }
        },
      }),
      fetchWarehouseSummary: builder.query({
        query({
          page = 0,
          size = 10,
          sortby = 'total',
          sortdir = 'asc',
          warehouseId,
          month,
          type = 'current',
        }) {
          if (!warehouseId) {
            throw new Error("Parameter 'warehouseId' is mandatory!.")
          }
          return {
            url: API_WAREHOUSE_SUMMARY,
            params: {
              page,
              size,
              sortby,
              sortdir,
              warehouseId,
              month,
              type,
            },
          }
        },
      }),
    }
  },
})

export const { useFetchWarehouseByIdQuery, useFetchWarehouseSummaryQuery } =
  warehouseSummaryApiSlice
