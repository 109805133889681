import ICDefaultPhoto from 'assets/icons/default-ava-profile-user.svg'
import ICTransaction from 'assets/icons/icon-transaksi.svg'
import CustomButton from 'components/Buttons/CustomButton'
import RoundedComponent from 'components/Rounded'
import SidebarDetails from 'components/SidebarDetails'
import { Can } from 'context/Acl'
import { useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, CardText, Collapse } from 'reactstrap'
import { formatDatePMBV2 } from 'utils/formatDate'
import {
  toggleModal,
  toggleModalTolak,
  toggleSidebar,
} from './transferInventorySlice'
import ModalConfirmation from './ModalConfirmation'
import { BUSINESS_TRANSFER_INVENTORY, MANAGE } from 'utils/subjectActions'
import PMBModalRemove from 'components/PMBModalRemove'
import { useConfirmTransferMutation } from './transferInventoryApiSlice'
import { enqueueSnackbar } from 'notistack'

const SidebarTfInventory = ({ data, handleCallback }) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const { tag, isOpenSidebar, isOpenModalTolak } = useSelector(
    (s) => s.transferInventory,
  )
  const [confirmTransfer, resulConfirmTransfer] = useConfirmTransferMutation()
  const [errMsg, setErrMsg] = useState('')
  const [isOpenCollapse, setIsOpenCollapse] = useState(false)
  const toggleCollapse = () => {
    setIsOpenCollapse(!isOpenCollapse)
  }

  const handleConfirm = async (id) => {
    try {
      const result = await confirmTransfer({ id: id, body: 0 })
      if (result?.error) {
        throw result
      } else {
        dispatch(toggleModalTolak())
        dispatch(toggleSidebar())
        enqueueSnackbar('Berhasil ditolak.', { variant: 'error' })
        handleCallback()
      }
    } catch (error) {
      setErrMsg(error?.error?.data?.message ?? error?.message)
    }
  }

  return (
    <>
      <ModalConfirmation data={data} handleCallback={handleCallback} />
      <PMBModalRemove
        isOpen={isOpenModalTolak}
        onClose={() => dispatch(toggleModalTolak())}
        onRemoveData={handleConfirm}
        title="Tolak inventori masuk"
        desc="Anda yakin ingin menolak inventori ini?"
        dataId={data?.id}
        isLoading={resulConfirmTransfer?.isLoading}
        errMsg={errMsg}
        buttonRightText="Tolak"
      />
      <SidebarDetails
        size="lg"
        open={isOpenSidebar}
        title="Detail Inventori"
        headerClassName="mb-1"
        contentClassName="pt-0"
        toggleSidebar={() => dispatch(toggleSidebar())}
      >
        <div className="h-100 w-100">
          {/* Alur Pengiriman */}
          <Card
            onClick={() => {
              toggleCollapse()
            }}
            body
            className="mb-3 mt-3 border-0 shadow-sm"
          >
            <div className="d-flex gap-2 flex-row align-items-center justify-content-between">
              <div className="d-flex flex-row gap-2">
                <div className="flex-centered justify-content-start gap-1 align-items-center">
                  <img src={ICTransaction} alt="ava" width={40} height={40} />
                </div>
                <div>
                  <CardText tag="h6" className="fw-bold text-primary">
                    Alur Pengiriman
                  </CardText>
                  <CardText tag="p" className="m-0 fw-light">
                    Pengiriman dibuat:
                  </CardText>
                  <CardText className="fw-light" tag="p">
                    {`(${formatDatePMBV2(data?.items?.[0]?.history?.createdDate ?? data?.createdAt)})`}
                  </CardText>
                </div>
              </div>

              {isOpenCollapse ? (
                <ChevronUp size={15} />
              ) : (
                <ChevronDown size={15} />
              )}
            </div>
            {data?.createdAt && (
              <Collapse isOpen={isOpenCollapse}>
                <CardBody>
                  <div>
                    {data?.createdAt && (
                      <div className="d-flex flex-row justify-content-start align-items-center gap-2">
                        <RoundedComponent
                          childern="1"
                          style={{
                            backgroundColor: '#C0E5F2',
                            width: '20px',
                            height: '20px',
                          }}
                        />
                        <div className="d-flex flex-column">
                          <span>Pengiriman dibuat</span>
                          <small className="text-secondary">
                            {' '}
                            {`(${formatDatePMBV2(data?.createdAt)})`}
                          </small>
                        </div>
                      </div>
                    )}
                    {data?.approvedAt && (
                      <div className="d-flex flex-row justify-content-start align-items-center gap-2">
                        <RoundedComponent
                          childern="2"
                          style={{
                            backgroundColor: '#C0E5F2',
                            width: '20px',
                            height: '20px',
                          }}
                        />
                        <div className="d-flex flex-column">
                          <span>Pengiriman dikonfirmasi oleh penerima</span>
                          <small className="text-secondary">{`(${formatDatePMBV2(data?.approvedAt)})`}</small>
                        </div>
                      </div>
                    )}
                    {data?.rejectedAt && (
                      <div className="d-flex flex-row justify-content-start align-items-center gap-2">
                        <RoundedComponent
                          childern="2"
                          style={{
                            backgroundColor: '#C0E5F2',
                            width: '20px',
                            height: '20px',
                          }}
                        />
                        <div className="d-flex flex-column">
                          <span>Pengiriman dibatalkan oleh penerima</span>
                          <small className="text-secondary">{`(${formatDatePMBV2(data?.rejectedAt)})`}</small>
                        </div>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Collapse>
            )}
          </Card>

          {/* STATUS */}
          <div
            className={`rounded p-3 text-center mb-3 d-block w-100 ${!data?.approvedAt ? 'badge-warning' : 'badge-process'}`}
          >
            {data?.approvedAt
              ? 'Selesai'
              : data?.rejectedAt
                ? 'Ditolak'
                : 'Menunggu konfirmasi penerima'}
          </div>

          {/* Inventories */}
          <Card className="mb-3 border-0 shadow-sm">
            <CardBody>
              <CardText tag="h6" className="fw-bold">
                Nama Inventori
              </CardText>
              {data?.warehousetransferitem?.map((e) => {
                return (
                  <div className="d-flex flex-column my-2">
                    <span>{e?.inventory?.item?.nameItem}</span>
                    <span>
                      {' '}
                      {intl.formatNumber(e?.amount ?? e?.inventory?.amount, {
                        useGrouping: 'always',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      })}{' '}
                      {e?.inventory?.unit ?? e?.inventory?.item?.unit}
                    </span>
                    {tag?.type === 'in' ? (
                      <span>Gudang Pengirim: {e?.warehouse?.name}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                )
              })}
            </CardBody>
          </Card>
          {/* Details Dibuat oleh */}
          {tag?.type === 'in' ? (
            <Card body className="mb-3 border-0 shadow-sm">
              <CardText tag="h6" className="fw-bold">
                Dibuat Oleh
              </CardText>
              <div className="flex-centered justify-content-between gap-1">
                <div className="d-flex gap-2 align-items-center">
                  <img
                    src={data?.sender?.profilePic ?? ICDefaultPhoto}
                    alt="ava"
                    width={32}
                    height={32}
                    className="rounded-circle"
                  />
                  <span>{data?.sender?.name}</span>
                </div>
              </div>
            </Card>
          ) : tag?.type === 'out' && data?.approvedAt ? (
            <Card body className="mb-3 border-0 shadow-sm">
              <CardText tag="h6" className="fw-bold">
                Diterima Oleh
              </CardText>
              <div className="flex-centered justify-content-between gap-1">
                <div className="d-flex gap-2 align-items-center">
                  <img
                    src={data?.receiver?.profilePic ?? ICDefaultPhoto}
                    alt="ava"
                    width={32}
                    height={32}
                    className="rounded-circle"
                  />
                  <span>{data?.receiver?.name}</span>
                </div>
              </div>
            </Card>
          ) : (
            <></>
          )}

          <Can I={MANAGE} this={BUSINESS_TRANSFER_INVENTORY}>
            {tag?.type === 'in' && !data?.approvedAt && !data?.rejectedAt ? (
              <div className="d-flex gap-2 flex-column">
                <CustomButton
                  block
                  color="primary"
                  outline
                  size="sm"
                  onClick={() => dispatch(toggleModalTolak())}
                >
                  Tolak
                </CustomButton>
                <CustomButton
                  block
                  color="primary"
                  size="sm"
                  onClick={() => {
                    dispatch(toggleModal())
                  }}
                >
                  Konfirmasi Terima
                </CustomButton>
              </div>
            ) : null}
          </Can>
        </div>
      </SidebarDetails>
    </>
  )
}

export default SidebarTfInventory
