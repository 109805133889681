import { createAppSlice } from 'app/createAppSlice'

const initialState = {
  tag: {
    size: 10,
    page: 0,
  },
}

export const farmersPendingListSlice = createAppSlice({
  name: 'farmersPendingList',
  initialState,
  reducers: (create) => ({
    setFetchOption: create.reducer((state, { payload }) => {
      state.tag = { ...state.tag, ...payload }
    }),
  }),
  extraReducers: (builder) => {},
})

export const { setFetchOption } = farmersPendingListSlice.actions

export default farmersPendingListSlice.reducer
