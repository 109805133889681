import IPhoto from 'assets/icons/Pak-Tani.png'
import { ButtonIcon } from 'components/Buttons'
import { TableComponent as DataTable } from 'components/TableComponent'
import moment from 'moment-timezone'
import { useState } from 'react'
import { CheckCircle } from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Badge, Card, CardFooter, CardText } from 'reactstrap'
import SidebarTalanginInvoice from './SidebarTalanginInvoice'
import {
  setSelectedRow,
  toggleBanner,
  toggleSidebarTalangin,
} from './talanginInvoiceSlice'
// import ICFilter from 'assets/icons/filter-icon.svg'
// import ModalFilter from './components/ModalFilter'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import useGetTrxTalanginQuery from 'hooks/useGetTrxTalanginQuery'
import { Navigate } from 'react-router-dom'
import { URL_406 } from 'utils/pathUrl'
import { setPage as setPageRedux, setSize } from './talanginSlice'
import BreadCrumbs from 'components/breadcrumbs'
import PMBPagination from 'components/PMBPagination'
import { formatDatePMB } from 'utils/formatDate'

const TalanginInvoice = ({ action, subject }) => {
  const abilityCreate = useAbilityAccess({ action, subject })

  const intl = useIntl()
  const dispatch = useDispatch()
  const talanginTable = useSelector((s) => s.talanginTable)
  let [page, setPage] = useState({ page: talanginTable?.page })
  // let [searchData, setSearchData] = useState(talanginTable?.search)

  const dataTalangin = useGetTrxTalanginQuery({
    ...talanginTable,
  })
  const {
    isOpenBanner,
    isOpenSidebar,
    msgBanner,
    selectedRow,
    justRolledOver,
  } = useSelector((e) => e.talanginInvoice)

  const toggleBanners = () => dispatch(toggleBanner())

  const toggleSidebar = () => dispatch(toggleSidebarTalangin())

  // handle Open Filter
  // const [isOpenFilter, setisOpenFilter] = useState(false)
  // const openFilter = () => {
  //   setisOpenFilter(!isOpenFilter)
  // }

  const conditionalRowStyles = [
    {
      when: (row) => justRolledOver === row?.id,
      style: {
        backgroundColor: '#F3FEFF',
      },
    },
  ]

  const columns = [
    {
      name: 'Penjual',
      cell: (row) => (
        <div className="flex-centered gap-2">
          <img
            src={row?.sellerAvatar ?? IPhoto}
            alt="pak tani"
            width={20}
            height={20}
            className="rounded-circle"
          />
          <span>{row?.sellerName}</span>
        </div>
      ),
      // sortable: true,
    },
    {
      name: 'Jatuh Tempo',
      cell: (row) => <span>{formatDatePMB(row?.dueDate, row?.dueDate)}</span>,
    },
    {
      name: 'Komoditas',
      cell: (row) => {
        return (
          <div className="d-flex flex-column">
            <span className="flex-row">{`${row?.escrowpayment?.order?.orderitem?.[0]?.item?.nameItem} • ${row?.escrowpayment?.order?.orderitem?.[0]?.amount} ${row?.escrowpayment?.order?.orderitem?.[0]?.unit}`}</span>
            <span className="fw-light">
              {row?.escrowpayment?.order?.orderitem?.length > 1
                ? `dan ${row?.escrowpayment?.order?.orderitem?.length - 1} komoditas lainnya`
                : ''}
            </span>
          </div>
        )
      },
    },
    {
      name: 'Total Transaksi',
      cell: (row) => (
        <span>
          Rp
          {intl.formatNumber(
            row?.escrowpayment?.escrowcreditv2?.useFreeTx
              ? row?.escrowpayment?.escrowcreditv2?.totalVAT +
                  (row?.escrowpayment?.amount +
                    (row?.previousUserPinjamanId
                      ? row?.escrowpayment?.escrowcreditv2?.margin2
                      : row?.escrowpayment?.escrowcreditv2?.margin) ||
                    row?.amountPlusInterest) +
                  (row?.escrowpayment?.escrowcreditv2?.feeTx -
                    row?.escrowpayment?.escrowcreditv2?.feeTxVAT) +
                  (row?.partialpaymentData?.fine || row?.fineData?.totalFine)
              : row?.escrowpayment?.escrowcreditv2?.feeTxVAT +
                  row?.escrowpayment?.escrowcreditv2?.totalVAT +
                  (row?.escrowpayment?.amount +
                    (row?.previousUserPinjamanId
                      ? row?.escrowpayment?.escrowcreditv2?.margin2
                      : row?.escrowpayment?.escrowcreditv2?.margin) ||
                    row?.amountPlusInterest) +
                  (row?.escrowpayment?.escrowcreditv2?.feeTx -
                    row?.escrowpayment?.escrowcreditv2?.feeTxVAT) +
                  (row?.partialpaymentData?.fine || row?.fineData?.totalFine),
            {
              useGrouping: 'always',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            },
          )}
        </span>
      ),
    },
    {
      name: 'Dibayar oleh',
      cell: (row) =>
        row?.pmbactions ? (
          <div className="flex-centered gap-2">
            <img
              src={row?.paidOffUserAvatar ?? IPhoto}
              alt="pak tani"
              width={20}
              height={20}
              className="rounded-circle"
            />
            <span>{row?.pmbactions?.verifiedprofile?.name ?? '-'}</span>
          </div>
        ) : (
          <>
            <div className="">-</div>
          </>
        ),
    },
    {
      name: 'Status',
      cell: (row) => {
        let tdd = moment(row.lateDueDate ?? row.dueDate).tz('Asia/Jakarta')
        let diffInDays = moment().tz('Asia/Jakarta').diff(tdd, 'days')

        // Terlambat x hari:
        if (
          (row.status?.toLowerCase() === 'pending' ||
            row.status?.toLowerCase() === 'disbursed') &&
          diffInDays > 0
        ) {
          return (
            <Badge size="sm" disabled className="badge-danger">
              Terlambat {diffInDays + 1} hari
            </Badge>
          )
        }

        // Lunas
        if (
          row.status?.toLowerCase() === 'rollover' ||
          row.status?.toLowerCase() === 'paidoff'
        ) {
          return (
            <Badge size="sm" disabled className="badge-verify">
              Lunas
            </Badge>
          )
        }

        // Jatuh Tempo Hari Ini
        if (
          (row.status?.toLowerCase() === 'pending' ||
            row.status?.toLowerCase() === 'disbursed') &&
          diffInDays === 0
        ) {
          return (
            <Badge size="sm" className="badge-danger">
              Jatuh tempo hari ini
            </Badge>
          )
        }

        // Diperpanjang (x) hari
        if (
          (row.status?.toLowerCase() === 'pending' ||
            row.status?.toLowerCase() === 'disbursed') &&
          row.previousUserPinjamanId &&
          diffInDays < 0
        ) {
          return (
            <Badge size="sm" className="badge-process">
              Diperpanjang {row?.escrowpayment?.escrowcreditv2?.loanDays2} hari
            </Badge>
          )
        }

        // Belum Lunas
        if (
          (row.status?.toLowerCase() === 'pending' ||
            row.status?.toLowerCase() === 'disbursed') &&
          !row.previousUserPinjamanId &&
          diffInDays < 0
        ) {
          return (
            <Badge size="sm" className="badge-warning">
              Belum Lunas
            </Badge>
          )
        }
      },
    },
    {
      name: '',
      selector: (row) => (
        <div
          onClick={() => {
            toggleSidebar()
            dispatch(setSelectedRow(row))
          }}
          style={{ cursor: 'pointer' }}
        >
          Lihat Detail
        </div>
      ),
      center: true,
    },
  ]

  if (!abilityCreate) {
    return <Navigate to={URL_406} replace={true} />
  }

  return (
    <>
      <div className="w-100 px-3">
        <SidebarTalanginInvoice
          isOpen={isOpenSidebar}
          toggle={toggleSidebar}
          row={selectedRow}
        />
        <div className="d-flex align-items-start gap-2 pt-3">
          <BreadCrumbs breadCrumbActive={'Tagihan Talangin'} />
        </div>
        <Card body>
          <CardText tag="h5" className="py-2 fw-semibold">
            Tagihan Talangin
          </CardText>
          {isOpenBanner && (
            <Alert color="success" isOpen={isOpenBanner} toggle={toggleBanners}>
              <CheckCircle className="text-success me-2" size={16} />
              <span>{msgBanner}</span>
            </Alert>
          )}
          <DataTable
            columns={columns}
            data={dataTalangin?.data?.items}
            progressPending={
              dataTalangin?.isLoading || dataTalangin?.isFetching
            }
            conditionalRowStyles={conditionalRowStyles}
            pagination
            paginationServer
            paginationComponent={(props) => (
              <PMBPagination
                rowCount={dataTalangin?.data?.totalItems}
                currentPage={talanginTable?.page}
                rowsPerPage={talanginTable?.size}
                setCurrentPage={(e) => {
                  dispatch(
                    setPageRedux({
                      page: e,
                    }),
                  )
                }}
                setRowsPerPage={(e) => {
                  dispatch(
                    setSize({
                      size: e,
                    }),
                  )
                }}
                props={props}
              />
            )}
          />
        </Card>
      </div>
      {/* <ModalFilter handleClick={openFilter} modal={isOpenFilter} /> */}
    </>
  )
}

export default TalanginInvoice
