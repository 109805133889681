import { createAppSlice } from 'app/createAppSlice'

const initialState = {
  isOpenSidebar: false,
  tag: {
    size: 10,
    page: 0,
    type: 'in',
  },
  isOpenModal: false,
  isOpenModalTolak: false,
  loading: false,
}

export const inventoryTransferSlice = createAppSlice({
  name: 'inventoryTransfer',
  initialState,
  reducers: (create) => ({
    toggleSidebar: create.reducer((state, { payload }) => {
      state.isOpenSidebar = !state.isOpenSidebar
    }),
    setFetchOption: create.reducer((state, { payload }) => {
      state.tag = { ...state.tag, ...payload }
    }),
    toggleModal: create.reducer((state, { payload }) => {
      state.isOpenModal = !state.isOpenModal
    }),
    toggleModalTolak: create.reducer((state, { payload }) => {
      state.isOpenModalTolak = !state.isOpenModalTolak
    }),
  }),
  extraReducers: (builder) => {},
})

export const { toggleSidebar, toggleModal, setFetchOption, toggleModalTolak } =
  inventoryTransferSlice.actions

export default inventoryTransferSlice.reducer
