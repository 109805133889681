import BreadCrumbs from 'components/breadcrumbs'
import LoadingV2 from 'components/LoadingV2'
import PMBModalImagePreview from 'components/PMBModalImagePreview'
import PMBPagination from 'components/PMBPagination'
import PMBTooltip from 'components/PMBTooltip'
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import moment from 'moment-timezone'
import { useState } from 'react'
import DataTable from 'react-data-table-component'
import { Calendar, ChevronDown, User } from 'react-feather'
import Flatpickr from 'react-flatpickr'
import { useParams } from 'react-router-dom'
import { Card, CardBody, Spinner } from 'reactstrap'
import {
  useFetchWarehouseByIdQuery,
  useFetchWarehouseSummaryQuery,
} from './warehouseDetailApiSlice'

const DetailWarehouse = () => {
  const { id } = useParams()

  const MIN_MAX_HEIGHT = '100px'
  const [activeTab, setActiveTab] = useState('all')
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [sortColumn, setSortColumn] = useState('nameItem')
  const [sortDirection, setSortDirection] = useState('desc')
  const [imageItems, setImagesItems] = useState([])
  const [modalOpenImage, setModalOpenImage] = useState(false)

  // FETCH API BY WAREHOUSE DETAIL
  const formattedDate = moment(selectedDate).format('YYYY-MM')
  const { data: dataById, isLoading: isLoadingById } =
    useFetchWarehouseByIdQuery({
      id: id,
    })

  const dataWarehouse = dataById?.items?.[0]

  const { data, isLoading, isFetching } = useFetchWarehouseSummaryQuery({
    page: currentPage ?? 0,
    size: rowsPerPage ?? 10,
    sortby: sortColumn,
    sortdir: sortDirection,
    warehouseId: id ?? null,
    month: formattedDate,
    type: activeTab === 'all' ? 'current' : activeTab,
  })

  const dataResponse = Array.isArray(data?.items) ? data?.items : []

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length > 0) {
      const date = selectedDates[0]
      setSelectedDate(date)
    }
  }

  // HANDLE SORTING
  const handleSort = (column, direction) => {
    setSortColumn(column.selector)
    setSortDirection(direction === 'asc' ? 'asc' : 'desc')
  }

  const columns = [
    {
      name: <span>Nama Inventori</span>,
      selector: 'nameItem',
      sortable: true,
      cell: (row) => <span>{row?.nameItem}</span>,
    },
    {
      name: (
        <span>
          {activeTab === 'in'
            ? 'Jumlah Masuk'
            : activeTab === 'out'
              ? 'Jumlah Keluar'
              : 'Jumlah'}
        </span>
      ),
      selector: 'total',
      sortable: true,
      cell: (row) => (
        <span>
          {row?.total ?? '0'} {row?.unit ?? 'Kg'}
        </span>
      ),
    },
  ]

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {
        marginTop: '1px',
        marginBottom: '1px',
      },
    },
    table: {
      style: {
        minHeight: '300px',
      },
    },
  }

  const onHandleOpenImageModal = (imageUrl) => {
    const images = Array.isArray(imageUrl) ? imageUrl : [imageUrl]
    setImagesItems(images)
    setModalOpenImage(true)
  }

  if (isLoadingById) {
    return <LoadingV2 />
  }

  return (
    <div className="w-100 px-3">
      {/* BREADCRUMB */}
      <div className="d-flex align-items-start gap-2 pt-3">
        <BreadCrumbs
          breadCrumbParent2="Daftar Gudang"
          breadCrumbParent2Link={'/gudang'}
          breadCrumbActive={`${dataWarehouse?.name ?? '-'}`}
        />
      </div>
      {/* END BREADCRUMB */}

      {/* ADDRES AND PIC SECTION */}
      <div className="row">
        <div className="col-md-6 col-sm-12 mb-3">
          <Card
            className="border-0 shadow-sm"
            style={{ minHeight: MIN_MAX_HEIGHT, maxHeight: MIN_MAX_HEIGHT }}
          >
            <CardBody className="d-flex flex-column gap-1">
              <h6>Alamat</h6>
              <div>
                <span className="fw-medium text-sm">
                  {dataWarehouse?.address ?? '-'}
                </span>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-md-6 col-sm-12 mb-3">
          <Card
            className="border-0 shadow-sm"
            style={{
              minHeight: MIN_MAX_HEIGHT,
              maxHeight: MIN_MAX_HEIGHT,
              overflow: 'auto',
            }}
          >
            <CardBody className="d-flex flex-column gap-1">
              <h6>PIC</h6>
              <div>
                <span className="fw-semibold text-sm">
                  <div className="gap-2 d-flex align-items-center">
                    {dataWarehouse?.warehousemember?.map((item) => {
                      return (
                        <>
                          <div
                            key={item?.id}
                            className="d-flex align-items-center"
                            id={`tooltip-pic-${item?.id}`}
                          >
                            {item?.profilePic ? (
                              <img
                                src={item?.profilePic}
                                alt="pic-img"
                                width={32}
                                height={32}
                                className="rounded-circle"
                                onClick={() =>
                                  onHandleOpenImageModal(item?.profilePic)
                                }
                                style={{ cursor: 'pointer' }}
                              />
                            ) : (
                              <div
                                className="rounded-circle flex-centered"
                                style={{
                                  width: '35px',
                                  height: '35px',
                                  backgroundColor: '#ECFAFF',
                                }}
                              >
                                <User
                                  size={28}
                                  className="text-primary rounded"
                                />
                              </div>
                            )}
                          </div>
                          <PMBTooltip
                            placement="top"
                            target={`tooltip-pic-${item?.id}`}
                            text={item?.name}
                          />
                        </>
                      )
                    })}
                  </div>
                </span>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>

      {/* MODAL IMAGE PREVIEW */}
      <PMBModalImagePreview
        isOpen={modalOpenImage}
        toggle={() => setModalOpenImage(!modalOpenImage)}
        images={imageItems}
      />
      {/* END MODAL IMAGE PREVIEW */}

      <Card className="mb-3 border-0 shadow-sm">
        <CardBody>
          <div className="row">
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ marginBottom: '24px' }}
            >
              <h5 className="fw-semibold">{dataWarehouse?.name ?? '-'}</h5>
              {activeTab !== 'all' && (
                <div style={{ minWidth: '320px' }}>
                  <div className="d-flex align-items-center justify-content-center border rounded">
                    <Calendar size={17} className="ms-3" />
                    <Flatpickr
                      value={selectedDate}
                      onChange={handleDateChange}
                      options={{
                        altInput: true,
                        altFormat: 'F Y',
                        dateFormat: 'Y-m',
                        defaultDate: selectedDate,
                        plugins: [
                          new MonthSelectPlugin({
                            shorthand: false,
                            dateFormat: 'Y-m',
                            altFormat: 'F Y',
                          }),
                        ],
                      }}
                      className="form-control bg-transparent border-0 shadow-none"
                    />
                  </div>
                </div>
              )}
            </div>

            {/* TAB MENU */}
            <div
              className="d-flex gap-3 mb-3"
              style={{ borderBottom: '1px solid #EAECF0' }}
            >
              <div
                style={{
                  borderBottom: activeTab === 'all' ? '2px solid #005370' : '',
                  paddingBottom: 14,
                  color: activeTab === 'all' ? '#005370' : '#667085',
                  cursor: 'pointer',
                  fontSize: '14px',
                }}
                onClick={() => {
                  setActiveTab('all')
                  setCurrentPage(0)
                }}
              >
                Semua Inventori
              </div>
              <div
                style={{
                  borderBottom: activeTab === 'in' ? '2px solid #005370' : '',
                  paddingBottom: 14,
                  color: activeTab === 'in' ? '#005370' : '#667085',
                  cursor: 'pointer',
                  fontSize: '14px',
                }}
                onClick={() => {
                  setActiveTab('in')
                  setCurrentPage(0)
                }}
              >
                Inventori Masuk
              </div>
              <div
                style={{
                  borderBottom: activeTab === 'out' ? '2px solid #005370' : '',
                  paddingBottom: 14,
                  color: activeTab === 'out' ? '#005370' : '#667085',
                  cursor: 'pointer',
                  fontSize: '14px',
                }}
                onClick={() => {
                  setActiveTab('out')
                  setCurrentPage(0)
                }}
              >
                Inventori Keluar
              </div>
            </div>

            {/* TABLE */}
            <div>
              <DataTable
                key={`${currentPage}-${rowsPerPage}`}
                customStyles={customStyles}
                columns={columns}
                data={dataResponse}
                progressPending={isLoading || isFetching}
                responsive
                persistTableHead
                defaultSortFieldId={1}
                defaultSortAsc={false}
                sortIcon={<ChevronDown />}
                sortServer
                onSort={handleSort}
                className="border p-0 border-1 rounded-top"
                progressComponent={
                  <div style={{ margin: '100px 0px' }}>
                    <Spinner />
                  </div>
                }
                noDataComponent={
                  <p className="my-3 px-3 fw-semibold">
                    Tidak ada data inventori{' '}
                    {activeTab === 'in'
                      ? 'masuk'
                      : activeTab === 'out'
                        ? 'keluar'
                        : ''}{' '}
                    yang tersedia
                  </p>
                }
                pagination
                paginationTotalRows={data?.totalItems || 0}
                paginationPerPage={rowsPerPage}
                paginationPageNumber={currentPage + 1}
                paginationComponent={(props) => (
                  <PMBPagination
                    rowCount={data?.totalItems || 0}
                    currentPage={currentPage}
                    rowsPerPage={rowsPerPage}
                    setCurrentPage={setCurrentPage}
                    setRowsPerPage={setRowsPerPage}
                    props={props}
                  />
                )}
              />
            </div>
            {/* END TABLE */}
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default DetailWarehouse
