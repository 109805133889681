import { createAppSlice } from 'app/createAppSlice'
import { setSession } from 'utils/setSession'
import { LOCALSTORAGE_STATE } from 'utils/tags'
import {
  getGroupInventory,
  getPackingDetail,
  getWarehouse,
  getWarehouseForAdd,
} from './manageStockAction'

export const initialState = {
  loading: false,
  groupsInventory: null,
  detail: null,
  warehouses: null,
  warehousesAdd: null,
}

export const manageStockSlice = createAppSlice({
  name: 'manageStock',
  initialState,
  reducers: (create) => ({}),
  extraReducers: (builder) => {
    builder
      .addCase(getGroupInventory.pending, (state) => {
        state.loading = true
      })
      .addCase(getGroupInventory.fulfilled, (state, { payload }) => {
        state.loading = false
        state.groupsInventory = payload
      })
      .addCase(getGroupInventory.rejected, (state, { payload }) => {
        state.loading = false
      })
      .addCase(getPackingDetail.pending, (state) => {
        state.loading = true
      })
      .addCase(getPackingDetail.fulfilled, (state, { payload }) => {
        state.loading = false
        state.detail = payload
      })
      .addCase(getPackingDetail.rejected, (state, { payload }) => {
        state.loading = false
      })
      .addCase(getWarehouse.pending, (state) => {
        state.loading = true
      })
      .addCase(getWarehouse.fulfilled, (state, { payload }) => {
        state.loading = false
        state.warehouses = payload
      })
      .addCase(getWarehouse.rejected, (state, { payload }) => {
        state.loading = false
      })
      .addCase(getWarehouseForAdd.pending, (state) => {
        state.loading = true
      })
      .addCase(getWarehouseForAdd.fulfilled, (state, { payload }) => {
        state.loading = false
        state.warehousesAdd = payload
      })
      .addCase(getWarehouseForAdd.rejected, (state, { payload }) => {
        state.loading = false
      })
  },
})
export const {
  resetAlert,
  addRole,
  logout,
  login,
  loginLoading,
  loginReject,
  toggleAlert,
  setAlertMsg,
  setInitAlert,
  toggleModal,
  setLoading,
  setToken,
} = manageStockSlice.actions
export default manageStockSlice.reducer
