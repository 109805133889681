import { useState } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Card, Col, List, Row } from 'reactstrap'

import { Clipboard, PlusCircle } from 'react-feather'
import { REPO_KOMODITAS, REPO_KOMODITAS_LIST, URL_406 } from 'utils/pathUrl'

import IcSuccessTrx from 'assets/images/success-trx.png'
import BreadCrumbs from 'components/breadcrumbs'
import CustomButton from 'components/Buttons/CustomButton'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import { useLocalStorageTable as storage } from 'hooks/useLocalStorageTable'
import { BUSINESS_AUTOREPO, MANAGE } from 'utils/subjectActions'
import { useGetRepoListQuery } from './commodityRepoApiSlice'

const CommodityRepo = () => {
  const nav = useNavigate()
  let location = useLocation()
  const [successTrx, setSuccessTrx] = useState(false)

  const { options } = storage('auto_repo_table')
  const repoList = useGetRepoListQuery(options)

  const ability = useAbilityAccess({
    action: MANAGE,
    subject: BUSINESS_AUTOREPO,
  })

  if (!ability) {
    return <Navigate to={URL_406} replace={true} />
  }

  return (
    <div className="w-100 px-3">
      <Row>
        <div className="d-flex align-items-start gap-2 pt-3">
          <BreadCrumbs breadCrumbActive={'Repo'} />
        </div>
        {successTrx ? (
          <Col>
            <Card
              className="m-0 m-auto d-flex justify-content-center align-items-center gap-3 p-5"
              style={{ maxWidth: '640px' }}
            >
              <img src={IcSuccessTrx} alt="success" width={280} height={182} />
              <div className="text-sm text-center">
                Pengajuan Repo kamu telah berhasil dan sedang di pelajari oleh
                Tim kami. Setelah selesai kami akan memberitahu kamu segera.
              </div>
              <CustomButton
                onClick={() => {
                  nav(REPO_KOMODITAS, { replace: true })
                  repoList.refetch()
                  setSuccessTrx(false)
                }}
                color="primary"
                className="w-50"
              >
                Kembali ke Repo Komoditas
              </CustomButton>
            </Card>
          </Col>
        ) : (
          <>
            {/* <Col sm="12" md="4" lg="3"> */}
            <Col md={4} className="mb-3">
              <Card body className="mb-3 border-0 shadow-sm">
                <List type="unstyled" className="my-1">
                  <li
                    className={`text-sm menu-categories-approval ${location.pathname === REPO_KOMODITAS ? 'active' : ''}`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => nav(REPO_KOMODITAS)}
                  >
                    <PlusCircle size={16} className="ms-2 me-1" />
                    <span>Repo Komoditas</span>
                  </li>
                  <li
                    className={`text-sm menu-categories-approval ${location.pathname === REPO_KOMODITAS_LIST ? 'active' : ''}`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => nav(REPO_KOMODITAS_LIST)}
                  >
                    <Clipboard size={16} className="ms-2 me-1" />
                    <span>{`Daftar Pengajuan Repo (${repoList?.data?.totalItems ?? 0})`}</span>
                  </li>
                </List>
              </Card>
            </Col>
            {/* <Col sm="12" md="8" lg="9"> */}
            <Col md={8}>
              <Outlet context={[setSuccessTrx]} />
            </Col>
          </>
        )}
      </Row>
    </div>
  )
}

export default CommodityRepo
