import warningRed from 'assets/icons/warning-red.svg'
import { AlertCircle } from 'react-feather'
import { Button, CardText, CardTitle, Modal, Spinner } from 'reactstrap'
import './style.css'

const PMBModalRemove = ({
  isOpen,
  onClose,
  onRemoveData,
  title,
  desc,
  dataId,
  isLoading,
  errMsg,
  buttonRightText,
}) => {
  return (
    <Modal isOpen={isOpen} centered>
      <div className="regular-padding-horizontal small-scrollable-container small-padding-vertical">
        <img
          src={warningRed}
          style={{ width: 50, height: 50, marginTop: 10, marginBottom: 10 }}
          className="button small-margin-right"
        />
        <CardTitle className="fw-semibold">
          {title && title !== '' ? title : 'Apakah anda yakin?'}
        </CardTitle>
        <CardText className="mt-2" style={{ color: '#475467' }}>
          {desc && desc !== ''
            ? desc
            : 'Data yang sudah dihapus tidak dapat dikembalikan.'}
        </CardText>
        {errMsg ? (
          <small className="text-danger">
            <AlertCircle size={15} /> {errMsg}
          </small>
        ) : (
          <></>
        )}
      </div>

      <div className="d-flex gap-3 m-4">
        <Button className="button-cancel" onClick={onClose} outline block>
          Batal
        </Button>
        <Button
          disabled={isLoading}
          onClick={() => onRemoveData(dataId)}
          block
          className="button-delete"
        >
          {isLoading ? (
            <Spinner size="sm" style={{ color: 'white' }} />
          ) : buttonRightText ? (
            buttonRightText
          ) : (
            'Hapus'
          )}
        </Button>
      </div>
    </Modal>
  )
}

export default PMBModalRemove
