import IPhoto from 'assets/icons/Pak-Tani.png'
import IcRepoStore from 'assets/icons/ic-repo-store.svg'
import IcRepoTime from 'assets/icons/ic-repo-time.png'
import ICTransaction from 'assets/icons/icon-transaksi.svg'
import CustomButton from 'components/Buttons/CustomButton'
import LoadingAnimation from 'components/LoadingAnimation'
import PMBPagination from 'components/PMBPagination'
import SidebarDetails from 'components/SidebarDetails'
import { TableComponent as DataTable } from 'components/TableComponent'
import { getFileExtension } from 'helpers'
import { useLocalStorageTable as storage } from 'hooks/useLocalStorageTable'
import { useState } from 'react'
import { X } from 'react-feather'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardText, Container } from 'reactstrap'
import {
  formatDateOnlyPMB,
  formatDatePMB,
  formatDatePMBV2,
} from 'utils/formatDate'
import {
  useApproveAutoRepoByUserMutation,
  useGetRepoListQuery,
} from '../commodityRepoApiSlice'
import BadgeRepo, { STATUS } from '../components/BadgeRepo'
import ModalConfirmReject from '../components/ModalConfirmReject'

const ListRepo = () => {
  const auth = useSelector((s) => s.auth)
  const { options, handlePrevPage, handleNextPage } = storage('auto_repo_table')
  const [pagination, setPagination] = useState({ size: 10, page: 0 })
  const intl = useIntl()
  const repoList = useGetRepoListQuery(pagination)
  const { data, isLoading, refetch } = repoList
  const [approveByUser, { isLoading: approveLoading }] =
    useApproveAutoRepoByUserMutation()

  const [selectedRepo, setSelectedRepo] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isDownloadLoading, setIsDownloadLoading] = useState(false)
  const [isLoadingApprove, setIsLoadingApprove] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const [isOpenRejectModal, setIsOpenRejectModal] = useState(false)

  const columns = [
    {
      name: <span>Komoditas</span>,
      cell: (row) => {
        return (
          <div className="flex-centered gap-2">
            <>
              <img
                src={row?.item?.linkFoto ?? IPhoto}
                alt="pak tani"
                width={25}
                height={25}
                className="rounded-circle"
              />
              <span>{row?.item?.nameItem}</span>
            </>
          </div>
        )
      },
      grow: 1,
    },
    {
      name: <span>Kuantitas</span>,
      grow: 1,
      cell: (row) => {
        return (
          <span>
            {intl.formatNumber(row?.unitAmount, {
              maximumFractionDigits: 0,
              useGrouping: 'always',
            })}
            {` `}
            {row?.unitName}
          </span>
        )
      },
    },
    {
      name: <span>Sumber dokumen repo</span>,
      grow: 1,
      cell: (row) => {
        return <span>{row?.name}</span>
      },
    },
    {
      name: <span>Permintaan repo</span>,
      grow: 1,
      cell: (row) => {
        return (
          <span className="fw-bold">
            {`Rp`}
            {intl.formatNumber(row?.amountRequested, {
              maximumFractionDigits: 2,
              useGrouping: 'always',
            })}
          </span>
        )
      },
    },
    {
      name: <span>Status</span>,
      grow: 1,
      cell: (row) => {
        return <BadgeRepo status={row?.status} />
      },
    },
    {
      name: <span>Dibuat Pada</span>,
      grow: 1,
      cell: (row) => {
        return (
          <div className="d-flex flex-column">
            {formatDatePMB(row?.createdAt)}
          </div>
        )
      },
    },
    {
      name: '',
      grow: 1,
      cell: (row) => {
        return (
          <span
            className="w-100 fw-bold"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setIsOpen(true)
              setSelectedRepo(row)
            }}
          >
            Lihat Detil
          </span>
        )
      },
    },
  ]

  async function downloadPDF(url, filename, userid) {
    try {
      setIsDownloadLoading(true)
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const blob = await response.blob()
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.error(
        'There has been a problem with your fetch operation:',
        error,
      )
    } finally {
      setIsDownloadLoading(false)
    }
  }

  async function approveRepoByUser() {
    try {
      setIsLoadingApprove(true)
      const data = await approveByUser({
        userautorepoId: selectedRepo?.id,
      }).unwrap()
      refetch()
      setSuccessMsg(`Penawaran repo berhasil diambil`)
      setIsOpen(false)
    } catch (error) {
      console.log(error?.response)
    } finally {
      setIsLoadingApprove(false)
    }
  }

  return (
    <>
      <SidebarDetails
        open={isOpen}
        toggleSidebar={() => setIsOpen(!isOpen)}
        title="Detail Repo"
        size="lg"
        headerClassName="mb-1"
        contentClassName="pt-0"
      >
        <Container className="h-100 w-100">
          <Card body className="mb-3 border-0 shadow-sm">
            <div className="d-flex gap-2 flex-row">
              <div className="flex-centered justify-content-start gap-1">
                <img
                  src={ICTransaction}
                  alt="ava"
                  width={30}
                  height={30}
                  className="rounded-circle"
                />
              </div>
              <div>
                <CardText tag="h6" className="fw-bold text-primary">
                  Alur Repo
                </CardText>
                <CardText tag="p" className="m-0 text-sm">
                  Pembeli membuat transaksi:
                </CardText>
                <CardText
                  tag="p"
                  className="text-sm"
                >{`(${formatDatePMBV2(selectedRepo?.createdAt)})`}</CardText>
              </div>
            </div>
          </Card>
          <Card body className="mb-3 border-0 shadow-sm">
            <div className="d-flex gap-2 flex-column">
              <CardText tag="h6" className="fw-bold">
                Sumber Dokumen Repo
              </CardText>

              <div className="flex-centered justify-content-start gap-2">
                <img
                  src={IcRepoStore}
                  alt="ava"
                  width={30}
                  height={30}
                  className="rounded-circle"
                />
                <span className="text-sm">{selectedRepo?.name}</span>
              </div>
            </div>
          </Card>
          <Card body className="mb-3 border-0 shadow-sm">
            <div className="d-flex gap-2 flex-column">
              <CardText tag="h6" className="fw-bold">
                Tanggal repo akan dibayarkan oleh penjamin repo
              </CardText>
              <div className="flex-centered justify-content-start gap-2">
                <img
                  src={IcRepoTime}
                  alt="ava"
                  width={30}
                  height={30}
                  className="rounded-circle"
                />
                <span className="text-sm">{` ${formatDateOnlyPMB(selectedRepo?.invoiceDate)}`}</span>
              </div>
            </div>
          </Card>
          {/* Rincian Pesanan */}
          <Card body className="mb-3 border-0 shadow-sm">
            <CardText tag="h6" className="fw-bold">
              Rincian Komoditas Repo
            </CardText>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex flex-column mb-2">
                <span className="text-sm fw-semibold">
                  {selectedRepo?.item?.nameItem}
                </span>
                <span className="fw-light text-sm">
                  {selectedRepo?.unitAmount} {selectedRepo?.unitName}{' '}
                </span>
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between align-items-center">
              <span className="text-sm">Total</span>
              <span className="text-sm">
                {`Rp`}
                {intl.formatNumber(selectedRepo?.amountInvoice, {
                  maximumFractionDigits: 2,
                  useGrouping: 'always',
                })}{' '}
              </span>
            </div>
          </Card>
          {/* request amount */}
          <Card body className="mb-3 border-0 shadow-sm">
            <CardText tag="h6" className="fw-bold">
              Permintaan Jumlah Repo
            </CardText>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex flex-column mb-2">
                <span className="text-sm fw-semibold">
                  {selectedRepo?.item?.nameItem}
                </span>
                <span className="fw-light text-sm">
                  {selectedRepo?.unitAmount} {selectedRepo?.unitName}{' '}
                </span>
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between align-items-center">
              <span className="fw-bold text-sm">Total</span>
              <span className="fw-bold text-sm">
                {`Rp`}
                {intl.formatNumber(selectedRepo?.amountRequested, {
                  maximumFractionDigits: 2,
                  useGrouping: 'always',
                })}{' '}
              </span>
            </div>
          </Card>
          {/* Dokumen Repo */}
          <Card body className="mb-3 border-0 shadow-sm">
            <CardText tag="h6" className="fw-bold">
              Dokumen Repo
            </CardText>
            <div
              className="d-flex flex-column justify-content-between align-items-center"
              style={{ gap: '0.25rem' }}
            >
              {selectedRepo?.invoiceLinks?.split(',')?.map((e, i) => {
                if (getFileExtension(e) === null) {
                  return (
                    <CustomButton
                      key={i}
                      className="w-100 rounded border-1"
                      style={{
                        padding: '8px 14px',
                      }}
                      disabled={true}
                      block
                      color="primary"
                      outline
                    >
                      No Dokumen
                    </CustomButton>
                  )
                } else {
                  return (
                    <CustomButton
                      className="w-100 rounded border-1"
                      style={{
                        padding: '8px 14px',
                      }}
                      key={i}
                      onClick={() =>
                        downloadPDF(
                          e,
                          `dokumen-repo-${i + 1}`,
                          selectedRepo?.userId,
                        )
                      }
                      disabled={isDownloadLoading}
                      block
                      color="primary"
                      outline
                    >
                      Lihat Dokumen {`${getFileExtension(e)}`}
                    </CustomButton>
                  )
                }
              })}
            </div>
          </Card>

          {/* Status Dokumen Repo */}
          {selectedRepo?.status === STATUS.PENDING && (
            <Card className="p-3 badge-repo-pending border-0 shadow-sm">
              <span className="text-center">Pengajuan Sedang Ditinjau</span>
            </Card>
          )}
          {selectedRepo?.status === STATUS.APPROVEDBYADMIN && (
            <div className="p-2 d-flex">
              <CustomButton
                className="w-100 me-1"
                color="secondary"
                onClick={() => setIsOpenRejectModal(true)}
                style={{
                  backgroundColor: '#fff',
                  color: '#344054',
                  cursor:
                    isLoading || approveLoading || isLoadingApprove
                      ? 'not-allowed'
                      : 'pointer',
                }}
                disabled={isLoading || approveLoading || isLoadingApprove}
              >
                Tolak
              </CustomButton>
              <CustomButton
                className="w-100"
                color="primary"
                onClick={approveRepoByUser}
                disabled={isLoading || approveLoading || isLoadingApprove}
                style={{
                  cursor:
                    isLoading || approveLoading || isLoadingApprove
                      ? 'not-allowed'
                      : 'pointer',
                }}
              >
                Terima
              </CustomButton>
            </div>
          )}

          {(selectedRepo?.status === STATUS.APPROVEDBYALL ||
            selectedRepo?.status === STATUS.APPROVEDBYUSER) && (
            <Card className="p-3 badge-repo-success border-0 shadow-sm">
              <span className="text-left">
                Limit talangin kamu akan ditambahkan sesuai dengan jumlah repo
                yang disetujui. Pastikan untuk selalu di cek secara berkala
              </span>
            </Card>
          )}
        </Container>
        {/* Modal Reject */}
        <ModalConfirmReject
          isOpen={isOpenRejectModal}
          refetch={refetch}
          setSuccessMsg={(e) => {
            setSuccessMsg(e)
            setTimeout(() => setIsOpen(false), 300)
          }}
          data={selectedRepo}
          toggle={() => {
            setIsOpenRejectModal(false)
            setSuccessMsg('')
          }}
        />
      </SidebarDetails>
      <Card body className="mb-3 mt-0 border-0 shadow-sm">
        <CardBody className="py-0 px-3 d-flex justify-content-between mt-4">
          <CardText tag="h4">Daftar Pengajuan Repo</CardText>
        </CardBody>

        <CardBody>
          {successMsg && (
            <div
              className="alert badge-repo-success"
              style={{ padding: '0.5rem', position: 'relative' }}
              role="alert"
            >
              <span
                style={{
                  position: 'absolute',
                  right: '10px',
                  cursor: 'pointer',
                  padding: '0.08rem',
                }}
                onClick={() => {
                  setSuccessMsg('')
                }}
              >
                <X size={14} />
              </span>
              <span className="ps-2">{successMsg}</span>
            </div>
          )}
          <DataTable
            columns={columns}
            data={data?.items}
            progressPending={isLoading}
            progressComponent={<LoadingAnimation />}
            pagination
            paginationServer
            paginationComponent={(props) => (
              <PMBPagination
                rowCount={data?.totalItems || 0}
                currentPage={pagination?.page}
                rowsPerPage={pagination?.size}
                setCurrentPage={(e) => {
                  setPagination((prev) => ({
                    ...prev,
                    page: e,
                  }))
                }}
                setRowsPerPage={(e) => {
                  setPagination((prev) => ({
                    ...prev,
                    size: e,
                  }))
                }}
                props={props}
              />
            )}
            // subHeader={true}
            // subHeaderAlign={'center'}
            // subHeaderComponent={<></>}
          />
        </CardBody>
      </Card>
    </>
  )
}

export default ListRepo
