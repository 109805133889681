import { createAppSlice } from 'app/createAppSlice'

const initialState = {
  tag: {
    size: 10,
    page: 0,
  },
  details: null,
}

export const companiesListSlice = createAppSlice({
  name: 'companiesList',
  initialState,
  reducers: (create) => ({
    setFetchOption: create.reducer((state, { payload }) => {
      state.tag.size = payload.size
      state.tag.page = payload.page
    }),
  }),
})

export const { setFetchOption } = companiesListSlice.actions

export default companiesListSlice.reducer
