import { createAppSlice } from 'app/createAppSlice'

const initialState = {
  isOpenSidebar: false,
  tag: {
    size: 10,
    page: 0,
  },
  tagGroups: {
    size: 10,
    page: 0,
  },
  isOpenBanner: false,
  isOpenBannerGroups: false,
  details: null,
  isOpenModal: false,
  loading: false,
}

export const staffListSlice = createAppSlice({
  name: 'staffList',
  initialState,
  reducers: (create) => ({
    toggleSidebarWithdraw: create.reducer((state, { payload }) => {
      state.isOpenSidebar = !state.isOpenSidebar
    }),
    setFetchOption: create.reducer((state, { payload }) => {
      state.tag = { ...state.tag, ...payload }
    }),
    setFetchOptionGroups: create.reducer((state, { payload }) => {
      state.tagGroups.size = payload.size
      state.tagGroups.page = payload.page
    }),
    setFetchOptionSubGroup: create.reducer((state, { payload }) => {
      state.tags[payload.id].size = payload.size
      state.tags[payload.id].page = payload.page
    }),
    toggleBanner: create.reducer((state, { payload }) => {
      state.isOpenBanner = !state.isOpenBanner
    }),
    toggleBannerGroups: create.reducer((state, { payload }) => {
      state.isOpenBannerGroups = !state.isOpenBannerGroups
    }),
    toggleModal: create.reducer((state, { payload }) => {
      state.isOpenModal = !state.isOpenModal
    }),
  }),
  extraReducers: (builder) => {},
})

export const {
  setFetchOption,
  toggleSidebarWithdraw,
  toggleBanner,
  toggleModal,
  setFetchOptionGroups,
  setFetchOptionSubGroup,
  toggleBannerGroups,
} = staffListSlice.actions

export default staffListSlice.reducer
