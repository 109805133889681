import BreadCrumbs from 'components/breadcrumbs'
import CustomButton from 'components/Buttons/CustomButton'
import RoundedComponent from 'components/Rounded'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { AlertCircle, MinusCircle, Plus } from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import {
  Button,
  Card,
  CardBody,
  CardText,
  Container,
  FormGroup,
  Label,
} from 'reactstrap'
import { URL_TRANSFER_INVENTORY } from 'utils/pathUrl'
import { getPackingDetail } from './transferInventoryAction'
import {
  useLazyFetchInventoryQuery,
  useLazyFetchInventoryTransferQuery,
  useLazyFetchPackingQuery,
  useLazyFetchWarehousesQuery,
  usePostTransferMutation,
} from './transferInventoryApiSlice'

const FormTransferInventory = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const intl = useIntl()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [listOptions, setListOptions] = useState([])
  const [listOptionsWarehouse, setListOptionsWarehouse] = useState([])
  const [errMsg, setErrMsg] = useState('')
  const [payload, setPayload] = useState(null)
  const [pics, setPics] = useState([''])
  const [createTransfer, resultTransfer] = usePostTransferMutation()
  const [getInventory, res] = useLazyFetchInventoryQuery()
  const [getGudang, resWarehouse] = useLazyFetchWarehousesQuery()
  const [getPacking, resPackings] = useLazyFetchPackingQuery()
  const [getTfInventori, resTfInventori] = useLazyFetchInventoryTransferQuery()

  const handleSubmit = async () => {
    try {
      const inventories = []

      for (const e of pics) {
        if (e?.isPacking) {
          const res = await dispatch(getPackingDetail({ id: e?.id }))

          if (res?.payload?.contents) {
            inventories.push(
              ...res.payload.contents.map((item) => ({
                id: item?.id,
                amount: item?.amount,
              })),
            )
          }
        } else {
          inventories.push({
            id: e?.id,
            amount: e?.amount,
          })
        }
      }

      const body = {
        toWarehouseId: payload?.warehouse?.id,
        inventories,
      }

      const result = await createTransfer(body)
      if (result?.error) {
        throw result
      } else {
        navigate(URL_TRANSFER_INVENTORY)
        enqueueSnackbar(`Pengiriman Inventori berhasil dibuat.`, {
          variant: 'success',
        })
        await getTfInventori({})
      }
    } catch (error) {
      setErrMsg(error?.error?.data?.message ?? error?.message)
    }
  }

  const handleAdd = () => {
    setErrMsg('')
    setPics([...pics, ''])
  }

  const handleDelete = (index) => {
    setErrMsg('')
    setPics(pics.filter((_, i) => i !== index))
  }

  const getFilteredOptions = () => {
    const selectedIds = pics?.map((pic) => Number(pic?.id))
    return listOptions?.map((group) => ({
      ...group,
      options: group.options?.map((option) => ({
        ...option,
        isDisabled: selectedIds?.includes(option?.id),
      })),
    }))
  }

  useEffect(() => {
    let isMounted = true
    setLoading(true)

    const fetchData = async () => {
      try {
        let resInv = await getInventory({
          isForTransaction: 1,
          groupId: 'null',
        })

        if (
          isMounted &&
          resInv?.data?.totalItems > resInv?.data?.items?.length
        ) {
          const updatedResInv = await getInventory({
            isForTransaction: 1,
            size: resInv?.data?.totalItems,
            groupId: 'null',
          })

          if (updatedResInv?.data) {
            resInv = updatedResInv
          }
        }

        let resGudang = await getGudang({})
        if (
          isMounted &&
          resGudang?.data?.totalItems > resGudang?.data?.items?.length
        ) {
          const updatedResGudang = await getGudang({
            size: resGudang?.data?.totalItems,
          })
          if (updatedResGudang?.data) {
            resGudang = updatedResGudang
          }
        }

        let resPacking = await getPacking({ isForTransaction: 1 })
        if (
          isMounted &&
          resPacking?.data?.totalItems > resPacking?.data?.items?.length
        ) {
          const updatedResPacking = await getPacking({
            isForTransaction: 1,
            size: resPacking?.data?.totalItems,
          })
          if (updatedResPacking?.data) {
            resPacking = updatedResPacking
          }
        }

        if (isMounted) {
          setListOptions([
            {
              label: 'GRUP INVENTORI',
              options: resPacking?.data?.items?.map((e) => ({
                label: <span>{e?.label}</span>,
                value: e?.id,
                id: e?.id,
                isPacking: true,
              })),
            },
            {
              label: 'SEMUA INVENTORI',
              options: resInv?.data?.items?.map((e) => ({
                amount: e?.amount,
                label: (
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex gap-2 align-items-center">
                      <RoundedComponent
                        childern={
                          <img
                            width={29}
                            height={29}
                            alt={e?.id}
                            src={e?.item?.linkFoto}
                          />
                        }
                        style={{
                          width: '29px',
                          height: '29px',
                        }}
                      />
                      <span>{e?.item?.nameItem}</span>
                    </div>
                    <span>
                      {intl.formatNumber(e?.amount, {
                        useGrouping: 'always',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      })}{' '}
                      {e?.unit || e?.item?.unit}{' '}
                    </span>
                  </div>
                ),
                value: e?.id,
                id: e?.id,
              })),
            },
          ])

          setListOptionsWarehouse(resGudang?.data?.newData)
        }
      } catch (error) {
        if (isMounted) {
          setErrMsg(error?.message ?? 'Gagal mengambil data')
        }
      } finally {
        setLoading(false)
      }
    }

    fetchData()

    return () => {
      isMounted = false
    }
  }, [])

  return (
    <>
      <Container className="custom-container d-flex flex-column gap-1 py-2">
        <div className="pt-3">
          <BreadCrumbs
            breadCrumbActive={'Kirim Inventori'}
            breadCrumbParent={'Transfer Inventori'}
            breadCrumbParentLink={URL_TRANSFER_INVENTORY}
          />
        </div>
        <Card className="border-0 shadow-sm">
          <CardBody>
            <CardText tag="h5" className="pb-4">
              Kirim Inventori
            </CardText>
            <FormGroup>
              <Label className="fw-500">Pilih gudang</Label>
              <Select
                id="gudang"
                name="gudang"
                className="flex-grow-1"
                placeholder="Pilih gudang tujuan"
                isLoading={loading}
                options={listOptionsWarehouse ?? []}
                value={payload?.warehouse}
                onChange={(e) => {
                  setErrMsg('')
                  setPayload((prev) => ({
                    ...prev,
                    warehouse: e,
                  }))
                }}
              />
            </FormGroup>

            <FormGroup>
              <Label className="fw-500">Inventori yang dipindahkan</Label>
              <div className="d-flex flex-column gap-3">
                {pics?.map((pic, index) => (
                  <div
                    key={pic.id}
                    className="d-flex w-100 align-items-center gap-2"
                  >
                    <Select
                      isLoading={loading}
                      className="flex-grow-1"
                      placeholder="Pilih inventori"
                      options={getFilteredOptions() ?? []}
                      value={pics[index]}
                      onChange={(e) => {
                        setErrMsg('')
                        const updatedPics = [...pics]
                        updatedPics[index] = e
                        setPics(updatedPics.filter((pic) => pic !== ''))
                      }}
                    />

                    <MinusCircle
                      onClick={() => handleDelete(index)}
                      style={{
                        visibility: index === 0 ? 'hidden' : '',
                      }}
                      className="text-danger pointer"
                    />
                  </div>
                ))}
              </div>
            </FormGroup>

            <FormGroup>
              <CustomButton
                onClick={handleAdd}
                outline
                color="primary"
                size="sm"
              >
                <Plus size={20} /> Tambah
              </CustomButton>
            </FormGroup>
            <hr></hr>

            {errMsg && (
              <FormGroup>
                <small className="text-danger">
                  <AlertCircle size={15} /> {errMsg}
                </small>
              </FormGroup>
            )}

            <div className="d-flex gap-3">
              <Button
                onClick={() => navigate(URL_TRANSFER_INVENTORY)}
                outline
                color="primary"
                block
              >
                Batal
              </Button>
              <CustomButton
                onClick={() => handleSubmit()}
                disabled={
                  res?.isLoading ||
                  resPackings?.isLoading ||
                  resWarehouse?.isLoading ||
                  resultTransfer?.isLoading ||
                  !payload?.warehouse ||
                  pics[0] === ''
                }
                block
                color="primary"
              >
                Kirim Inventori
              </CustomButton>
            </div>
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default FormTransferInventory
