import ICWarning from 'assets/icons/warning-icon.svg'
import CustomButton from 'components/Buttons/CustomButton'
import { Modal, ModalBody } from 'reactstrap'

const ModalDeletePaymentMethod = ({
  isOpen,
  toggle,
  methodName,
  onConfirm,
}) => {
  return (
    <Modal centered toggle={toggle} isOpen={isOpen}>
      <ModalBody>
        <img src={ICWarning} alt="Warning Icon" />
        <h6 className="mt-4 fw-600">Hapus metode pembayaran?</h6>
        <small>Anda yakin ingin menghapus metode pembayaran ini?</small>
        <div className="d-flex gap-3 mt-4">
          <CustomButton
            onClick={() => toggle()}
            block
            outline
            color="secondary"
            className="btn-cancel"
          >
            Batal
          </CustomButton>
          <CustomButton
            onClick={() => {
              onConfirm(methodName)
              toggle()
            }}
            block
            color="danger"
          >
            Hapus
          </CustomButton>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalDeletePaymentMethod
