import CustomButton from 'components/Buttons/CustomButton'
import InputPhoneNumber from 'components/InputPhoneNumber'
import { setIsV3 } from 'features/private-views/dashboard/dashboardSlice'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Container, FormGroup, Label } from 'reactstrap'
import {
  usePrecheckLoginMutation,
  useSendOtpLoginMutation,
} from './loginNewApiSlice'

const LoginNew = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = new URLSearchParams(window.location.search)
  const hash = params.get('deviceHash')
  const { token } = useSelector((s) => s.auth)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [deviceHash, setDeviceHash] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [preCheck, result] = usePrecheckLoginMutation()
  const [sendOtp, resultSendOtp] = useSendOtpLoginMutation()
  const [suggestions, setSuggestions] = useState([])

  // const [createDevice, resultCreateDevice] = useCreateDeviceMutation()
  // const [checkDevice] = useLazyCheckDeviceQuery()

  // const handleCheckDevice = async () => {
  //   try {
  //     const res = await checkDevice({ deviceHash })
  //     if (!res?.data?.data) {
  //       const res2 = await createDevice({
  //         deviceToken: deviceHash,
  //         deviceHash: deviceHash,
  //         deviceInfo: {
  //           hash: deviceHash,
  //         },
  //       })
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // SAVE PHONE NUMBER TO LOCAL STORAGE
  const savePhoneNumberToLocalStorage = (number) => {
    let storedNumbers = JSON.parse(localStorage.getItem('phoneNumbers')) || []

    storedNumbers = storedNumbers?.filter((num) => num !== number)

    storedNumbers.unshift(number)

    if (storedNumbers.length > 3) {
      storedNumbers = storedNumbers.slice(0, 3)
    }

    localStorage.setItem('phoneNumbers', JSON.stringify(storedNumbers))
  }

  const handleSubmit = async () => {
    try {
      setErrMsg('')
      const res = await preCheck({
        deviceHash: deviceHash ?? hash,
        identity: phoneNumber,
      })
      if (!res.error) {
        savePhoneNumberToLocalStorage(phoneNumber)

        if (
          res?.data?.data?.sendOtp &&
          res?.data?.data?.status === 'REGISTERED' &&
          res?.data?.data?.allow
        ) {
          const res5 = await sendOtp({
            identity: phoneNumber,
            deviceHash: deviceHash ?? hash,
            type: 'login',
            channel: 'WHATSAPP',
          })
          if (!res5?.data?.meta?.error) {
            navigate('/otp', {
              state: {
                phoneNumber,
                deviceHash: deviceHash ?? hash,
                countDown: res5?.data?.data?.expireTime,
              },
            })
          }
        } else if (
          !res?.data?.data?.sendOtp &&
          !res?.data?.data?.sendDeviceInfo &&
          res?.data?.data?.status === 'REGISTERED' &&
          res?.data?.data?.allow
        ) {
          navigate('/pin', {
            state: { deviceHash: deviceHash ?? hash, phoneNumber },
          })
        }
      } else {
        throw res
      }
    } catch (error) {
      setErrMsg(error?.error?.data?.meta?.message ?? error?.message)
      console.log(error)
    }
  }

  // GET NUMBERS FROM LOCAL STORAGE
  useEffect(() => {
    const storedNumbers = JSON.parse(localStorage.getItem('phoneNumbers')) || []
    setSuggestions(storedNumbers)
  }, [])

  useEffect(() => {
    if (hash) {
      setDeviceHash(hash)
    }
  }, [hash])

  useEffect(() => {
    if (token) {
      dispatch(setIsV3(true))
      navigate('/catatan-arus-kas')
    }
  }, [token])

  return (
    <>
      <Container
        style={{ minHeight: '100vh' }}
        className="d-flex justify-content-between flex-column py-3"
      >
        <div>
          <h5 className="fw-bold">Masuk atau daftar di sini</h5>
          <span>Masukkan nomor teleponmu untuk melanjutkan.</span>
          <div className="mt-4">
            <FormGroup>
              <Label className="fw-bold">No Telepon</Label>
              <InputPhoneNumber
                placeholder="Masukkan nomor telepon"
                value={phoneNumber}
                setValue={(e) => {
                  setPhoneNumber(e)
                  setErrMsg('')
                }}
                list="phone-suggestions"
              />
              <datalist id="phone-suggestions">
                {suggestions?.map((suggestion, index) => (
                  <option key={index} value={suggestion} />
                ))}
              </datalist>
            </FormGroup>
            <p>
              Butuh bantuan?{' '}
              <span
                className="text-primary"
                onClick={() =>
                  window.open('https://wa.me/+6285157570025', '_blank')
                }
              >
                Hubungi kami
              </span>
            </p>
            <p className="text-danger">{errMsg}</p>
          </div>
        </div>
        <div>
          <CustomButton
            disabled={
              !phoneNumber || result?.isLoading || resultSendOtp?.isLoading
            }
            onClick={handleSubmit}
            color="primary"
            block
          >
            Lanjut
          </CustomButton>
          <hr></hr>
          <p className="text-center">
            Dengan masuk atau daftar, kamu menyetujui{' '}
            <span
              className="text-primary"
              onClick={() =>
                navigate('/syarat-dan-ketentuan', {
                  state: { isNewLogin: true },
                })
              }
            >
              {' '}
              Ketentuan Layanan
            </span>{' '}
            dan
            <span
              className="text-primary"
              onClick={() =>
                navigate('/syarat-dan-ketentuan', {
                  state: { isNewLogin: true },
                })
              }
            >
              {' '}
              Kebijakan Privasi
            </span>{' '}
            dari PasarMIKRO
          </p>
          <div className="text-xs text-center text-muted">
            {process.env.REACT_APP_VERSION}
          </div>
        </div>
      </Container>
    </>
  )
}

export default LoginNew
