import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Modal, ModalBody } from 'reactstrap'
import { getGroupCount, getGroupList } from '../groups-list/groupListAction'
import { useDeleteGroupMutation } from './farmersListApiSlice'
import { toggleModalDelete } from './farmersListSlice'

const ModalConfirmDelete = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isOpenModalDelete } = useSelector((s) => s.farmersList)
  const code = window.location.href.substring(
    window.location.href.lastIndexOf('/') + 1,
  )
  const [errMsg, setErrMsg] = useState('')
  const [deleteGroup, result] = useDeleteGroupMutation()

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const { data } = await deleteGroup({ code: code }).unwrap()
      if (data?.data?.meta?.error) {
        throw data
      } else {
        dispatch(toggleModalDelete())
        dispatch(getGroupList())
        dispatch(getGroupCount())
        enqueueSnackbar('Grup berhasil dihapus.', { variant: 'success' })
        navigate('/farmers/groups')
      }
    } catch (error) {
      setErrMsg(error?.data?.meta?.message)
    }
  }

  return (
    <Modal
      className="p-4"
      centered
      isOpen={isOpenModalDelete}
      toggle={() => {
        dispatch(toggleModalDelete())
        setErrMsg('')
      }}
      autoFocus={false}
    >
      <ModalBody>
        <h5>Hapus Grup</h5>
        <p className="text-muted">Anda yakin ingin menghapus grup ini?</p>

        {errMsg && (
          <small className="text-danger">
            <AlertCircle size={15} /> {errMsg}
          </small>
        )}
        <div className="d-flex gap-3">
          <Button
            onClick={() => {
              dispatch(toggleModalDelete())
              setErrMsg('')
            }}
            disabled={result?.isLoading}
            color="secondary"
            outline
            block
          >
            Batal
          </Button>
          <Button
            disabled={result?.isLoading}
            onClick={handleSubmit}
            color="danger"
            block
          >
            Hapus
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalConfirmDelete
