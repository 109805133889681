import { createAppSlice } from 'app/createAppSlice'
import { getDetailProfile, getPricing } from './dashboardAction'

const initialState = {
  isOpenSidebarWithdraw: false,
  profile: {
    isLoading: false,
    data: null,
  },
  balance: {
    talangin: {
      isLoading: false,
    },
    uangBelanja: {},
    uangUsaha: {},
  },
  loading: false,
  pricing: [],
  detail: {},
  isOpenBanner: false,
  bannerMessage: '',
  bannerType: 'success',
  isOpenNavbar: false,
  ownerData: {},
  cashPayment: false,
  isOpenSidebarFilter: false,
  isV3: false,
  tag: {
    size: 10,
    page: 0,
    type: 'BUY',
  },
}

export const dashboardSlice = createAppSlice({
  name: 'dashboard',
  initialState,
  reducers: (create) => ({
    addBalance: create.reducer((state, { payload }) => {
      state.balance.talangin = payload.talangin
      state.balance.uangBelanja = payload.wallet1
      state.balance.uangUsaha = payload.wallet2
      state.cashPayment = payload.cashPayment
    }),
    toggleSidebarWithdraw: create.reducer((state, { payload }) => {
      state.isOpenSidebarWithdraw = !state.isOpenSidebarWithdraw
    }),
    toggleBanner: create.reducer((state, { payload }) => {
      state.isOpenBanner = !state.isOpenBanner
    }),
    setMessageBanner: create.reducer((state, { payload }) => {
      state.bannerMessage = payload
    }),
    setBannerType: create.reducer((state, { payload }) => {
      state.bannerType = payload
    }),
    setTypeFetch: create.reducer((state, { payload }) => {
      state.tag.type = payload
    }),
    setProfile: create.reducer((state, { payload }) => {
      state.profile = payload
    }),
    setOwnerData: create.reducer((state, { payload }) => {
      state.ownerData = payload
    }),
    toggleNavbar: create.reducer((state, { payload }) => {
      state.isOpenNavbar = !state.isOpenNavbar
    }),
    toggleSidebarFilter: create.reducer((state, { payload }) => {
      state.isOpenSidebarFilter = !state.isOpenSidebarFilter
    }),
    setIsV3: create.reducer((state, { payload }) => {
      state.isV3 = payload
    }),
    setFetchOption: create.reducer((state, { payload }) => {
      state.tag = { ...state.tag, ...payload }
    }),
  }),
  extraReducers: (builder) => {
    builder.addCase(getPricing.fulfilled, (state, { payload }) => {
      state.pricing = payload
      state.loading = false
    })
    builder.addCase(getPricing.pending, (state, { payload }) => {
      state.loading = true
    })
    builder.addCase(getPricing.rejected, (state, { payload }) => {
      state.loading = false
    })
    builder.addCase(getDetailProfile.fulfilled, (state, { payload }) => {
      state.detail = payload
      state.loading = false
    })
    builder.addCase(getDetailProfile.pending, (state, { payload }) => {
      state.loading = true
    })
    builder.addCase(getDetailProfile.rejected, (state, { payload }) => {
      state.loading = false
    })
  },
})

export const {
  toggleSidebarWithdraw,
  addBalance,
  toggleBanner,
  setMessageBanner,
  setBannerType,
  setTypeFetch,
  setProfile,
  setOwnerData,
  toggleNavbar,
  toggleSidebarFilter,
  setIsV3,
  setFetchOption,
} = dashboardSlice.actions

export default dashboardSlice.reducer
