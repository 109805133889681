import axios from 'axios'
import AmountInput from 'components/AmountInput'
import CustomButton from 'components/Buttons/CustomButton'
import { useEffect, useState } from 'react'
import { AlertCircle, Plus, Trash2 } from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap'
import { apiEndpoint } from '../../utility/Utils'
import { setTotal } from './commodityPurchaseSlice'

const ComodityForm = ({ handleFinished, title, isSet, handleCbData }) => {
  const dispatch = useDispatch()
  const [comodities, setComodities] = useState([{ id: 1 }])
  const [dataComodities, setDatComodities] = useState([
    { unit: { value: 'Kg', label: 'Kg' } },
  ])
  const [notes, setNotes] = useState('')
  const [listComodities, setListComodities] = useState([])
  const [errMsg, setErrMsg] = useState('')
  const [units, setUnits] = useState([])
  const { token } = useSelector((e) => e.auth)
  const intl = useIntl()

  const handleChange = (key, e, index) => {
    setDatComodities((prevValues) => {
      const newData = [...prevValues]
      newData[index] = {
        ...newData[index],
        [key]: e,
        totalPrice:
          key === 'price' && dataComodities[index]?.quantity
            ? Number(
                e?.replace?.(/\./g, '')?.replace?.(/\,/g, '.')?.slice?.(0, 15),
              ) *
              Number(
                newData[index]?.quantity
                  ?.replace?.(/\./g, '')
                  ?.replace?.(/\,/g, '.')
                  ?.slice?.(0, 15),
              )
            : key === 'quantity' && dataComodities[index]?.price
              ? Number(
                  newData[index]?.price
                    ?.replace?.(/\./g, '')
                    ?.replace?.(/\,/g, '.')
                    ?.slice?.(0, 15),
                ) *
                Number(
                  e
                    ?.replace?.(/\./g, '')
                    ?.replace?.(/\,/g, '.')
                    ?.slice?.(0, 15),
                )
              : newData[index]?.totalPrice,
      }
      return newData
    })
  }

  const addComodity = () => {
    const newId = comodities.length + 1
    setComodities([...comodities, { id: newId }])
  }

  const removeComodity = (id) => {
    setComodities(comodities.filter((comodity) => comodity.id !== id))

    const indexToRemove = id - 1
    setDatComodities(dataComodities.filter((_, idx) => +idx !== indexToRemove))
  }

  const handleLastPick = (e) => {
    const newListComodities = [...listComodities]

    const updatedOptions = newListComodities[0]?.options?.filter(
      (item) => item.id !== e.id,
    )

    updatedOptions.unshift(e)

    if (updatedOptions.length > 5) {
      const removedItem = updatedOptions.pop()

      newListComodities[1] = {
        ...newListComodities[1],
        options: [...newListComodities[1].options, removedItem],
      }
    }

    newListComodities[0] = {
      ...newListComodities[0],
      options: updatedOptions,
    }

    newListComodities[1] = {
      ...newListComodities[1],
      options: newListComodities[1].options.filter((item) => item.id !== e.id),
    }

    localStorage.setItem(
      'lastPickedCommoditiesBuy',
      JSON.stringify(updatedOptions),
    )

    setListComodities(newListComodities)
  }

  const getComodities = async () => {
    try {
      if (listComodities.length < 1) {
        let frequentItems = []

        const storedFrequentItems =
          JSON.parse(localStorage.getItem('lastPickedCommoditiesBuy')) || []

        try {
          const resFrequent = await axios.get(
            `${apiEndpoint}/api/v1/bussines/item-frequent?type=buy`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )

          frequentItems = resFrequent.data.map((e) => ({
            value: e.id,
            label: e.nameItem,
            id: e.id,
            img: e.linkFoto,
            sku: e.SKU,
          }))

          frequentItems = [...storedFrequentItems, ...frequentItems]

          frequentItems = frequentItems.filter(
            (item, index, self) =>
              index === self.findIndex((i) => i.id === item.id),
          )

          frequentItems = frequentItems.slice(0, 5)

          localStorage.setItem(
            'lastPickedCommoditiesBuy',
            JSON.stringify(frequentItems),
          )
        } catch (error) {
          console.log(error)
        }

        const { data } = await axios.get(
          `${apiEndpoint}/api/v1/bussines/items?origin=true&sortby=nameItem&sortdir=asc`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )

        let allItems = data.items

        if (data.totalItems > data.items.length) {
          const res = await axios.get(
            `${apiEndpoint}/api/v1/bussines/items?origin=true&sortby=nameItem&sortdir=asc&size=${data.totalItems}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          allItems = res.data.items
        }

        const allMappedItems = allItems
          .map((e) => ({
            value: e.id,
            label: e.nameItem,
            id: e.id,
            img: e.linkFoto,
            sku: e.SKU,
          }))
          .filter(
            (item) =>
              !frequentItems.some((frequent) => frequent.id === item.id),
          )

        setListComodities([
          {
            label: 'TERAKHIR/SERING DIPILIH',
            options: frequentItems,
          },
          {
            label: 'SEMUA KOMODITAS',
            options: allMappedItems,
          },
        ])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getUnits = async () => {
    try {
      if (units.length < 1) {
        const { data } = await axios.get(`${apiEndpoint}/api/v1/public/version`)
        const newData = data?.unitList?.split(',')
        const newestData = newData.map((e) => ({
          value: e,
          label: e,
        }))
        setUnits(newestData)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeInputSeparator = (data, name, idx) => {
    if (data !== dataComodities[idx]?.[name]) {
      handleChange(name, data, idx)
    } else {
      return
    }
  }

  useEffect(() => {
    if (listComodities?.length < 1) {
      getComodities()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listComodities])

  useEffect(() => {
    if (units?.length < 1) {
      getUnits()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [units])

  useEffect(() => {
    if (isSet) {
      const obj = {
        dataComodities: dataComodities,
        comodities: comodities,
        notes: notes,
      }
      handleFinished(obj)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSet])

  useEffect(() => {
    if (dataComodities) {
      handleCbData({
        dataComodities,
      })
      dispatch(
        setTotal(dataComodities?.reduce((sum, e) => sum + e.totalPrice, 0)),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataComodities])

  return (
    <>
      <Card className="border-0 shadow-sm">
        <CardBody className="p-4">
          <CardTitle tag="h5" className="mb-4">
            {title}
          </CardTitle>
          {comodities?.map((comodity, index) => (
            <div key={comodity.id}>
              <FormGroup>
                <Label>Komoditas</Label>
                <Select
                  name="comodity"
                  placeholder="Pilih komoditas"
                  options={listComodities}
                  onChange={(e) => {
                    handleChange('comodity', e, index)
                    handleLastPick(e)
                  }}
                  value={dataComodities[index]?.comodity ?? ''}
                />
              </FormGroup>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>Jumlah</Label>
                    <AmountInput
                      name="quantity"
                      idx={index}
                      // value={dataComodities[index]?.quantity ?? ''}
                      cb={handleChangeInputSeparator}
                      placeholder="0"
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>Satuan</Label>
                    <Select
                      options={units}
                      name="unit"
                      onChange={(e) => {
                        handleChange('unit', e, index)
                      }}
                      placeholder="Pilih satuan"
                      value={dataComodities[index]?.unit ?? ''}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>Harga per satuan</Label>
                    <InputGroup>
                      <InputGroupText className="bg-transparent">
                        Rp
                      </InputGroupText>
                      <AmountInput
                        name="price"
                        idx={index}
                        // value={dataComodities[index]?.price ?? ''}
                        cb={handleChangeInputSeparator}
                        placeholder="0"
                        dotOnly
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>Total Harga</Label>
                    <InputGroup>
                      <InputGroupText className="bg-transparent">
                        Rp
                      </InputGroupText>
                      <Input
                        disabled={true}
                        type="text"
                        value={intl.formatNumber(
                          isNaN(dataComodities[index]?.totalPrice)
                            ? 0
                            : (dataComodities[index]?.totalPrice ?? 0),
                          {
                            useGrouping: 'always',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 3,
                          },
                        )}
                        onChange={() => {}}
                        name="totalPrice"
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              {index > 0 && (
                <div className="d-flex justify-content-end">
                  <CustomButton
                    outline
                    color="danger"
                    onClick={() => removeComodity(comodity.id)}
                  >
                    <Trash2 /> Hapus
                  </CustomButton>
                </div>
              )}
              <hr />
            </div>
          ))}
          <CustomButton outline color="primary" onClick={addComodity}>
            <Plus /> Tambah Komoditas
          </CustomButton>
        </CardBody>
      </Card>
      <Card className="border-0 shadow-sm">
        <CardBody className="p-4">
          <CardTitle tag="h5" className="mb-4">
            Catatan ke penjual
          </CardTitle>
          <Input
            type="textarea"
            style={{ minHeight: '128px' }}
            name="notes"
            onChange={(e) => {
              setNotes(e.target.value ?? '')
            }}
            value={notes}
            placeholder="Masukkan catatan jika ada"
          />
        </CardBody>
      </Card>

      {errMsg && (
        <div>
          <small className="text-danger">
            <AlertCircle size={15} /> {errMsg}
          </small>
        </div>
      )}
    </>
  )
}

export default ComodityForm
