import PMBPagination from 'components/PMBPagination'
import { TableComponent as DataTable } from 'components/TableComponent'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, Spinner } from 'reactstrap'
import { useFetchFarmersQuery } from '../farmers-list/farmersListApiSlice'
import { setFetchOptionPending } from '../farmers-list/farmersListSlice'

const FarmersPendingList = () => {
  const { tagPending } = useSelector((s) => s.farmersList)

  const code = window.location.href.substring(
    window.location.href.lastIndexOf('/') + 1,
  )
  const dispatch = useDispatch()

  let [page, setPage] = useState({ page: tagPending.page })
  const { data, isLoading } = useFetchFarmersQuery(
    {
      groupCode: code,
      size: tagPending?.size,
      page: tagPending?.page,
      status: 'PENDING',
    },
    { skip: !code },
  )

  const columns = [
    {
      name: 'Nama',
      cell: (row) => {
        return <span>{row?.name}</span>
      },
    },
    {
      name: 'Nomor Telepon',
      cell: (row) => {
        return <span>{row?.phoneNumber}</span>
      },
    },
  ]

  return (
    <div className="w-100 px-3 py-3">
      <Card className="border-0 shadow-sm">
        <CardBody>
          <DataTable
            columns={columns}
            data={data?.data?.items}
            progressPending={isLoading}
            progressComponent={<Spinner />}
            noDataComponent={
              <p className="my-3 fw-semibold">
                Belum ada pengguna yang didaftarkan
              </p>
            }
            subHeader
            subHeaderAlign="left"
            subHeaderComponent={
              <>
                <h5 className="fw-semibold">Menunggu Konfirmasi Anggota</h5>
                <p>
                  Anggota yang sudah memiliki akun perlu melakukan konfirmasi
                  via WhatsApp agar dapat bergabung sepenuhnya. Anda tetap bisa
                  menambahkan anggota lainnya sambil menunggu.
                </p>
              </>
            }
            pagination
            paginationServer
            paginationComponent={(props) => (
              <PMBPagination
                rowCount={data?.data?.totalItems || 0}
                currentPage={tagPending?.page}
                rowsPerPage={tagPending?.size}
                setCurrentPage={(e) => {
                  dispatch(setFetchOptionPending({ page: e }))
                }}
                setRowsPerPage={(e) => {
                  dispatch(setFetchOptionPending({ size: e }))
                }}
                props={props}
              />
            )}
          />
        </CardBody>
      </Card>
    </div>
  )
}

export default FarmersPendingList
