const TraceabilityTemporary = () => {
  return (
    <iframe
      src={`https://dev.ceritaproduk.id`}
      title="Traceability"
      style={{
        width: '100%',
        height: '100vh',
        border: 'none',
      }}
    />
  )
}

export default TraceabilityTemporary
