import axios from 'axios'
import CustomButton from 'components/Buttons/CustomButton'
import PMBModalRemove from 'components/PMBModalRemove'
import PMBPagination from 'components/PMBPagination'
import SidebarDetails from 'components/SidebarDetails'
import BreadCrumbs from 'components/breadcrumbs'
import { Can } from 'context/Acl'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import {
  ArrowDown,
  ArrowRight,
  CheckCircle,
  ChevronDown,
  Menu,
  Plus,
} from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap'
import { formatDateOnlyPMB } from 'utils/formatDate'
import { newFormatThousandSeparator } from 'utils/formatNumber'
import {
  BUSINESS_FARMERS,
  BUSINESS_TRIPS,
  BUSINESS_VESSELS,
  MANAGE,
  READ,
} from 'utils/subjectActions'
import VesselNotFound from '../../../../assets/icons/vessel-not-found.png'
import {
  useDeleteAgentMutation,
  useFetchFarmersDetailQuery,
  useFetchFarmersPlotQuery,
  useFetchUserCatchesQuery,
  useFetchUserVesselQuery,
  useInviteAgentMutation,
  useSetDefaultAgentMutation,
} from '../farmers-list/farmersListApiSlice'
import { toggleSidebarFarmer } from '../groups-list/groupsListSlice'
import ModalChangeRole from '../staffList/ModalChangeRole'
import { toggleModal as toggleModalChangeRole } from '../staffList/staffListSlice'
import AboutFarmersCard from './AboutFarmersCard'
import DetailModal from './DetailModal'
import ModalChangeProfil from './ModalChangeProfil'
import ModalDisbursement from './ModalDisbursement'
import ModalLandPlot from './ModalLandPlot'
import {
  setBannerMsg,
  toggleBannerDetail,
  toggleModalChangeProfile,
} from './detailSlice'

const DetailFarmers = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const dispatch = useDispatch()
  const userId = window.location.href.substring(
    window.location.href.lastIndexOf('/') + 1,
  )
  const { data, refetch } = useFetchFarmersDetailQuery({ id: userId })
  const resp = useFetchFarmersPlotQuery({ id: userId })

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedPlot, setSelectedPlot] = useState({})
  const [isClick, setIsClick] = useState(false)
  const [imgSelfieSrc, setImgSelfieSrc] = useState('')
  const [imgKtpSrc, setImgKtpSrc] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenDetail, setIsOpenDetail] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  const [isOpenModalDisbursement, setIsOpenModalDisbursement] = useState(false)
  const [isSwitchAgent, setIsSwitchAgent] = useState(false)
  const [isShowModalDeletePaymentMethod, setIsShowModalDeletePaymentMethod] =
    useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const toggleModal = () => setIsModalOpen(!isModalOpen)

  const { isOpenBanner, bannerMsg } = useSelector((s) => s.farmersDetail)
  const { tag } = useSelector((s) => s.farmersList)
  const { token } = useSelector((s) => s.auth)
  let [page, setPage] = useState(tag?.page ?? 0)

  const regions = isClick
    ? resp?.data?.data?.items
    : !isClick && resp?.data?.data?.items.length > 3
      ? resp?.data?.data?.items.slice(0, -(resp?.data?.data?.items.length - 3))
      : resp?.data?.data?.items

  const getSrc = (link, type) => {
    setIsLoading(true)
    const imageUrl = link
    const bearerToken = token

    axios
      .get(imageUrl, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
        responseType: 'blob',
      })
      .then((response) => {
        const imgUrl = URL.createObjectURL(response.data)
        if (type === 'img-ktp') {
          setImgKtpSrc(imgUrl)
        } else {
          setImgSelfieSrc(imgUrl)
        }
      })
      .catch((error) => {
        console.warn(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleFinishedChangeRole = () => {
    dispatch(setBannerMsg('Data akun berhasil diubah.'))
    dispatch(toggleBannerDetail())
    refetch()
  }

  useEffect(() => {
    if (data?.data?.imgSelfie) {
      getSrc(data?.data?.imgSelfie, 'img-selfie')
    }
    if (data?.data?.imgKTP) {
      getSrc(data?.data?.imgKTP, 'img-ktp')
    }
  }, [userId, data])

  // INVITE AGENT
  const [inviteAgent, { isLoading: isLoadingInviteAgent }] =
    useInviteAgentMutation()

  // DELETE AGENT
  const [deleteAgent, { isLoading: isLoadingDeleteAgent }] =
    useDeleteAgentMutation()

  // SET DEFAULT PAYMENT
  const [setDefaultAgent, { isLoading: isLoadingSetDefaultAgent }] =
    useSetDefaultAgentMutation()

  const isHaveAbilityBusinessVessels = useAbilityAccess({
    action: READ,
    subject: BUSINESS_VESSELS,
  })

  const isHaveAbilityBusinessTrips = useAbilityAccess({
    action: READ,
    subject: BUSINESS_TRIPS,
  })

  // USER VESSELS
  const responseUser = useFetchUserVesselQuery(
    { id: userId },
    { skip: !isHaveAbilityBusinessVessels },
  )
  const dataUser = responseUser?.data?.items ?? []

  // USER CATCHES
  const responseUserCatches = useFetchUserCatchesQuery(
    {
      id: userId,
      page: currentPage ?? 0,
      size: rowsPerPage ?? 10,
      // page: page,
      // size: 10,
    },
    { skip: !isHaveAbilityBusinessTrips },
  )
  const dataUserCatches = responseUserCatches?.data

  const { enqueueSnackbar } = useSnackbar()

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {
        marginTop: '1px',
        marginBottom: '1px',
      },
    },
    rows: {
      style: {
        marginTop: '1px',
        marginBottom: '1px',
        '&:hover': {
          backgroundColor: '#F9FAFB',
        },
      },
    },
  }

  const columns = [
    {
      name: <span>Tanggal Perjalanan</span>,
      cell: (row) => {
        const startDate = formatDateOnlyPMB(row?.data?.startFishing)
        const endDate = formatDateOnlyPMB(row?.data?.endFishing)
        return (
          <span>
            {startDate ?? ''} - {endDate ?? ''}
          </span>
        )
      },
    },
    {
      name: <span>Hasil Tangkapan</span>,
      cell: (row) => {
        const fishes = row?.data?.fishes ?? []

        const firstFish = fishes[0]
        const firstFishName = firstFish?.selectedFish?.nameItem
        const firstFishCode = firstFish?.selectedFish?.attribute?.code ?? ''
        const firstFishAmount = firstFish?.amount ?? 0

        const additionalFishesCount =
          fishes?.length > 1 ? fishes?.length - 1 : 0

        const unit = fishes?.[0]?.selectedFish?.unit ?? 'kg'

        return (
          <div>
            <div className="my-1">
              <span className="fw-semibold">
                {firstFishName} <em>({firstFishCode})</em>
              </span>
            </div>
            <div className="my-1">
              <span className="text-primary">
                {newFormatThousandSeparator(firstFishAmount)} {unit}
              </span>
            </div>

            {additionalFishesCount > 0 && (
              <Badge className="badge-gray my-1">
                + {additionalFishesCount} tangkapan lainnya
              </Badge>
            )}
          </div>
        )
      },
    },
    {
      name: <span>Kapal</span>,
      cell: (row) => {
        return (
          <Row className="align-items-center mb-2 d-flex flex-grow-1">
            <Col xs="3">
              <img
                src={row?.data?.vessel?.linkImage ?? VesselNotFound}
                alt={row?.data?.vessel?.vesselName}
                style={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '4px',
                }}
                loading="lazy"
              />
            </Col>

            <Col className="align-items-center" xs="8">
              <p className="fw-bold text-primary m-0">
                {row?.data?.vessel?.shipName}
              </p>
            </Col>
          </Row>
        )
      },
    },
    {
      name: <span>Total Penangkapan (kg)</span>,
      cell: (row) => {
        const unit = row?.data?.fishes?.[0]?.selectedFish?.unit ?? 'kg'
        const totalAmount = row.data.fishes.reduce(
          (sum, fish) => Number(sum) + Number(fish.amount),
          0,
        )
        // const unit =
        //   row?.data?.fishes?.length > 0
        //     ? row?.data?.fishes?.[0]?.selectedFish?.unit
        //     : 'kg'

        return (
          <span>
            {newFormatThousandSeparator(totalAmount)} {unit}
          </span>
        )
      },
    },
    {
      name: <span>WPP</span>,
      cell: (row) => {
        const catchAreas = [
          ...new Set(row?.data?.fishes?.map((fish) => fish?.catchArea)),
        ].join(', ')

        return <span>{catchAreas}</span>
      },
    },
    {
      name: '',
      cell: (row) => {
        return (
          <div className="d-flex gap-2">
            <span
              style={{ cursor: 'pointer' }}
              className="fw-semibold text-center"
              onClick={() => {
                setSelectedRow(row)
                setIsOpenDetail(true)
              }}
            >
              Lihat Detail
            </span>
          </div>
        )
      },
    },
  ]

  const toggleModalDeletePaymentMethod = () =>
    setIsShowModalDeletePaymentMethod((prev) => !prev)

  const toggleModalDisbursement = () => {
    setIsOpenModalDisbursement((prev) => !prev)
    setIsEdit(false)
  }

  const handleAddDisbursement = async (data) => {
    try {
      setIsEdit(false)
      const response = await inviteAgent({
        sellerUserId: Number(userId),
        agentUserId: Number(data?.agentInfo?.relationUserId),
        isDefault: data?.isDefault,
      }).unwrap()

      if (response && response?.data?.id) {
        enqueueSnackbar('Metode pembayaran baru berhasil ditambahkan', {
          variant: 'success',
        })
        refetch()
        toggleModalDisbursement()
      }
    } catch (error) {
      console.warn(error)
      enqueueSnackbar(
        error?.data?.meta?.message ||
          error?.data?.message ||
          error?.message ||
          'Metode pembayaran baru gagal ditambahkan',
        {
          variant: 'error',
        },
      )
    }
  }

  const handleEditPaymentMethod = () => {
    setIsOpenModalDisbursement(true)
    setIsEdit(true)
  }

  const dataDisbursementAgent = data?.data?.disburseuseragent

  useEffect(() => {
    if (dataDisbursementAgent?.isDefault) {
      setIsSwitchAgent(dataDisbursementAgent?.isDefault)
    }
  }, [dataDisbursementAgent])

  const handleSetDefaultPayment = async (value) => {
    try {
      const response = await setDefaultAgent({
        id: value ? Number(dataDisbursementAgent?.id) : -1,
        sellerUserId: Number(userId),
      }).unwrap()

      if (response && response?.data?.id) {
        enqueueSnackbar('Metode pembayaran utama berhasil diubah!', {
          variant: 'success',
        })
        refetch()
      }
    } catch (error) {
      console.warn(error)
      enqueueSnackbar(
        error?.data?.meta?.message ||
          error?.data?.message ||
          error?.message ||
          'Metode pembayaran utama gagal diubah!',
        {
          variant: 'error',
        },
      )
    }
  }

  const handleDeleteAgent = async (agentId) => {
    try {
      const response = await deleteAgent({
        id: Number(agentId),
      }).unwrap()

      if (response?.meta?.code === 200 && !response?.meta?.error) {
        enqueueSnackbar('Metode pembayaran berhasil dihapus!', {
          variant: 'success',
        })
        setIsShowModalDeletePaymentMethod(false)
        refetch()
      } else {
        enqueueSnackbar('Metode pembayaran gagal dihapus!', {
          variant: 'error',
        })
      }
    } catch (error) {
      console.warn(error)
      enqueueSnackbar(
        error?.data?.meta?.message ||
          error?.data?.message ||
          error?.message ||
          'Metode pembayaran gagal dihapus!',
        {
          variant: 'error',
        },
      )
    }
  }

  return (
    <Container className="pb-3">
      <div className="d-flex align-items-start gap-2 pt-3">
        <Menu
          onClick={() => dispatch(toggleSidebarFarmer())}
          className="pointer"
        />
        <BreadCrumbs
          breadCrumbActive={'Detail Anggota'}
          breadCrumbParent={'Daftar Anggota'}
          breadCrumbParent2={'Grup'}
          breadCrumbParent3={data?.data?.groupName ?? '-'}
          breadCrumbParent2Link={'/farmers/groups'}
          breadCrumbParent3Link={`/farmers/groups/${data?.data?.groupCode}`}
        />
      </div>
      {/* MODAL DELETE METODE PEMBAYARAN */}
      <PMBModalRemove
        isOpen={isShowModalDeletePaymentMethod}
        onClose={() => toggleModalDeletePaymentMethod()}
        title="Hapus metode pembayaran?"
        dataId={dataDisbursementAgent?.id}
        isLoading={isLoadingDeleteAgent}
        onRemoveData={(id) => {
          handleDeleteAgent(id)
        }}
      />
      {/* END MODAL DELETE METODE PEMBAYARAN */}
      <ModalLandPlot
        row={selectedPlot}
        isOpen={isModalOpen}
        toggle={toggleModal}
      />

      <ModalChangeRole
        isSubGroup
        row={data?.data}
        handleFinishedChangeRole={handleFinishedChangeRole}
      />

      <ModalChangeProfil row={data?.data} />

      {isOpenBanner ? (
        <div>
          <Alert
            isOpen={isOpenBanner}
            toggle={() => dispatch(toggleBannerDetail())}
            color="success"
            className="d-flex align-items-center my-4 w-100"
          >
            <span>
              <CheckCircle size={17} /> {bannerMsg}
            </span>
          </Alert>
        </div>
      ) : (
        <></>
      )}

      <div className="d-flex flex-column gap-3">
        {/* Data Tentang anggota */}
        <AboutFarmersCard row={{ ...data?.data }} />

        <Row>
          <Col>
            <div className="d-flex flex-column gap-3">
              {/* Data Akun */}
              <Card className="border-0 shadow-sm">
                <CardBody className="d-flex flex-column gap-2">
                  <h5>Data Akun</h5>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <span className="text-primary fw-semibold">
                        {data?.data?.fullName}
                      </span>
                      <p className="text-muted">{data?.data?.phoneNumber}</p>
                    </div>
                    <Badge
                      className={
                        data?.data?.status === 'VERIFIED'
                          ? 'badge-verify'
                          : 'badge-danger'
                      }
                    >
                      {data?.data?.status === 'VERIFIED'
                        ? 'Terverifikasi'
                        : 'Belum Terverifikasi'}
                    </Badge>
                  </div>
                  <div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="fw-semibold">Peran</span>
                      <Can I={MANAGE} this={BUSINESS_FARMERS}>
                        <h6
                          onClick={() => dispatch(toggleModalChangeRole())}
                          style={{ cursor: 'pointer' }}
                          className="text-primary"
                        >
                          Ubah Peran
                        </h6>
                      </Can>
                    </div>
                    <p className="text-muted">{data?.data?.roleName}</p>
                  </div>
                </CardBody>
              </Card>

              {/* Data Foto */}
              <Card className="border-0 shadow-sm">
                <CardBody className="d-flex flex-column gap-2">
                  <h5>Identitas</h5>
                  <div className="d-flex gap-2">
                    <div>
                      <p>Foto KTP</p>
                      {(isLoading && !imgKtpSrc) || !data ? (
                        <Spinner />
                      ) : (
                        <img
                          style={{ width: '180px', height: 'auto' }}
                          id="img-ktp"
                          className="img-fluid rounded"
                          src={imgKtpSrc}
                          alt="Foto Ktp"
                        />
                      )}
                    </div>
                    <div>
                      <p>Foto Selfie</p>
                      {(isLoading && !imgSelfieSrc) || !data ? (
                        <Spinner />
                      ) : (
                        <img
                          style={{ width: '180px', height: 'auto' }}
                          id="img-selfie"
                          className="img-fluid rounded"
                          src={imgSelfieSrc}
                          alt="Foto Selfie"
                        />
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>

              {/* MODAL DISBURSEMENT */}
              <ModalDisbursement
                isOpen={isOpenModalDisbursement}
                toggle={toggleModalDisbursement}
                handleAddDisbursement={handleAddDisbursement}
                noHpFarmer={data?.data?.phoneNumber}
                isDetailFarmer
                isLoading={isLoadingInviteAgent}
                isAgentExist={
                  data?.data?.disbursementViaAgent?.relationUserId
                    ? true
                    : false
                }
                isEdit={isEdit}
                {...(isEdit && {
                  agentSelected: dataDisbursementAgent,
                  isSwitchAgent: data?.data?.disbursementViaAgent,
                  methodName: 'agent',
                })}
              />

              {/* SECTION PAYMENT METHOD */}
              <Card className="border-0 shadow-sm">
                <CardBody className="d-flex flex-column gap-2">
                  <div>
                    <h5>Metode pembayaran diterima (opsional)</h5>
                    <p className="text-muted">
                      Digunakan untuk menerima pembayaran saat melakukan
                      penjualan
                    </p>
                  </div>

                  {/* SWITCH PAYMENT METHOD */}
                  {dataDisbursementAgent?.agentUserId && (
                    <FormGroup switch>
                      <Input
                        type="switch"
                        checked={isSwitchAgent}
                        onClick={(e) => {
                          setIsSwitchAgent(e?.target?.checked)
                          handleSetDefaultPayment(e?.target?.checked)
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                      <Label check>Jadikan agen menjadi pembayaran utama</Label>
                    </FormGroup>
                  )}
                  {/* END SWITCH PAYMENT METHOD */}

                  {/* CARD BANK */}
                  {data?.data?.bankAccountNumber && (
                    <Card className="border">
                      <CardBody className="d-flex flex-column gap-2">
                        <div className="d-flex justify-content-between">
                          <span className="fw-semibold mb-2">
                            Transfer bank{' '}
                            {!data?.data?.disbursementViaAgent && '(Utama)'}
                          </span>
                        </div>
                        {data?.data?.bankLogo && (
                          <img
                            width={50}
                            height={50}
                            src={data?.data?.bankLogo}
                            alt="Bank Logo"
                          />
                        )}
                        <span className="text-sm">
                          {data?.data?.bankAccountNumber}
                        </span>
                        <span className="text-sm">
                          {data?.data?.bankAccountName}
                        </span>
                      </CardBody>
                    </Card>
                  )}
                  {/* END CARD BANK */}

                  {/* CARD AGENT */}
                  {dataDisbursementAgent?.agentUserId && (
                    <Card className="border mt-2">
                      <CardBody className="d-flex flex-column gap-2">
                        <div className="d-flex justify-content-between">
                          <span className="fw-semibold">
                            Pembayaran lewat agen{' '}
                            {data?.data?.disbursementViaAgent && '(Utama)'}
                          </span>
                          <div className="d-flex gap-2">
                            {/* EDIT */}
                            <span
                              className="text-primary"
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleEditPaymentMethod()}
                            >
                              Ubah
                            </span>

                            {/* REMOVE */}
                            <span
                              style={{ color: '#D92C20', cursor: 'pointer' }}
                              onClick={() =>
                                setIsShowModalDeletePaymentMethod(true)
                              }
                            >
                              Hapus
                            </span>
                          </div>
                        </div>

                        {dataDisbursementAgent?.status === 'PENDING' && (
                          <div className="w-20">
                            <Badge size="sm" disabled className="badge-warning">
                              <span className="">Menunggu konfirmasi agen</span>
                            </Badge>
                          </div>
                        )}
                        <span className="fw-semibold text-sm">
                          Nomor handphone agen
                        </span>
                        <span className="text-sm" style={{ color: '#667085' }}>
                          {dataDisbursementAgent?.agentverifiedprofile
                            ?.phoneNumber ?? '-'}
                        </span>
                        <span className="fw-semibold text-sm">Nama agen</span>
                        <span className="text-sm" style={{ color: '#667085' }}>
                          {dataDisbursementAgent?.agentverifiedprofile
                            ?.partnership?.name ?? '-'}
                        </span>
                      </CardBody>
                    </Card>
                  )}
                  {/* END CARD AGENT */}

                  {/* SHOW BUTTON IF THE USER DIDN'T HAVE PAYMENT METHOD BY AGENT */}
                  {!dataDisbursementAgent?.agentUserId && (
                    <>
                      {!data?.data?.bankAccountNumber && (
                        <p className="m-0">
                          Belum ada metode pembayaran yang ditambahkan.
                        </p>
                      )}
                      <div className="w-20 mt-2">
                        <CustomButton
                          onClick={() => setIsOpenModalDisbursement(true)}
                          outline
                          color="primary"
                          size="sm"
                        >
                          <Plus size={20} /> Tambah Metode Pembayaran
                        </CustomButton>
                      </div>
                    </>
                  )}
                  {/* END SHOW BUTTON IF THE USER DIDN'T HAVE PAYMENT METHOD */}
                </CardBody>
              </Card>
              {/* END SECTION PAYMENT METHOD */}
            </div>
          </Col>

          <Col>
            {/* Data Diri */}
            <Card className="border-0 shadow-sm">
              <CardBody className="d-flex flex-column gap-2">
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Data Diri</h5>
                  <Can I={MANAGE} this={BUSINESS_FARMERS}>
                    <h6
                      onClick={() => dispatch(toggleModalChangeProfile())}
                      style={{ cursor: 'pointer' }}
                      className="text-primary"
                    >
                      Ubah
                    </h6>
                  </Can>
                </div>
                <div>
                  <span className="fw-semibold">Nama</span>
                  <p className="text-muted">{data?.data?.fullName}</p>
                </div>
                <div>
                  <span className="fw-semibold">Tanggal lahir</span>
                  <p className="text-muted">{data?.data?.dob}</p>
                </div>
                <div>
                  <span className="fw-semibold">Jenis Kelamin</span>
                  <p className="text-muted">
                    {data?.data?.sex == 0 ? 'Perempuan' : 'Laki-laki'}
                  </p>
                </div>
                <div>
                  <span className="fw-semibold">Email </span>
                  <p className="text-muted">{data?.data?.email}</p>
                </div>
                <div>
                  <span className="fw-semibold">NIK</span>
                  <p className="text-muted">{data?.data?.noKTP}</p>
                </div>
                <div>
                  <span className="fw-semibold">Provinsi</span>
                  <p className="text-muted">{data?.data?.province}</p>
                </div>
                <div>
                  <span className="fw-semibold">Kota</span>
                  <p className="text-muted">{data?.data?.city}</p>
                </div>
                <div>
                  <span className="fw-semibold">Kecamatan</span>
                  <p className="text-muted">{data?.data?.district}</p>
                </div>
                <div>
                  <span className="fw-semibold">Kelurahan</span>
                  <p className="text-muted">{data?.data?.subDistrict}</p>
                </div>
                <div>
                  <span className="fw-semibold">Kode pos</span>
                  <p className="text-muted">{data?.data?.postalCode}</p>
                </div>
                <div>
                  <span className="fw-semibold">Alamat</span>
                  <p className="text-muted">{data?.data?.address}</p>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Data Wilayah */}
        <Card className="border-0 shadow-sm">
          <CardBody className="d-flex flex-column gap-2">
            <h5>Wilayah Komoditas</h5>

            {resp?.data?.data?.items.length < 1 ? (
              <span className="text-muted">Belum ada wilayah yang direkam</span>
            ) : (
              regions?.map((e, i) => {
                return (
                  <div
                    key={i}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <span className="text-primary fw-semibold">
                        {e?.landName ? e?.landName : '-'}
                      </span>
                      <p className="text-muted">
                        Perkiraan luas:{' '}
                        {`${intl.formatNumber(e?.size / 10000, {
                          useGrouping: 'always',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        })} Ha`}
                      </p>
                    </div>
                    <div
                      onClick={() => {
                        setSelectedPlot(e)
                        toggleModal()
                      }}
                      className="fw-semibold"
                      style={{ cursor: 'pointer' }}
                    >
                      Lihat Wilayah <ArrowRight />
                    </div>
                  </div>
                )
              })
            )}
            {resp?.data?.data?.items.length > 3 && !isClick ? (
              <Button
                onClick={() => setIsClick(true)}
                block
                outline
                color="primary"
              >
                <ArrowDown /> Muat Lebih Banyak
              </Button>
            ) : null}
          </CardBody>
        </Card>

        {/* PEMETAAN KAPAL */}
        <Can I={READ} this={BUSINESS_VESSELS}>
          {dataUser?.length > 0 && (
            <Card className="border-0 shadow-sm">
              <CardBody className="d-flex flex-column gap-2">
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Pemetaan Kapal</h5>
                  <Can I={MANAGE} this={BUSINESS_VESSELS}>
                    <h6
                      onClick={() => navigate('/vessels')}
                      style={{ cursor: 'pointer' }}
                      className="text-primary"
                    >
                      Tambahkan pemetaan kapal
                    </h6>
                  </Can>
                </div>

                {dataUser?.map((item, index) => (
                  <Row
                    key={index}
                    className="align-items-center mb-3 gap-10 m-0"
                  >
                    <Col xs="1" sm="1" md="1" className="text-start p-0">
                      <img
                        src={item?.data?.linkImage ?? VesselNotFound}
                        alt={item?.data?.vesselName}
                        style={{
                          width: '100px',
                          maxWidth: '100%',
                          height: '70px',
                          borderRadius: '4px',
                          objectFit: 'cover',
                        }}
                        loading="lazy"
                      />
                    </Col>

                    <Col xs="11" sm="11" md="11">
                      <div>
                        <span className="fw-semibold d-block">
                          {item?.data?.vesselName}
                        </span>
                        <p className="text-muted m-0">
                          {item?.data?.licenseNumber}
                        </p>
                      </div>
                    </Col>
                  </Row>
                ))}
              </CardBody>
            </Card>
          )}
        </Can>

        {/* RIWAYAT OPERASI PENANGKAPAN */}
        <Can I={READ} this={BUSINESS_TRIPS}>
          {dataUserCatches?.items?.length > 0 && (
            <Card className="border-0 shadow-sm">
              <CardBody className="d-flex flex-column gap-2">
                <div>
                  <h5>Riwayat Operasi Penangkapan</h5>
                </div>
              </CardBody>

              {dataUserCatches?.items?.length > 0 ? (
                <DataTable
                  customStyles={customStyles}
                  columns={columns}
                  data={dataUserCatches?.items ?? []}
                  progressPending={isLoading}
                  responsive
                  persistTableHead
                  paginationTotalRows={
                    responseUserCatches?.data?.totalItems ?? 0
                  }
                  progressComponent={
                    <div style={{ margin: '100px 0px' }}>
                      <Spinner />
                    </div>
                  }
                  sortIcon={<ChevronDown />}
                  sortServer
                  className="border p-0 border-1 rounded-top"
                  noDataComponent={
                    <p className="my-3 fw-semibold">
                      Belum ada pengguna yang didaftarkan
                    </p>
                  }
                  pagination
                  paginationServer
                  paginationComponent={(props) => {
                    return (
                      <PMBPagination
                        rowCount={responseUserCatches?.data?.totalItems ?? 0}
                        currentPage={currentPage}
                        rowsPerPage={rowsPerPage}
                        setCurrentPage={setCurrentPage}
                        setRowsPerPage={setRowsPerPage}
                        props={props}
                      />
                    )
                  }}
                />
              ) : (
                <Card className="bg-light rounded border-0">
                  <CardBody>
                    <div className="d-flex flex-column align-items-center gap-4">
                      <span>Belum ada riwayat operasi penangkapan.</span>
                      <Button
                        disabled
                        onClick={() => navigate('/vessels/')}
                        color="primary"
                      >
                        + Tambah Penangkapan
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              )}
            </Card>
          )}
        </Can>

        {/* MODAL DETAIL RIWAYAT OPERASI PENANGKAPAN */}
        <Can I={READ} this={BUSINESS_TRIPS}>
          {dataUserCatches?.items?.length > 0 && (
            <SidebarDetails
              open={isOpenDetail}
              title="Detail Operasi Informasi"
              size="lg"
              headerClassName="mb-1"
              contentClassName="pt-0"
              toggleSidebar={() => setIsOpenDetail(false)}
            >
              <DetailModal row={selectedRow} />
            </SidebarDetails>
          )}
        </Can>
      </div>
    </Container>
  )
}

export default DetailFarmers
