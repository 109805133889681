import CustomButton from 'components/Buttons/CustomButton'
import React, { useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalBody } from 'reactstrap'
import { toggleModal } from './requestTransactionSlice'
import { releaseTrx } from './requestTransactionAction'
import { useNavigate, useSearchParams } from 'react-router-dom'

const ModalConfirm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  let code = searchParams.get('code')
  const { isOpenModal } = useSelector((s) => s.reqTrxSell)
  const [loading, setLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('')

  const handleRelease = async () => {
    try {
      setErrMsg('')
      setLoading(true)
      const res = await dispatch(releaseTrx({ code: code }))
      if (res?.error) {
        throw res
      } else {
        navigate('/success-transaction', { replace: true })
      }
    } catch (error) {
      setErrMsg(error?.payload ?? error?.message)
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      isOpen={isOpenModal}
      toggle={() => dispatch(toggleModal())}
      centered
      style={{ maxWidth: '400px' }}
    >
      <ModalBody>
        <div>
          <div>
            <h5 className="fw-bold text-md">Selesaikan transaksi ini?</h5>
            <p className="text-sm">
              Pembayaran Anda akan diteruskan ke penjual. Lanjutkan?
            </p>
          </div>
          {errMsg && (
            <div>
              <small className="text-danger">
                <AlertCircle size={15} /> {errMsg}
              </small>
            </div>
          )}
        </div>
        <div className="d-flex gap-2 w-100">
          <CustomButton
            color="secondary"
            onClick={() => {
              setErrMsg('')
              dispatch(toggleModal())
            }}
            block
            size="sm"
            className="p-2"
            disabled={loading}
          >
            <span className="text-normal">Batal</span>
          </CustomButton>
          <CustomButton
            color="primary"
            onClick={() => {
              handleRelease()
            }}
            block
            size="sm"
            className="p-2"
            disabled={loading}
          >
            <span className="text-normal">Ya, Lanjut</span>
          </CustomButton>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalConfirm
