import moment from 'moment-timezone'

export const formatDatePMB = (val, val2) => {
  return (
    <div className="d-flex flex-column">
      <span>{moment(val).format('Do MMM YYYY')}</span>
      <span className="text-muted">{moment(val2).format('HH:mm')}</span>
    </div>
  )
}

export const formatDatePMBV2 = (val) => {
  return moment(val).locale('id').format('Do MMM YYYY, HH:mm')
}

export const formatDateOnlyPMB = (val) => {
  return moment(val).locale('id').format('Do MMM YYYY')
}
