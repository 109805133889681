import IPhoto from 'assets/icons/Avatar.svg'
import BreadCrumbs from 'components/breadcrumbs'
import CustomButton from 'components/Buttons/CustomButton'
import PMBModalRemove from 'components/PMBModalRemove'
import PMBPagination from 'components/PMBPagination'
import PMBTooltip from 'components/PMBTooltip'
import { TableComponent as DataTable } from 'components/TableComponent'
import { Can } from 'context/Acl'
import { enqueueSnackbar } from 'notistack'
import { useRef, useState } from 'react'
import {
  ChevronDown,
  Edit2,
  Eye,
  MoreVertical,
  Plus,
  Search,
  Trash2,
} from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupText,
  Spinner,
  UncontrolledDropdown,
} from 'reactstrap'
import { URL_FORM_WAREHOUSE } from 'utils/pathUrl'
import { BUSINESS_WAREHOUSE, MANAGE } from 'utils/subjectActions'
import {
  useDeleteWarehouseMutation,
  useFetchWarehouseQuery,
} from './warehouseApiSlice'
import { setFetchOption, toggleModal } from './warehouseSlice'

const Warehouse = () => {
  const fetchTimeoutRef = useRef(0)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const intl = useIntl()
  const { tag, isOpenModal } = useSelector((s) => s.warehouse)
  let [page, setPage] = useState({ page: tag.page })
  const [loading, setLoading] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [errMsg, setErrMsg] = useState('')
  const [sortColumn, setSortColumn] = useState('name')
  const [sortDirection, setSortDirection] = useState('asc')
  const [search, setSearch] = useState('')
  const [searchTemp, setSearchTemp] = useState('')
  const [deleteWarehouse, resDeleteWarehouse] = useDeleteWarehouseMutation()
  const { data, isLoading, refetch } = useFetchWarehouseQuery(
    {
      page: tag?.page,
      sortby: sortColumn,
      sortdir: sortDirection,
      size: tag?.size,
      s: searchTemp,
    },
    { skip: !page },
  )
  const columns = [
    {
      name: <span>Nama Gudang</span>,
      sortable: true,
      sortField: 'name',
      width: '40%',
      grow: 2,
      cell: (row) => {
        return <span>{row.name}</span>
      },
    },
    {
      name: <span>Total Inventori</span>,
      grow: 2,
      sortable: true,
      sortField: 'totalInventory',
      cell: (row) => {
        return (
          <span>
            {row?.totalInventory ? (
              intl.formatNumber(row?.totalInventory, {
                useGrouping: 'always',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })
            ) : (
              <></>
            )}
          </span>
        )
      },
    },
    {
      name: <span>PIC</span>,
      grow: 2,
      cell: (row) => (
        <div>
          {row?.warehousemember?.map((e, i) => (
            <>
              <img
                id={`pic-${e?.id}`}
                key={i}
                src={e?.profilePic ?? IPhoto}
                className="rounded-circle"
                alt="PIC"
                width={32}
                height={32}
                style={{ marginRight: '5px' }}
              />
              <PMBTooltip
                placement="top"
                target={`pic-${e?.id}`}
                text={e?.name}
              />
            </>
          ))}
        </div>
      ),
    },
    {
      name: '',
      cell: (row) => {
        return (
          <div className="d-flex gap-3">
            <Eye
              size={20}
              color="#475467"
              className="button"
              onClick={() => {
                navigate(`/gudang/${row?.id}`)
                // dispatch(toggleSidebar())
                setSelectedRow(row)
              }}
            />
            <Can I={MANAGE} this={BUSINESS_WAREHOUSE}>
              <UncontrolledDropdown>
                <DropdownToggle tag="div">
                  <MoreVertical size={20} color="#475467" className="button" />
                </DropdownToggle>
                <DropdownMenu container="body">
                  <DropdownItem
                    className="mb-2 mt-2 text-danger"
                    onClick={() => {
                      dispatch(toggleModal())
                      setSelectedRow(row)
                    }}
                  >
                    <Trash2 size={15} /> Hapus
                  </DropdownItem>
                  <DropdownItem
                    className="mb-2 mt-2"
                    onClick={() => navigate(URL_FORM_WAREHOUSE, { state: row })}
                  >
                    <Edit2 size={15} /> Edit
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Can>
          </div>
        )
      },
    },
  ]

  const handleSort = (column, direction) => {
    setSortColumn(column.sortField)
    setSortDirection(direction === 'asc' ? 'desc' : 'asc')
  }

  const handleSearch = (e) => {
    setSearch(e)
    if (fetchTimeoutRef.current) {
      clearTimeout(fetchTimeoutRef.current)
    }

    fetchTimeoutRef.current = setTimeout(() => {
      if (e.trim() !== '') {
        setSearchTemp(e)
      } else {
        setSearch('')
        setSearchTemp('')
      }
    }, 1000)
  }

  const handleDelete = async (id) => {
    try {
      setLoading(true)
      const result = await deleteWarehouse(id)
      console.log(result)
      if (result?.error) {
        throw result
      } else {
        enqueueSnackbar(`Gudang berhasil dihapus`, {
          variant: 'success',
        })
        dispatch(toggleModal())
        refetch({
          page: tag?.page,
          sortby: sortColumn,
          sortdir: sortDirection,
        })
      }
    } catch (error) {
      console.log(error)
      setErrMsg(error?.error?.data?.message ?? error?.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="w-100 px-3 pb-3">
      {/* <SidebarWarehouse data={selectedRow} /> */}
      <PMBModalRemove
        isOpen={isOpenModal}
        onClose={() => dispatch(toggleModal())}
        onRemoveData={handleDelete}
        dataId={selectedRow?.id}
        isLoading={loading}
        errMsg={errMsg}
      />
      <div className="d-flex align-items-start gap-2 pt-3">
        <BreadCrumbs breadCrumbActive={'Gudang'} />
      </div>
      <div className="d-flex flex-column gap-2 mb-2">
        <Card className="border-0 shadow-sm">
          <CardBody>
            <DataTable
              columns={columns}
              data={data?.items}
              progressPending={isLoading}
              responsive
              persistTableHead
              defaultSortFieldId={1}
              defaultSortAsc={true}
              progressComponent={<Spinner />}
              sortIcon={<ChevronDown />}
              sortServer
              noDataComponent={
                <p className="my-3 fw-semibold">
                  Belum ada gudang yang didaftarkan
                </p>
              }
              onSort={handleSort}
              pagination
              paginationServer
              paginationComponent={(props) => (
                <PMBPagination
                  rowCount={data?.totalItems || 0}
                  currentPage={tag?.page}
                  rowsPerPage={tag?.size}
                  setCurrentPage={(e) => {
                    dispatch(setFetchOption({ page: e }))
                  }}
                  setRowsPerPage={(e) => {
                    dispatch(setFetchOption({ size: e }))
                  }}
                  props={props}
                />
              )}
              subHeader={true}
              subHeaderComponent={
                <div className="w-100">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5>Gudang</h5>
                    <Can I={MANAGE} this={BUSINESS_WAREHOUSE}>
                      <CustomButton
                        onClick={() => navigate(URL_FORM_WAREHOUSE)}
                        color="primary"
                      >
                        <Plus size={15} /> Tambah Gudang
                      </CustomButton>
                    </Can>
                  </div>

                  <p className="mb-2">Cari</p>
                  <InputGroup
                    style={{ borderColor: '#D0D5DD' }}
                    className="mb-4"
                  >
                    <InputGroupText
                      style={{
                        backgroundColor: 'white',
                        borderRightColor: 'white',
                      }}
                    >
                      <Search color="#667085" size={16} />
                    </InputGroupText>
                    <Input
                      style={{
                        maxWidth: '346px',
                      }}
                      placeholder="Cari gudang..."
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                  </InputGroup>
                </div>
              }
            />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default Warehouse
