import BreadCrumbs from 'components/breadcrumbs'
import LoadingV2 from 'components/LoadingV2'
import PMBPagination from 'components/PMBPagination'
import PMBSearchInputGroup from 'components/PMBSearchInputGroup'
import { useDebounce } from 'helpers'
import { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { ChevronDown } from 'react-feather'
import { Card, CardBody, Spinner } from 'reactstrap'
import { useFetchPortsQuery } from './portsListApiSlice'

const PortsTable = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchInput, setSearchInput] = useState('')
  const [search, setSearch] = useState('')
  const [isNoDataFilterAvailable, setIsNoDataFilterAvailable] = useState(false)

  // SORT BY FILTER
  const [sortColumn, setSortColumn] = useState('id')
  const [sortDirection, setSortDirection] = useState('DESC')

  const { data, isLoading, refetch, isFetching } = useFetchPortsQuery({
    page: currentPage ?? 0,
    size: rowsPerPage ?? 10,
    searchvalue: search,
    sortby: sortColumn ?? 'id',
    sortdir: sortDirection ?? 'DESC',
  })

  // HANDLE LOADING FILTER
  useEffect(() => {
    if (!isFetching && data?.totalItems === 0) {
      setIsNoDataFilterAvailable(true)
    } else {
      setIsNoDataFilterAvailable(false)
    }
  }, [isFetching, data?.totalItems])

  const handleSort = (column, direction) => {
    setSortColumn(column.selector)
    setSortDirection(direction?.toLowerCase() === 'asc' ? 'ASC' : 'DESC')
  }

  const columns = [
    {
      name: <span>ID</span>,
      selector: 'id',
      sortable: true,
      width: '80px',
      cell: (row) => {
        return <span>{row?.id ?? '-'}</span>
      },
    },
    {
      name: <span>Port Name</span>,
      selector: 'name',
      sortable: true,
      cell: (row) => {
        return <span>{row?.name ?? '-'}</span>
      },
    },
    {
      name: <span>District</span>,
      selector: 'district',
      sortable: true,
      cell: (row) => {
        return <span>{row?.district ?? '-'}</span>
      },
    },
    {
      name: <span>Latitude</span>,
      selector: 'latitude',
      sortable: true,
      cell: (row) => {
        return <span>{row?.latitude ?? '-'}</span>
      },
    },
    {
      name: <span>Longitude</span>,
      selector: 'longitude',
      sortable: true,
      cell: (row) => {
        return <span>{row?.longitude ?? '-'}</span>
      },
    },
  ]

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {
        marginTop: '1px',
        marginBottom: '1px',
      },
    },
    rows: {
      style: {
        marginTop: '1px',
        marginBottom: '1px',
        '&:hover': {
          backgroundColor: '#F9FAFB',
        },
      },
    },
  }

  const handleKeyDown = (e) => {
    if (e.key === ' ' && search.length === 0) {
      e.preventDefault()
    }
  }

  const debouncedSearch = useDebounce((value) => {
    // setTag({ page: 0, size: 10 })
    setCurrentPage(0)
    setRowsPerPage(10)
    setSearch(value)
    refetch()
  }, 600)

  const onHandleSearchChange = (value) => {
    setSearchInput(value ?? '')
    debouncedSearch(value ?? '')
  }

  if (isLoading) {
    return <LoadingV2 />
  }

  return (
    <div className="w-100 px-3">
      <div className="mt-3">
        <BreadCrumbs breadCrumbActive="Data Ports" />
      </div>

      <Card className="mb-3 border-0 shadow-sm">
        <CardBody>
          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={data?.items}
            progressPending={isLoading || isFetching}
            responsive
            persistTableHead
            defaultSortFieldId={1}
            defaultSortAsc={false}
            paginationTotalRows={data?.totalItems}
            progressComponent={
              <div style={{ margin: '100px 0px' }}>
                <Spinner />
              </div>
            }
            sortIcon={<ChevronDown />}
            sortServer
            onSort={handleSort}
            className="border p-0 border-1 rounded-top"
            noDataComponent={
              <p className="my-3 fw-semibold">
                {isNoDataFilterAvailable
                  ? 'Tidak ada data yang sesuai dengan filter Anda.'
                  : 'Belum ada daftar ports yang didaftarkan'}
              </p>
            }
            pagination
            paginationServer
            paginationComponent={(props) => (
              <PMBPagination
                rowCount={data?.totalItems || 0}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                setCurrentPage={setCurrentPage}
                setRowsPerPage={setRowsPerPage}
                props={props}
              />
            )}
            subHeader={true}
            subHeaderComponent={
              <div className="w-100">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <h5 className="fw-semibold">Daftar Ports</h5>
                </div>

                <div className="my-4">
                  <PMBSearchInputGroup
                    label={'Cari'}
                    value={searchInput}
                    placeholder="Cari nama port..."
                    onChange={(e) => onHandleSearchChange(e.target.value)}
                    maxWidth="25%"
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
            }
          />
        </CardBody>
      </Card>
    </div>
  )
}

export default PortsTable
