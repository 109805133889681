import PmgLogo from 'assets/images/pmg-logo-beta.png'
import PMBTooltip from 'components/PMBTooltip'
import SidebarDetails from 'components/SidebarDetails'
import { AbilityContext, Can } from 'context/Acl'
import { useFetchApprovalCountQuery } from 'features/private-views/approval/approvalApiSlice'
import { setCountTag } from 'features/private-views/approval/approvalSlice'
import { toggleNavbar } from 'features/private-views/dashboard/dashboardSlice'
import useGetMyProfileAvaQuery from 'hooks/useGetMyProfileAvaQuery'
import { useContext, useEffect, useState } from 'react'
import { ChevronDown, ChevronLeft, ChevronRight } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Button, Container, Nav, NavItem } from 'reactstrap'
import { URL_INVENTORY } from 'utils/pathUrl'
import { BUSINESS_MYAPPROVAL } from 'utils/subjectActions'
import { menus } from './menus'
import { menusV3 } from './menusV3'

const addIsOpenToMenus = (menus) => {
  return menus.map((menu) => ({
    ...menu,
    isOpen: false,
  }))
}

const NavbarSide = () => {
  const dispatch = useDispatch()
  const ability = useContext(AbilityContext)
  const { data, isLoading, isSuccess } = useFetchApprovalCountQuery()
  const { isOpenNavbar } = useSelector((s) => s.dashboard)
  const profile = useGetMyProfileAvaQuery({ skip: false })
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const { isV3 } = useSelector((s) => s.dashboard)

  const menu = isV3 ? addIsOpenToMenus(menusV3) : addIsOpenToMenus(menus)
  const [menuState, setMenuState] = useState(menu)

  useEffect(() => {
    if (isSuccess) dispatch(setCountTag(data?.data))
  }, [dispatch, data, isSuccess])

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [dispatch])

  const isMobile = screenWidth < 1100

  const onClickMenuItem = () => {
    if (isMobile && isOpenNavbar) {
      dispatch(toggleNavbar())
    }
  }

  const onClickToggle = (index) => {
    setMenuState((prevMenus) =>
      prevMenus.map((menu, idx) =>
        idx === index ? { ...menu, isOpen: !menu.isOpen } : menu,
      ),
    )
  }

  return (
    <>
      {isMobile ? (
        <SidebarDetails
          open={isOpenNavbar}
          headerClassName="mb-1"
          contentClassName="pt-0"
          isNavbar
          toggleSidebar={() => dispatch(toggleNavbar())}
          title={
            <div className="d-flex flex-column">
              <img
                alt="PasarMIKRO logo"
                src={PmgLogo}
                style={{ width: '100%', maxWidth: '177px', height: 'auto' }}
              />
            </div>
          }
          noClose
          isLeftAnimation
        >
          <Nav vertical className="list-unstyled pb-3">
            {menuState?.map((e, i) => {
              if (e?.action) {
                const hasAccess = e?.subject?.some((sub) =>
                  ability.can(e.action, sub),
                )
                if (hasAccess) {
                  return (
                    <div className="d-flex flex-column gap-3 my-4">
                      <small className="text-secondary fw-semibold">
                        {e.headTitle}
                      </small>
                      {e?.items?.map((item, j) => {
                        if (
                          item?.path === URL_INVENTORY &&
                          profile?.data?.makerData?.status !== 'VERIFIED'
                        ) {
                          return null
                        } else if (item?.action) {
                          const hasAccess2 = item?.subject?.some((sub) =>
                            ability.can(item.action, sub),
                          )
                          if (hasAccess2) {
                            return (
                              <>
                                <NavItem
                                  className="hover rounded"
                                  id={`tooltip-${i}-${j}`}
                                >
                                  <NavLink
                                    className={({ isActive }) =>
                                      isActive
                                        ? !isOpenNavbar
                                          ? 'nav-active justify-content-center'
                                          : 'nav-active'
                                        : !isActive && !isOpenNavbar
                                          ? 'nav-unactive justify-content-center'
                                          : 'nav-unactive'
                                    }
                                    to={item?.path}
                                    onClick={() => onClickMenuItem()}
                                    style={{
                                      whiteSpace: 'normal',
                                      wordBreak: 'break-word',
                                    }}
                                  >
                                    {item?.icon}
                                    {isOpenNavbar && (
                                      <small>{item?.title}</small>
                                    )}
                                    {item.subject === BUSINESS_MYAPPROVAL &&
                                      isOpenNavbar && (
                                        <span
                                          style={{
                                            height: 22,
                                            width: 'auto',
                                            textDecoration: 'none',
                                          }}
                                          className="rounded-circle badge-cancel flex-centered text-sm"
                                        >
                                          {isSuccess
                                            ? data?.data
                                                ?.map((e) => e.total)
                                                ?.reduce((sum, e) => sum + e, 0)
                                            : isLoading
                                              ? 0
                                              : 0}
                                        </span>
                                      )}
                                  </NavLink>
                                </NavItem>
                                {!isOpenNavbar && (
                                  <PMBTooltip
                                    placement="right"
                                    target={`tooltip-${i}-${j}`}
                                    text={item?.title}
                                  />
                                )}
                              </>
                            )
                          }
                        } else {
                          return (
                            <>
                              <NavItem
                                className="hover rounded"
                                key={`item-${i}-${j}`}
                                id={`tooltip-${i}-${j}`}
                              >
                                <NavLink
                                  className={({ isActive }) =>
                                    isActive
                                      ? !isOpenNavbar
                                        ? 'nav-active justify-content-center'
                                        : 'nav-active'
                                      : !isActive && !isOpenNavbar
                                        ? 'nav-unactive justify-content-center'
                                        : 'nav-unactive'
                                  }
                                  to={item?.path}
                                  onClick={() => onClickMenuItem()}
                                  style={{
                                    whiteSpace: 'normal',
                                    wordBreak: 'break-word',
                                  }}
                                >
                                  {item?.icon}
                                  {isOpenNavbar && <small>{item?.title}</small>}
                                </NavLink>
                              </NavItem>
                              {!isOpenNavbar && (
                                <PMBTooltip
                                  placement="right"
                                  target={`tooltip-${i}-${j}`}
                                  text={item?.title}
                                />
                              )}
                            </>
                          )
                        }
                      })}
                    </div>
                  )
                }
              } else {
                return (
                  <div
                    key={`menu-${i}`}
                    className="d-flex flex-column gap-3 my-4"
                  >
                    <small className="text-secondary fw-semibold">
                      {e.headTitle}
                    </small>
                    {e?.items?.map((item, j) => (
                      <>
                        <NavItem
                          className="hover rounded"
                          key={`item-${i}-${j}`}
                          id={`tooltip-${i}-${j}`}
                        >
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? !isOpenNavbar
                                  ? 'nav-active justify-content-center'
                                  : 'nav-active'
                                : !isActive && !isOpenNavbar
                                  ? 'nav-unactive justify-content-center'
                                  : 'nav-unactive'
                            }
                            to={item?.path}
                            onClick={() => onClickMenuItem()}
                            style={{
                              whiteSpace: 'normal',
                              wordBreak: 'break-word',
                            }}
                          >
                            {item?.icon}
                            {isOpenNavbar && <small>{item?.title}</small>}
                          </NavLink>
                        </NavItem>
                        {!isOpenNavbar && (
                          <PMBTooltip
                            placement="right"
                            target={`tooltip-${i}-${j}`}
                            text={item?.title}
                          />
                        )}
                      </>
                    ))}
                  </div>
                )
              }
            })}
            <div className="d-flex justify-content-center">
              <Button
                size="sm"
                style={{
                  width: '25px',
                  height: '25px',
                  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                }}
                outline
                className="rounded-circle d-flex flex-column align-items-center border-0"
                color="secondary"
                onClick={() => dispatch(toggleNavbar())}
              >
                {isOpenNavbar ? (
                  <ChevronLeft size={25} />
                ) : (
                  <ChevronRight size={25} />
                )}
              </Button>
            </div>
          </Nav>
        </SidebarDetails>
      ) : (
        <div
          style={{
            width: isOpenNavbar ? '280px' : '80px',
            paddingTop: '75px',
            position: 'fixed',
            top: '0',
            left: '0',
            height: '100vh',
            overflowY: 'auto',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
            zIndex: 1000,
          }}
          className="bg-white border border-top-0 d-flex flex-column"
        >
          <Nav vertical className="list-unstyled pb-3">
            <Container>
              {menuState?.map((e, i) => {
                if (e?.action) {
                  const hasAccess = e?.subject?.some((sub) =>
                    ability.can(e.action, sub),
                  )
                  if (hasAccess) {
                    return (
                      <div className="d-flex flex-column gap-3 my-2">
                        {isOpenNavbar ? (
                          <div
                            className={`d-flex justify-content-between pointer align-items-center ${e?.isOpen ? 'nav-active' : ''}`}
                            onClick={() => onClickToggle(i)}
                            style={{
                              padding: 10,
                            }}
                          >
                            <div className="d-flex align-items-center">
                              {e?.icon}
                              <small className="fw-semibold px-2 mt-1">
                                {e.headTitle}
                              </small>
                            </div>
                            {e?.isOpen ? (
                              <ChevronDown size={20} />
                            ) : (
                              <ChevronRight color="#667085" size={20} />
                            )}
                          </div>
                        ) : (
                          <NavItem id={`tooltip-${i}`}>
                            <div
                              className={`d-flex align-items-center pointer justify-content-center ${e?.isOpen ? 'nav-active' : ''}`}
                              onClick={() => onClickToggle(i)}
                              style={{
                                padding: 10,
                              }}
                            >
                              <div>{e?.icon}</div>
                              {!isOpenNavbar && (
                                <PMBTooltip
                                  placement="right"
                                  target={`tooltip-${i}`}
                                  text={e?.headTitle}
                                />
                              )}
                            </div>
                          </NavItem>
                        )}
                        {e?.isOpen
                          ? e?.items?.map((item, j) => {
                              if (
                                item?.path === URL_INVENTORY &&
                                profile?.data?.makerData?.status !== 'VERIFIED'
                              ) {
                                return null
                              } else if (item?.action) {
                                const hasAccess2 = item?.subject?.some((sub) =>
                                  ability.can(item.action, sub),
                                )

                                if (hasAccess2) {
                                  return (
                                    <>
                                      <NavItem
                                        className="hover rounded"
                                        id={`tooltip-${i}-${j}`}
                                        // style={{ marginTop: j === 0 ? 10 : 2 }}
                                      >
                                        <NavLink
                                          className={({ isActive }) =>
                                            isActive
                                              ? !isOpenNavbar
                                                ? 'nav-active justify-content-center'
                                                : 'nav-active'
                                              : !isActive && !isOpenNavbar
                                                ? 'nav-unactive justify-content-center'
                                                : 'nav-unactive'
                                          }
                                          to={item?.path}
                                          onClick={() => onClickMenuItem()}
                                          style={{
                                            whiteSpace: 'normal',
                                            wordBreak: 'break-word',
                                          }}
                                        >
                                          {/* {item?.icon} */}
                                          {isOpenNavbar ? (
                                            <small style={{ paddingLeft: 30 }}>
                                              {item?.title}
                                            </small>
                                          ) : (
                                            item?.icon
                                          )}
                                          {item.subject ===
                                            BUSINESS_MYAPPROVAL &&
                                            isOpenNavbar && (
                                              <span
                                                style={{
                                                  height: 22,
                                                  width: 'auto',
                                                  textDecoration: 'none',
                                                }}
                                                className="rounded-circle badge-cancel flex-centered text-sm"
                                              >
                                                {isSuccess
                                                  ? data?.data
                                                      ?.map((e) => e.total)
                                                      ?.reduce(
                                                        (sum, e) => sum + e,
                                                        0,
                                                      )
                                                  : isLoading
                                                    ? 0
                                                    : 0}
                                              </span>
                                            )}
                                        </NavLink>
                                      </NavItem>
                                      {!isOpenNavbar && (
                                        <PMBTooltip
                                          placement="right"
                                          target={`tooltip-${i}-${j}`}
                                          text={item?.title}
                                        />
                                      )}
                                    </>
                                  )
                                }
                              } else {
                                return (
                                  <>
                                    <NavItem
                                      className="hover rounded"
                                      key={`item-${i}-${j}`}
                                      id={`tooltip-${i}-${j}`}
                                    >
                                      <NavLink
                                        className={({ isActive }) =>
                                          isActive
                                            ? !isOpenNavbar
                                              ? 'nav-active justify-content-center'
                                              : 'nav-active'
                                            : !isActive && !isOpenNavbar
                                              ? 'nav-unactive justify-content-center'
                                              : 'nav-unactive'
                                        }
                                        to={item?.path}
                                        onClick={() => onClickMenuItem()}
                                        style={{
                                          whiteSpace: 'normal',
                                          wordBreak: 'break-word',
                                        }}
                                      >
                                        {item?.icon}
                                        {isOpenNavbar && (
                                          <small>{item?.title}</small>
                                        )}
                                      </NavLink>
                                    </NavItem>
                                    {!isOpenNavbar && (
                                      <PMBTooltip
                                        placement="right"
                                        target={`tooltip-${i}-${j}`}
                                        text={item?.title}
                                      />
                                    )}
                                  </>
                                )
                              }
                            })
                          : null}
                      </div>
                    )
                  }
                } else {
                  return (
                    <div
                      key={`menu-${i}`}
                      className="d-flex flex-column gap-3 my-4"
                    >
                      <small className="text-secondary fw-semibold">
                        {e.headTitle}
                      </small>
                      {e?.items?.map((item, j) => (
                        <>
                          <NavItem
                            className="hover rounded"
                            key={`item-${i}-${j}`}
                            id={`tooltip-${i}-${j}`}
                          >
                            <NavLink
                              className={({ isActive }) =>
                                isActive
                                  ? !isOpenNavbar
                                    ? 'nav-active justify-content-center'
                                    : 'nav-active'
                                  : !isActive && !isOpenNavbar
                                    ? 'nav-unactive justify-content-center'
                                    : 'nav-unactive'
                              }
                              to={item?.path}
                              onClick={() => onClickMenuItem()}
                              style={{
                                whiteSpace: 'normal',
                                wordBreak: 'break-word',
                              }}
                            >
                              {item?.icon}
                              {isOpenNavbar && <small>{item?.title}</small>}
                            </NavLink>
                          </NavItem>
                          {!isOpenNavbar && (
                            <PMBTooltip
                              placement="right"
                              target={`tooltip-${i}-${j}`}
                              text={item?.title}
                            />
                          )}
                        </>
                      ))}
                    </div>
                  )
                }
              })}
            </Container>
          </Nav>
          <div className="d-flex justify-content-center">
            <Button
              size="sm"
              style={{
                width: '25px',
                height: '25px',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              }}
              outline
              className="rounded-circle d-flex flex-column align-items-center border-0"
              color="secondary"
              onClick={() => dispatch(toggleNavbar())}
            >
              {isOpenNavbar ? (
                <ChevronLeft size={25} />
              ) : (
                <ChevronRight size={25} />
              )}
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default NavbarSide
