import BreadCrumbs from 'components/breadcrumbs'
import { monthsOptions } from 'constants'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Card, CardBody } from 'reactstrap'
import { getChart2 } from '../API/ap2hiActions'
import '../style.css'
import AnnualTab from './AnnualTab'
import MonthlyTab from './MonthlyTab'

const Insight = ({ warning }) => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('tahunan')
  const [selectedMonthYear, setSelectedMonthYear] = useState(new Date())
  const [selectedYearAnnual, setSelectedYearAnnual] = useState(null)
  const [yearOptionsAnnual, setYearOptionsAnnual] = useState([])
  const [dataHandline, setDataHandline] = useState([])
  const [dataPoleLine, setDataPoleLine] = useState([])

  useEffect(() => {
    const currentYear = moment().year()
    const years = Array.from({ length: 3 }, (_, i) => ({
      value: currentYear - i,
      label: `${currentYear - i}`,
    }))
    setYearOptionsAnnual(years)
    setSelectedYearAnnual(years[0])
  }, [])

  useEffect(() => {
    fetchInsightAnnual(yearOptionsAnnual?.[0]?.value)
  }, [yearOptionsAnnual])

  const ensureAllMonthsPresentAnnual = (data) => {
    return monthsOptions.map((month) => {
      const found = data.find((d) => d.x === month.label)
      return {
        x: month.label,
        y: found ? found.y : 0,
      }
    })
  }

  const fetchInsightAnnual = (valueYear) => {
    if (!valueYear) {
      console.warn('Fetching skipped: Year is not defined')
      return
    }

    setLoading(true)

    Promise.all([
      dispatch(getChart2({ year: valueYear, gear: 'Handline' })),
      dispatch(getChart2({ year: valueYear, gear: 'Pole' })),
    ])
      .then(([selectedRes, previousRes]) => {
        const handlineDataRes =
          selectedRes?.payload?.items?.map((item) => ({
            x:
              monthsOptions.find(
                (month) => Number(month.value) === Number(item?.month),
              )?.label || item?.month,
            y: item?.totalAmount,
          })) || []

        const polelineDataRes =
          previousRes?.payload?.items?.map((item) => ({
            x:
              monthsOptions.find(
                (month) => Number(month.value) === Number(item?.month),
              )?.label || item?.month,
            y: item?.totalAmount,
          })) || []

        setDataHandline(ensureAllMonthsPresentAnnual(handlineDataRes))
        setDataPoleLine(ensureAllMonthsPresentAnnual(polelineDataRes))
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        setLoading(false)
      })
  }

  const handleYearChangeAnnual = (selectedOption) => {
    setSelectedYearAnnual(selectedOption)
    fetchInsightAnnual(selectedOption?.value)
  }

  const optionsAnnual = {
    chart: {
      type: 'line',
      height: 500,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    markers: {
      size: 5,
      colors: ['#FF1B6B', '#45CAFF'],
      strokeWidth: 2,
      hover: {
        size: 7,
      },
    },
    colors: ['#FF1B6B', '#45CAFF'],
    dataLabels: {
      enabled: true,
      offsetY: -5,
      style: {
        fontSize: '12px',
        colors: ['#000'],
        background: 'transparent',
        fontWeight: 'normal',
      },
      background: {
        enabled: false,
      },
      formatter: function (val) {
        return val === 0 ? '' : val.toString().replace('.', ',')
      },
    },
    xaxis: {
      categories: monthsOptions.map((month) => month?.label?.slice(0, 3)),
      labels: {
        style: {
          fontSize: '12px',
          colors: '#6E6E6E',
        },
      },
    },
    title: {
      text: 'Volume Tangkapan (kg)',
      offsetY: 5,
      style: {
        fontSize: '12px',
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value?.toLocaleString()
        },
        style: {
          fontSize: '12px',
          colors: '#6E6E6E',
        },
      },
    },
  }

  const seriesAnnual = [
    {
      name: 'Handline',
      data: dataHandline?.map((d) => d?.y),
    },
    {
      name: 'Pole & Line',
      data: dataPoleLine?.map((d) => d?.y),
    },
  ]

  const handleChangeMonthYear = (selectedDates) => {
    if (selectedDates.length > 0) {
      const date = selectedDates[0]
      setSelectedMonthYear(date)
    }
  }

  const columnsAnnual = [
    {
      name: <span>Gear Type</span>,
      selector: (row) => row.gearType,
      sortable: true,
      center: true,
      width: '110px',
    },
    ...monthsOptions.map((month) => ({
      name: <span>{month?.label?.slice(0, 3)}</span>,
      selector: (row) => row[month.label],
      center: true,
      format: (row) => row[month.label]?.toLocaleString(),
    })),
  ]

  const dataResultAnnual = [
    {
      gearType: <span>Handline</span>,
      ...Object?.fromEntries(dataHandline?.map((d) => [d?.x, d?.y])),
    },
    {
      gearType: <span>Pole & Line</span>,
      ...Object?.fromEntries(dataPoleLine?.map((d) => [d?.x, d?.y])),
    },
  ]

  return (
    <div className="container-fluid px-3">
      <div className="mt-3">
        <BreadCrumbs breadCrumbActive="Analisa Data Tangkapan" />
      </div>

      <h5 className="mb-3 mt-3">Laporan & Analisis Tangkapan</h5>

      <Card className="mb-3 p-3" style={{ border: 'none' }}>
        <CardBody>
          <div className="row">
            {/* TAB MENU */}
            <div
              className="d-flex gap-3 mb-3"
              style={{ borderBottom: '1px solid #EAECF0' }}
            >
              <div
                style={{
                  borderBottom:
                    activeTab === 'tahunan' ? '2px solid #005370' : '',
                  paddingBottom: 14,
                  color: activeTab === 'tahunan' ? '#005370' : '#667085',
                  cursor: 'pointer',
                  fontSize: '14px',
                }}
                onClick={() => {
                  setSelectedMonthYear(new Date())
                  setActiveTab('tahunan')
                }}
              >
                Tahunan
              </div>
              <div
                style={{
                  borderBottom:
                    activeTab === 'bulanan' ? '2px solid #005370' : '',
                  paddingBottom: 14,
                  color: activeTab === 'bulanan' ? '#005370' : '#667085',
                  cursor: 'pointer',
                  fontSize: '14px',
                }}
                onClick={() => setActiveTab('bulanan')}
              >
                Bulanan
              </div>
            </div>
            {/* END TAB MENU */}

            {/* TAHUNAN */}
            {activeTab === 'tahunan' && (
              <AnnualTab
                yearOptionsAnnual={yearOptionsAnnual}
                selectedYearAnnual={selectedYearAnnual}
                dataHandline={dataHandline}
                dataPoleLine={dataPoleLine}
                loading={loading}
                handleYearChangeAnnual={handleYearChangeAnnual}
                columnsAnnual={columnsAnnual}
                dataResultAnnual={dataResultAnnual}
                options={optionsAnnual}
                series={seriesAnnual}
              />
            )}
            {/* END TAHUNAN */}

            {/* BULANAN */}
            {activeTab === 'bulanan' && (
              <MonthlyTab
                selectedMonthYear={selectedMonthYear}
                handleChangeMonthYear={handleChangeMonthYear}
                options={optionsAnnual}
                series={seriesAnnual}
              />
            )}
            {/* END BULANAN */}
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default Insight
