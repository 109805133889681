import { ChevronLeft, ChevronRight } from 'react-feather'

const PMBPagination = ({
  rowCount,
  currentPage,
  rowsPerPage,
  setCurrentPage,
  setRowsPerPage,
  props,
}) => {
  const totalPages = Math.ceil(rowCount / rowsPerPage)

  return (
    <div className="d-flex align-items-center justify-content-end px-3 py-2 border-top">
      <div className="text-sm me-2">
        {`${currentPage * rowsPerPage + 1} - ${Math.min(
          (currentPage + 1) * rowsPerPage,
          rowCount,
        )} of ${rowCount}`}
      </div>
      <div className="d-flex align-items-center m-2">
        <span className="text-sm me-2" style={{ whiteSpace: 'nowrap' }}>
          Rows per page:
        </span>
        <select
          className="form-select form-select-md"
          style={{ border: 'none !important' }}
          value={rowsPerPage}
          onChange={(e) => {
            const newRowsPerPage = Number(e.target.value)
            setRowsPerPage(newRowsPerPage)
            setCurrentPage(0)
          }}
        >
          {[10, 15, 20, 25, 30].map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
      </div>
      <div className="d-flex align-items-center gap-2">
        <button
          className={`btn btn-sm ${
            currentPage === 0 ? 'btn-outline-secondary' : 'btn-outline-primary'
          }`}
          disabled={currentPage === 0}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          <ChevronLeft />
        </button>
        <button
          className={`btn btn-sm ${
            currentPage === totalPages - 1
              ? 'btn-outline-secondary'
              : 'btn-outline-primary'
          }`}
          disabled={currentPage === totalPages - 1}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          <ChevronRight />
        </button>
      </div>
    </div>
  )
}

export default PMBPagination
