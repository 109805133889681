import BreadCrumbs from 'components/breadcrumbs'
import LoadingV2 from 'components/LoadingV2'
import PMBModalRemove from 'components/PMBModalRemove'
import PMBPagination from 'components/PMBPagination'
import PMBSearchInputGroup from 'components/PMBSearchInputGroup'
import { Can } from 'context/Acl'
import { useDebounce } from 'helpers'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import { ChevronDown, Edit, MoreVertical, Trash2 } from 'react-feather'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from 'reactstrap'
import { BUSINESS_VESSELS, MANAGE } from 'utils/subjectActions'
import {
  useDeleteCompanyByIdMutation,
  useFetchCompaniesQuery,
} from './companiesListApiSlice'
import ModalEdit from './ModalEdit'

const CompaniesTable = () => {
  const [deleteCompanyById, { isLoading: isLoadingDelete }] =
    useDeleteCompanyByIdMutation()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [dropdownOpenMap, setDropdownOpenMap] = useState({})
  const [isOpenModalEdit, setIsOpenModalEdit] = useState(false)
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)
  let [dataRow, setDataRow] = useState(null)
  const dropdownRef = useRef(null)
  const [searchInput, setSearchInput] = useState('')
  const [search, setSearch] = useState('')
  const [isNoDataFilterAvailable, setIsNoDataFilterAvailable] = useState(false)

  const onHandleOpenCloseModalEdit = () => {
    setIsOpenModalEdit((prev) => !prev)
  }

  const onHandleOpenCloseModalDelete = () => {
    setIsOpenModalDelete((prev) => !prev)
  }

  const menuItems = [
    {
      label: 'Ubah',
      icon: <Edit size={16} />,
      action: (row) => {
        setDataRow(row)
        onHandleOpenCloseModalEdit()
      },
    },
    {
      label: 'Hapus',
      icon: <Trash2 size={16} />,
      action: (row) => {
        setDataRow(row)
        onHandleOpenCloseModalDelete()
      },
    },
  ]

  const toggleDropdownMenu = (id) => {
    setDropdownOpenMap((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpenMap(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  // SORT BY FILTER
  const [sortColumn, setSortColumn] = useState('id')
  const [sortDirection, setSortDirection] = useState('DESC')

  const { data, isLoading, refetch, isFetching } = useFetchCompaniesQuery({
    page: currentPage ?? 0,
    size: rowsPerPage ?? 10,
    searchvalue: search,
    sortby: sortColumn ?? 'id',
    sortdir: sortDirection ?? 'DESC',
  })

  // HANDLE LOADING FILTER
  useEffect(() => {
    if (!isFetching && data?.totalItems === 0) {
      setIsNoDataFilterAvailable(true)
    } else {
      setIsNoDataFilterAvailable(false)
    }
  }, [isFetching, data?.totalItems])

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleSort = (column, direction) => {
    setSortColumn(column.selector)
    setSortDirection(direction?.toLowerCase() === 'asc' ? 'ASC' : 'DESC')
  }

  const columns = [
    {
      name: <span>ID</span>,
      selector: 'id',
      sortable: true,
      width: '80px',
      cell: (row) => {
        return <span>{row?.id ?? '-'}</span>
      },
    },
    {
      name: <span>Kode</span>,
      selector: 'code',
      sortable: true,
      cell: (row) => {
        return <span>{row?.code ?? '-'}</span>
      },
    },
    {
      name: <span>Nama Perusahaan</span>,
      selector: 'name',
      sortable: true,
      cell: (row) => {
        return <span>{row?.name ?? '-'}</span>
      },
    },
    {
      name: <span>ABBR</span>,
      selector: 'abbr',
      sortable: true,
      cell: (row) => {
        return <span>{row?.abbr ?? '-'}</span>
      },
    },
    {
      name: <span>IUU</span>,
      selector: 'iuuList',
      sortable: true,
      cell: (row) => {
        return <span>{row?.iuuList === true ? 'Yes' : 'No'}</span>
      },
    },
    {
      name: <span>Code of Conduct</span>,
      selector: 'codeOfConduct',
      sortable: true,
      cell: (row) => {
        return <span>{row?.codeOfConduct === true ? 'Yes' : 'No'}</span>
      },
    },
    {
      name: '',
      width: '80px',
      cell: (row) => {
        return (
          <Can I={MANAGE} this={BUSINESS_VESSELS}>
            <div
              style={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}
            >
              <Dropdown
                isOpen={dropdownOpenMap[row.id]}
                toggle={() => toggleDropdownMenu(row.id)}
                direction="down"
              >
                <DropdownToggle
                  tag="span"
                  data-toggle="dropdown"
                  aria-expanded={dropdownOpenMap[row.id]}
                  style={{ cursor: 'pointer' }}
                >
                  <MoreVertical size={20} />
                </DropdownToggle>
                <DropdownMenu className="m-0 p-0" container="body">
                  {menuItems.map((item, index) => (
                    <DropdownItem
                      className="my-1"
                      key={index}
                      onClick={() => item.action(row)}
                    >
                      <span>{item.icon}</span>
                      <span className="ms-2 text-sm">{item.label}</span>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </Can>
        )
      },
    },
  ]

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {
        marginTop: '1px',
        marginBottom: '1px',
      },
    },
    rows: {
      style: {
        marginTop: '1px',
        marginBottom: '1px',
        '&:hover': {
          backgroundColor: '#F9FAFB',
        },
      },
    },
  }

  const onRemoveDataCompanyById = async (id) => {
    try {
      if (!id) {
        enqueueSnackbar('Perusahaan gagal dihapus!', {
          variant: 'error',
        })

        return
      }

      const res = await deleteCompanyById({ id }).unwrap()

      if (res) {
        enqueueSnackbar('Perusahaan berhasil di hapus!', {
          variant: 'success',
        })

        refetch()
        onHandleOpenCloseModalDelete()
      }
    } catch (error) {
      console.warn('Error saat menghapus vessel', error)
      enqueueSnackbar('Perusahaan gagal dihapus!', {
        variant: 'error',
      })

      onHandleOpenCloseModalDelete()
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === ' ' && search.length === 0) {
      e.preventDefault()
    }
  }

  const debouncedSearch = useDebounce((value) => {
    setCurrentPage(0)
    setRowsPerPage(10)
    setSearch(value)
    refetch()
  }, 600)

  const onHandleSearchChange = (value) => {
    setSearchInput(value ?? '')
    debouncedSearch(value ?? '')
  }

  const handleRefetch = () => {
    refetch()
  }

  if (isLoading) {
    return <LoadingV2 />
  }

  return (
    <div className="w-100 px-3">
      <div className="mt-3">
        <BreadCrumbs breadCrumbActive="Data Perusahaan" />
      </div>

      {/* MODAL EDIT */}
      <ModalEdit
        row={dataRow}
        isOpenModalEdit={isOpenModalEdit}
        onHandleOpenCloseModalEdit={onHandleOpenCloseModalEdit}
        handleRefetch={handleRefetch}
      />

      {/* MODAL REMOVE */}
      <PMBModalRemove
        isOpen={isOpenModalDelete}
        onClose={() => onHandleOpenCloseModalDelete()}
        title={`Apakah Anda yakin ingin menghapus data ${dataRow?.name}?`}
        dataId={dataRow?.id}
        isLoading={isLoadingDelete}
        onRemoveData={(id) => {
          onRemoveDataCompanyById(id)
        }}
      />

      <Card className="mb-3 border-0 shadow-sm">
        <CardBody>
          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={data?.items}
            progressPending={isLoading || isFetching}
            responsive
            persistTableHead
            defaultSortFieldId={1}
            defaultSortAsc={false}
            paginationTotalRows={data?.totalItems}
            progressComponent={
              <div style={{ margin: '100px 0px' }}>
                <Spinner />
              </div>
            }
            sortIcon={<ChevronDown />}
            sortServer
            onSort={handleSort}
            className="border p-0 border-1 rounded-top"
            noDataComponent={
              <p className="my-3 fw-semibold">
                {isNoDataFilterAvailable
                  ? 'Tidak ada data yang sesuai dengan filter Anda.'
                  : 'Belum ada daftar perusahaan yang didaftarkan'}
              </p>
            }
            pagination
            paginationServer
            paginationComponent={(props) => (
              <PMBPagination
                rowCount={data?.totalItems || 0}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                setCurrentPage={setCurrentPage}
                setRowsPerPage={setRowsPerPage}
                props={props}
              />
            )}
            subHeader={true}
            subHeaderComponent={
              <div className="w-100">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <h5 className="fw-semibold">Daftar Perusahaan</h5>

                  <Can I={MANAGE} this={BUSINESS_VESSELS}>
                    <Button
                      onClick={() => navigate('/companies/add')}
                      size="sm"
                      color="primary"
                    >
                      + Tambah
                    </Button>
                  </Can>
                </div>

                <div className="my-4">
                  <PMBSearchInputGroup
                    label={'Cari'}
                    value={searchInput}
                    placeholder="Cari nama perusahaan..."
                    onChange={(e) => onHandleSearchChange(e.target.value)}
                    maxWidth="25%"
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
            }
          />
        </CardBody>
      </Card>
    </div>
  )
}

export default CompaniesTable
