import { useIntl } from 'react-intl'
import { Card, CardBody, CardText } from 'reactstrap'

const ItemsDetail = ({ itemDetail, totalAmount }) => {
  const intl = useIntl()

  return (
    <Card className="w-100 mb-3">
      <CardBody>
        <CardText tag="h6" className="mb-1">
          Rincian Pesanan
        </CardText>
        {itemDetail?.map((item, idx) => (
          <div
            key={idx}
            className="d-flex align-items-center justify-content-between gap-2 my-3"
          >
            <div className="">
              <div>{item?.nameItem}</div>
              <div>
                <span style={{ color: 'rgba(102, 112, 133, 1)' }}>
                  {intl.formatNumber(item?.amount ?? 0, {
                    useGrouping: 'always',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}{' '}
                  kg •{' '}
                </span>
                <span style={{ color: 'rgba(0, 99, 134, 1)' }}>
                  Rp
                  {intl.formatNumber(item?.price ?? 0, {
                    useGrouping: 'always',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                </span>
              </div>
            </div>
            <div className="fw-semibold">
              Rp
              {intl.formatNumber(item?.totalPrice ?? 0, {
                useGrouping: 'always',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
        ))}
        <hr />
        <div className="d-flex align-items-center justify-content-between">
          <span className="fw-bold">Total</span>
          <div className="fw-bold">
            Rp
            {intl.formatNumber(totalAmount ?? 0, {
              useGrouping: 'always',
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default ItemsDetail
