import * as FileSaver from 'file-saver'
import moment from 'moment-timezone'
import * as XLSX from 'xlsx'

// HANDLE FUNCTION TO ESCAPE CSV VALUE
const escapeCsvValue = (value) => {
  if (value === null || value === undefined) {
    return '""'
  }
  return `"${String(value)?.replace(/"/g, '""')}"`
}

// FUNCTION TO CSV
export const handleDownloadCSV = (
  data,
  setIsDialogShow,
  setFirstRows,
  setTotalRows,
) => {
  setIsDialogShow(true)
  setFirstRows(0)
  const totalItems = data?.totalItems || 0
  setTotalRows(totalItems)

  const separator = ','

  // HEADER CSV
  let csvString = `${escapeCsvValue('Lama Trip')}${separator}${escapeCsvValue('Tanggal Keberangkatan')}${separator}${escapeCsvValue('Tanggal Kepulangan')}${separator}${escapeCsvValue('Koordinat Keberangkatan')}${separator}${escapeCsvValue('WPP')}${separator}${escapeCsvValue('Nama Kapal')}${separator}${escapeCsvValue('Kapten')}${separator}${escapeCsvValue('Nomor Kapal')}${separator}${escapeCsvValue('Berat Tangkapan')}${separator}${escapeCsvValue('Berat Dialihkan')}${separator}${escapeCsvValue('Berat Bersih')}${separator}${escapeCsvValue('FAO Code')}${separator}${escapeCsvValue('Waktu Tangkapan')}${separator}${escapeCsvValue('Latitude Penangkapan')}${separator}${escapeCsvValue('Longitude Penangkapan')}\n`

  // START EXPORTING
  exportFile(
    0,
    data,
    totalItems,
    setFirstRows,
    (chunk) => {
      chunk.forEach((row) => {
        const { startFishing, endFishing, vessel, fishes, moveReceipment } =
          row?.data || {}

        const calculateTransferredWeight = (fishId) => {
          const movementFishes = moveReceipment
            ?.flatMap((movement) => movement?.fishes)
            ?.filter((fish) => fish?.id === fishId)

          return movementFishes?.reduce(
            (total, fish) => total + (fish?.weight || 0),
            0,
          )
        }

        const formattedStartFishing = startFishing
          ? moment(startFishing)?.format('DD MMMM YYYY HH:mm')
          : ''
        const formattedEndFishing = endFishing
          ? moment(endFishing)?.format('DD MMMM YYYY HH:mm')
          : ''
        const departureCoordinates =
          fishes?.[0]?.latitude && fishes?.[0]?.longitude
            ? `${fishes?.[0]?.latitude}, ${fishes?.[0]?.longitude}`
            : ''
        const tripDuration = endFishing
          ? moment
              ?.duration(moment(endFishing)?.diff(moment(startFishing)))
              ?.humanize()
          : ''
        const catchAreas =
          row?.data?.fishes?.length > 0
            ? [
                ...new Set(row?.data?.fishes?.map((fish) => fish?.catchArea)),
              ]?.join(', ')
            : ''

        fishes?.forEach((fish) => {
          const { attribute } = fish?.selectedFish || {}
          const faoCode = attribute?.code ?? ''
          const fishAmount = Number(fish?.amount) ?? 0
          const formattedFishingDate = moment(fish?.createdAt).format(
            'DD MMMM YYYY HH:mm',
          )
          const fishLatitude = fish?.latitude ?? ''
          const fishLongitude = fish?.longitude ?? ''
          const transferredWeight = calculateTransferredWeight(fish?.id)
          const totalNetWeight = Number(fishAmount) - Number(transferredWeight)

          csvString += `${escapeCsvValue(tripDuration)}${separator}${escapeCsvValue(formattedStartFishing)}${separator}${escapeCsvValue(formattedEndFishing)}${separator}${escapeCsvValue(departureCoordinates)}${separator}${escapeCsvValue(catchAreas)}${separator}${escapeCsvValue(vessel?.shipName ?? '-')}${separator}${escapeCsvValue(vessel?.captain ?? '-')}${separator}${escapeCsvValue(vessel?.shipNumber ?? '-')}${separator}${escapeCsvValue(fishAmount)}${separator}${escapeCsvValue(transferredWeight)}${separator}${escapeCsvValue(totalNetWeight)}${separator}${escapeCsvValue(faoCode)}${separator}${escapeCsvValue(formattedFishingDate)}${separator}${escapeCsvValue(fishLatitude)}${separator}${escapeCsvValue(fishLongitude)}\n`
        })
      })
    },
    () => {
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })
      FileSaver.saveAs(
        blob,
        `Data_Tangkapan_Report_${moment()?.format('YYYY-MM-DD')}.csv`,
      )
      setIsDialogShow(false)
    },
    (error) => {
      console.error('Export error:', error)
      setIsDialogShow(false)
    },
  )
}

// FUNCTION TO DOWNLOAD EXCEL
export const handleDownloadExcel = (
  data,
  setIsDialogShow,
  setFirstRows,
  setTotalRows,
) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const bookType = 'xlsx'
  let count = 2

  // HEADERS
  const exportHeader = [
    'Lama Trip',
    'Tanggal Keberangkatan',
    'Tanggal Kepulangan',
    'Koordinat Keberangkatan',
    'WPP',
    'Nama Kapal',
    'Kapten',
    'Nomor Kapal',
    'Berat Tangkapan',
    'Berat Dialihkan',
    'Berat Bersih',
    'FAO Code',
    'Waktu Tangkapan',
    'Latitude Penangkapan',
    'Longitude Penangkapan',
  ]

  const ws = XLSX.utils.book_new()
  XLSX.utils.sheet_add_aoa(ws, [exportHeader])
  setIsDialogShow(true)
  setFirstRows(0)
  const totalItems = data?.totalItems || 0
  setTotalRows(totalItems)

  const calculateTransferredWeight = (moveReceipment, fishId) => {
    const movementFishes = moveReceipment
      ?.flatMap((movement) => movement?.fishes)
      ?.filter((fish) => fish?.id === fishId)

    return movementFishes?.reduce(
      (total, fish) => total + (fish?.weight || 0),
      0,
    )
  }

  exportFile(
    0,
    data,
    totalItems,
    setFirstRows,
    (chunk) => {
      const rechunk = chunk?.flatMap((row) => {
        const { startFishing, endFishing, vessel, fishes, moveReceipment } =
          row?.data || {}

        const formattedStartFishing = startFishing
          ? moment(startFishing)?.format('DD MMMM YYYY HH:mm')
          : ''
        const formattedEndFishing = endFishing
          ? moment(endFishing)?.format('DD MMMM YYYY HH:mm')
          : ''
        const departureCoordinates =
          fishes?.[0]?.latitude && fishes?.[0]?.longitude
            ? `${fishes?.[0]?.latitude}, ${fishes?.[0]?.longitude}`
            : ''
        const tripDuration = endFishing
          ? moment
              ?.duration(moment(endFishing)?.diff(moment(startFishing)))
              ?.humanize()
          : ''
        const catchAreas =
          fishes.length > 0
            ? [...new Set(fishes?.map((fish) => fish?.catchArea))]?.join(', ')
            : ''

        return fishes.map((fish) => {
          const { selectedFish, amount, latitude, longitude, createdAt } = fish
          const faoCode = selectedFish?.attribute?.code ?? ''
          const fishAmount = Number(amount) ?? 0
          const formattedFishingDate =
            moment(createdAt).format('DD MMMM YYYY HH:mm')
          const transferredWeight = calculateTransferredWeight(
            moveReceipment,
            fish?.id,
          )
          const totalNetWeight = Number(fishAmount) - Number(transferredWeight)

          return [
            tripDuration,
            formattedStartFishing,
            formattedEndFishing,
            departureCoordinates,
            catchAreas,
            vessel?.shipName ?? '',
            vessel?.captain ?? '',
            vessel?.shipNumber ?? '',
            fishAmount,
            transferredWeight,
            totalNetWeight,
            faoCode,
            formattedFishingDate,
            latitude ?? '',
            longitude ?? '',
          ]
        })
      })

      XLSX.utils.sheet_add_json(ws, rechunk, {
        origin: `A${count}`,
        skipHeader: true,
      })
      count += rechunk.length
    },
    () => {
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
      const excelBuffer = XLSX.write(wb, {
        bookType: bookType,
        type: 'array',
        cellStyles: true,
      })
      const finalData = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(
        finalData,
        `Data_Tangkapan_Report_${moment().format('YYYY-MM-DD')}.xlsx`,
      )
      setIsDialogShow(false)
    },
    (error) => {
      console.error('Export error:', error)
      setIsDialogShow(false)
    },
  )
}

// FUNCTION TO JSON
export const handleDownloadJSON = (
  data,
  setIsDialogShow,
  setFirstRows,
  setTotalRows,
) => {
  setIsDialogShow(true)
  setFirstRows(0)
  const totalItems = data?.totalItems || 0
  setTotalRows(totalItems)

  const jsonOutput = []

  // START EXPORTING
  exportFile(
    0,
    data,
    totalItems,
    setFirstRows,
    (chunk) => {
      chunk.forEach((row) => {
        const { startFishing, vessel, fishes, moveReceipment } = row?.data || {}

        // LOOP THROUGH EACH FISH TO CREATE A SEPARATE EVENT FOR EACH FISH
        fishes?.forEach((fish) => {
          // EVENT FOR FISHING (PENANGKAPAN)
          const fishingEvent = {
            eventType: 'ObjectEvent',
            eventTime: moment(startFishing).format('YYYY-MM-DDTHH:mm:ssZ'),
            eventTimeZoneOffset: '+00:00',
            epcList: [
              `urn:epc:id:sgtin:${fish.selectedFish?.attribute?.code}.${fish.amount}`,
            ],
            action: 'OBSERVE',
            bizStep: 'urn:epcglobal:cbv:bizstep:fishing',
            disposition: 'urn:epcglobal:cbv:disp:active',
            readPoint: {
              id: `urn:epc:id:sgln:${vessel?.shipNumber}`,
            },
            bizLocation: {
              id: `urn:epc:id:sgln:${vessel?.shipName}`,
            },
            ilmd: {
              fishSpecies: fish?.selectedFish?.nameItem,
              catchMethod: '-',
              vesselID: vessel?.shipNumber,
              catchDate: moment(startFishing).format('YYYY-MM-DDTHH:mm:ssZ'),
              catchLocation: {
                latitude: fish?.latitude,
                longitude: fish?.longitude,
              },
              fishWeight: {
                value: fish?.amount,
                unit: 'kg',
              },
            },
          }

          jsonOutput.push(fishingEvent)
        })

        // ADDITIONAL EVENT FOR TRANSSHIPMENT (PEMINDHANAN)
        if (moveReceipment && moveReceipment.length > 0) {
          moveReceipment.forEach((receipt) => {
            receipt.fishes?.forEach((fish) => {
              const transshipEvent = {
                eventType: 'ObjectEvent',
                eventTime: moment(receipt.createdAt).format(
                  'YYYY-MM-DDTHH:mm:ssZ',
                ),
                eventTimeZoneOffset: '+00:00',
                epcList: [
                  `urn:epc:id:sgtin:${fish.selectedFish?.attribute?.code}.${fish.weight}`,
                ],
                action: 'OBSERVE',
                bizStep: 'urn:epcglobal:cbv:bizstep:transshipment',
                disposition: 'urn:epcglobal:cbv:disp:active',
                readPoint: {
                  id: `urn:epc:id:sgln:${receipt.shipName}`,
                },
                bizLocation: {
                  id: `urn:epc:id:sgln:Location_for_Transshipment`,
                },
                ilmd: {
                  fishSpecies: fish?.selectedFish?.nameItem,
                  transshipMethod: 'Transfer',
                  vesselID: receipt.shipName,
                  transshipDate: moment(receipt.createdAt).format(
                    'YYYY-MM-DDTHH:mm:ssZ',
                  ),
                  transshipLocation: {
                    latitude: receipt.latitude,
                    longitude: receipt.longitude,
                  },
                  fishWeight: {
                    value: fish?.weight ?? 0,
                    unit: 'kg',
                  },
                },
              }

              jsonOutput.push(transshipEvent)
            })
          })
        }
      })
    },
    () => {
      const blob = new Blob(
        [JSON.stringify({ epcisBody: { eventList: jsonOutput } }, null, 2)],
        { type: 'application/json;charset=utf-8;' },
      )
      FileSaver.saveAs(
        blob,
        `Data_Tangkapan_Report_GDST_${moment().format('YYYY-MM-DD')}.json`,
      )
      setIsDialogShow(false)
    },
    (error) => {
      console.error('Export error:', error)
      setIsDialogShow(false)
    },
  )
}

// FUNCTION EXPORT FILE
const exportFile = (
  currentPage,
  data,
  totalRows,
  setFirstRows,
  onReceiveChunk,
  onFinished,
  onError,
) => {
  const perPage = 10
  const totalPages = Math.ceil(totalRows / perPage)

  // SIMULATE FETCHING DATA IN CHUNKS
  setTimeout(() => {
    const chunk = data?.items.slice(
      currentPage * perPage,
      (currentPage + 1) * perPage,
    )
    setFirstRows((currentPage + 1) * perPage)
    onReceiveChunk(chunk)

    if (currentPage < totalPages - 1) {
      exportFile(
        currentPage + 1,
        data,
        totalRows,
        setFirstRows,
        onReceiveChunk,
        onFinished,
        onError,
      )
    } else {
      onFinished()
    }
  }, 3000)
}
