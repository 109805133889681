import { Card, CardHeader, CardText, Container, Nav, NavItem } from 'reactstrap'
import { NavLink as NavLinkDom, Outlet } from 'react-router-dom'
import { useFetchSettingsQuery } from './settingApiSlice'
import { Can } from 'context/Acl'
import { settingRoutes } from 'routes/settingRoutes'
import BreadCrumbs from 'components/breadcrumbs'

const SettingView = () => {
  const { data, isLoading, isSuccess } = useFetchSettingsQuery()
  return (
    <div className="pb-4 px-3 w-100">
      <div className="d-flex align-items-start gap-2 pt-3">
        <BreadCrumbs breadCrumbActive={'Pengaturan'} />
      </div>
      <Card className="w-100 border-0 shadow-sm">
        <CardHeader className="border-bottom-0 bg-transparent">
          <CardText tag="h4" className="p-3 fw-semibold">
            Pengaturan
          </CardText>
          <Nav>
            {settingRoutes.map((item, idx) => (
              <Can I={item.action} this={item.subject} key={idx}>
                <NavItem>
                  <NavLinkDom
                    to={item.path}
                    aria-current="page"
                    className={({ isActive }) => {
                      if (isActive) {
                        return `nav-link active-link`
                      } else {
                        return `nav-link`
                      }
                    }}
                  >
                    {item.name}
                  </NavLinkDom>
                </NavItem>
              </Can>
            ))}
          </Nav>
        </CardHeader>
        <Outlet context={[data, isLoading, isSuccess]} />
      </Card>
      <Card
        body
        className="border-0"
        style={{ backgroundColor: 'transparent' }}
      >
        <span className="text-xs text-center mb-1">
          Versi: {process.env.REACT_APP_VERSION}
        </span>
      </Card>
    </div>
  )
}

export default SettingView
