import { useLocation } from 'react-router-dom'
import ManageStock from './ManageStock'

export const DetailInventoriesPacking = () => {
  const location = useLocation()
  const dataState = location.state

  const id = window.location.href.substring(
    window.location.href.lastIndexOf('/') + 1,
  )

  return (
    <>
      <ManageStock isDetail={true} id={id} dataState={dataState} />
    </>
  )
}
