import { itemUnitList } from 'constants'
import { X } from 'react-feather'
import { CardText, CardTitle, Modal } from 'reactstrap'

export default function ItemUnitModal({ isOpen, onClose, onSelectItemUnit }) {
  return (
    <Modal isOpen={isOpen} centered>
      <div className="regular-padding border-bottom d-flex justify-content-between align-items-center">
        <CardTitle className="fw-semibold">Pilih Unit</CardTitle>
        <X className="button" onClick={onClose} />
      </div>
      <div className="regular-padding-horizontal small-scrollable-container">
        {itemUnitList?.map((el, i) => {
          return (
            <div
              key={`${i}-${el?.title}`}
              className="regular-padding-bottom border-bottom regular-padding-top button"
              onClick={() => onSelectItemUnit(el)}
            >
              <CardText>{el?.title}</CardText>
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
