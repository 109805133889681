import ConnectAccount from 'features/public-views/connect-account'
import ForgetPasswordPage from 'features/public-views/forgetPassword'
import StepFourForgetPassword from 'features/public-views/forgetPassword/StepFour'
import StepOneForgetPassword, {
  loader as stepOneLoader,
} from 'features/public-views/forgetPassword/StepOne'
import StepTwoForgetPassword from 'features/public-views/forgetPassword/StepTwo'
import LoginNew from 'features/public-views/login-new'
import Otp from 'features/public-views/login-new/otp'
import Pin from 'features/public-views/login-new/pin'
import OneLinkTopup from 'features/public-views/one-link-topup'
import Expired from 'features/public-views/one-link-topup/Expired'
import Failed from 'features/public-views/one-link-topup/Failed'
import Instruction from 'features/public-views/one-link-topup/Instruction'
import Success from 'features/public-views/one-link-topup/Success'
import TraceabilityTemporary from 'features/public-views/traceability-temporary'

import {
  URL_CONNECT_ACCOUNT,
  URL_FORGET_PASSWORD_STEP_1,
  URL_FORGET_PASSWORD_STEP_2,
  URL_FORGET_PASSWORD_STEP_4,
} from 'utils/pathUrl'

import { FORGET_PASSWORD, READ } from 'utils/subjectActions'

export const publicRouter = [
  {
    element: <ForgetPasswordPage action={READ} subject={FORGET_PASSWORD} />,
    children: [
      {
        path: URL_FORGET_PASSWORD_STEP_1,
        element: <StepOneForgetPassword />,
        loader: stepOneLoader,
      },
      {
        path: URL_FORGET_PASSWORD_STEP_2,
        element: <StepTwoForgetPassword />,
      },
      {
        path: URL_FORGET_PASSWORD_STEP_4,
        element: <StepFourForgetPassword />,
      },
    ],
    action: READ,
    subject: FORGET_PASSWORD,
    name: 'Forget Password',
  },
  {
    path: URL_CONNECT_ACCOUNT,
    element: <ConnectAccount />,
  },
  {
    path: '/public-payment',
    element: <OneLinkTopup />,
  },
  {
    path: '/public-payment-instruksi',
    element: <Instruction />,
  },
  {
    path: '/public-payment-expired',
    element: <Expired />,
  },
  {
    path: '/public-payment-success',
    element: <Success />,
  },
  {
    path: '/public-payment-failed',
    element: <Failed />,
  },
  {
    path: '/login-v2',
    element: <LoginNew />,
  },
  {
    path: '/pin',
    element: <Pin />,
  },
  {
    path: '/otp',
    element: <Otp />,
  },
  {
    path: '/traceability',
    element: <TraceabilityTemporary />,
  },
]
