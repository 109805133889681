import { createAppSlice } from 'app/createAppSlice'

const initialState = {
  tag: {
    size: 10,
    page: 0,
  },
  isOpenModalAssign: false,
}

export const whistleBlowingsListSlice = createAppSlice({
  name: 'whistleBlowingsList',
  initialState,
  reducers: (create) => ({
    setFetchOption: create.reducer((state, { payload }) => {
      state.tag.size = payload.size
      state.tag.page = payload.page
    }),
    setIsOpenModalAssign: create.reducer((state, { payload }) => {
      state.isOpenModalAssign = !state.isOpenModalAssign
    }),
  }),
})

export const { setFetchOption, setIsOpenModalAssign } =
  whistleBlowingsListSlice.actions

export default whistleBlowingsListSlice.reducer
