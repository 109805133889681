import BreadCrumbs from 'components/breadcrumbs'
import { ButtonIcon } from 'components/Buttons'
import CustomButton from 'components/Buttons/CustomButton'
import PMBPagination from 'components/PMBPagination'
import { TableComponent as DataTable } from 'components/TableComponent'
import { Can } from 'context/Acl'
import { useState } from 'react'
import { ChevronDown, Eye, Send } from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ButtonGroup, Card, CardBody, Spinner } from 'reactstrap'
import { formatDatePMB } from 'utils/formatDate'
import { URL_FORM_TRANSFER_INVENTORY } from 'utils/pathUrl'
import { BUSINESS_TRANSFER_INVENTORY, MANAGE } from 'utils/subjectActions'
import SidebarTfInventory from './SidebarTfInventory'
import { useFetchInventoryTransferQuery } from './transferInventoryApiSlice'
import { setFetchOption, toggleSidebar } from './transferInventorySlice'
import IPhoto from 'assets/icons/Pak-Tani.png'

const TransferInventory = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const intl = useIntl()
  const { tag } = useSelector((s) => s.transferInventory)
  const { profile } = useSelector((s) => s.dashboard)
  let [page, setPage] = useState({ page: tag.page })
  const [selectedRow, setSelectedRow] = useState(null)
  const [sortColumn, setSortColumn] = useState('createdAt')
  const [sortDirection, setSortDirection] = useState('desc')

  const { data, isLoading, refetch } = useFetchInventoryTransferQuery(
    {
      page: tag?.page,
      sortby: sortColumn,
      sortdir: sortDirection,
      type: tag?.type,
      size: tag?.size,
    },
    { skip: !page },
  )

  const columns = [
    {
      name: <span>Tanggal</span>,
      sortable: true,
      sortField: 'createdAt',
      grow: 2,
      cell: (row) => formatDatePMB(row?.createdAt, row?.createdAt),
    },
    {
      name: <span>Nama Inventori</span>,
      grow: 2,
      cell: (row) => (
        <div className="d-flex flex-column py-2">
          <span className="flex-row">
            {row?.warehousetransferitem?.[0]?.inventory?.item?.nameItem}
          </span>
          <span className="text-primary">
            {intl.formatNumber(
              row?.warehousetransferitem?.[0]?.amount ??
                row?.warehousetransferitem?.[0]?.inventory?.amount,
              {
                useGrouping: 'always',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              },
            )}{' '}
            {row?.warehousetransferitem?.[0]?.unit ??
              row?.warehousetransferitem?.[0]?.inventory?.unit}
          </span>
          {row?.warehousetransferitem?.length > 1 ? (
            <div className="bg-gray-100 badge rounded-pill my-1">
              <span className="text-dark fw-medium">
                +{' '}
                {intl.formatNumber(row?.warehousetransferitem?.length - 1, {
                  useGrouping: 'always',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}{' '}
                inventori lainnya
              </span>
            </div>
          ) : (
            ''
          )}
        </div>
      ),
    },
    {
      name: <span>Gudang Pengirim</span>,
      grow: 2,
      cell: (row) => {
        return (
          <div
            className="d-flex flex-column gap-1 py-2"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <span>{row?.warehousetransferitem?.[0]?.warehouse?.name}</span>
            {row?.sender?.name ? (
              <div className="d-flex gap-1 align-items-center">
                <img
                  src={row?.sender?.profilePic ?? IPhoto}
                  className={`rounded-circle ${profile?.userId === row?.sender?.userId ? 'border border-2 border-success' : ''}`}
                  alt="Pengirim"
                  width={20}
                  height={20}
                />
                <span className="text-gray-500">{row?.sender?.name}</span>
              </div>
            ) : (
              <div style={{ minHeight: '24px' }}></div>
            )}
          </div>
        )
      },
    },
    {
      name: <span>Gudang Tujuan</span>,
      grow: 2,
      sortable: true,
      sortField: 'warehousereceiver.name',
      cell: (row) => {
        return (
          <div
            className="d-flex flex-column gap-1 py-2"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <span>{row?.warehousereceiver?.name}</span>
            {row?.receiver?.name ? (
              <div className="d-flex gap-1 align-items-center">
                <img
                  src={row?.receiver?.profilePic ?? IPhoto}
                  className={`rounded-circle ${profile?.userId === row?.receiver?.userId ? 'border border-2 border-success' : ''}`}
                  alt="Penerima"
                  width={20}
                  height={20}
                />
                <span className="text-gray-500">{row?.receiver?.name}</span>
              </div>
            ) : (
              <div style={{ minHeight: '24px' }}></div>
            )}
          </div>
        )
      },
    },

    {
      name: <span>Status</span>,
      grow: 2,
      cell: (row) => {
        if (row?.approvedAt) {
          return (
            <div className="badge rounded-pill bg-success-50 text-success-700 px-2">
              Diterima
            </div>
          )
        } else if (row?.rejectedAt) {
          return (
            <div className="badge rounded-pill bg-danger-50 text-danger-700 px-2">
              Ditolak
            </div>
          )
        } else {
          return (
            <div className="badge rounded-pill bg-warning-50 text-warning-700 px-2">
              Menunggu konfirmasi penerima
            </div>
          )
        }
      },
    },
    {
      name: '',
      cell: (row) => {
        return (
          <div className="d-flex gap-3">
            <Eye
              size={20}
              color="#475467"
              className="button"
              onClick={() => {
                dispatch(toggleSidebar())
                setSelectedRow(row)
              }}
            />
          </div>
        )
      },
    },
  ]

  const handleSort = (column, direction) => {
    setSortColumn(column.sortField)
    setSortDirection(direction === 'asc' ? 'desc' : 'asc')
  }

  const handleCallback = () => {
    refetch({})
  }

  return (
    <div className="w-100 px-3">
      <SidebarTfInventory data={selectedRow} handleCallback={handleCallback} />
      <div className="d-flex align-items-start gap-2 pt-3">
        <BreadCrumbs breadCrumbActive={'Transfer Inventori'} />
      </div>
      <div className="d-flex flex-column gap-2 mb-2">
        <Card className="border-0 shadow-sm">
          <CardBody>
            <DataTable
              columns={columns}
              data={data?.items}
              progressPending={isLoading}
              responsive
              persistTableHead
              defaultSortFieldId={1}
              defaultSortAsc={false}
              progressComponent={<Spinner />}
              sortIcon={<ChevronDown />}
              sortServer
              noDataComponent={
                <p className="my-3 fw-semibold">
                  Belum ada inventori{' '}
                  {tag?.type === 'out' ? ' keluar' : 'masuk'}
                </p>
              }
              onSort={handleSort}
              pagination
              paginationServer
              paginationComponent={(props) => (
                <PMBPagination
                  rowCount={data?.totalItems || 0}
                  currentPage={tag?.page}
                  rowsPerPage={tag?.size}
                  setCurrentPage={(e) => {
                    dispatch(setFetchOption({ page: e }))
                  }}
                  setRowsPerPage={(e) => {
                    dispatch(setFetchOption({ size: e }))
                  }}
                  props={props}
                />
              )}
              subHeader={true}
              subHeaderComponent={
                <div className="w-100">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5>Transfer Inventori</h5>
                    <Can I={MANAGE} this={BUSINESS_TRANSFER_INVENTORY}>
                      <CustomButton
                        onClick={() => navigate(URL_FORM_TRANSFER_INVENTORY)}
                        color="primary"
                      >
                        <Send size={15} /> Kirim Inventori
                      </CustomButton>
                    </Can>
                  </div>
                  <div className="w-100 mb-4">
                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <ButtonGroup>
                        <ButtonIcon
                          btnTitle="Inventori Masuk"
                          btnTitleProps={{
                            className: 'text-dark fw-medium fs-sm',
                          }}
                          btnProps={{
                            size: 'sm',
                            className: 'w-auto btn-dashboard-secondary',
                            color: 'secondary',
                            active: tag?.type === 'in',
                            onClick: () => {
                              dispatch(
                                setFetchOption({
                                  ...tag,
                                  type: 'in',
                                }),
                              )
                            },
                          }}
                        />

                        <ButtonIcon
                          btnTitle="Inventori Keluar"
                          btnTitleProps={{
                            className: 'text-dark fw-medium fs-sm',
                          }}
                          btnProps={{
                            size: 'sm',
                            className: 'w-auto btn-dashboard-secondary',
                            color: 'secondary',
                            active: tag?.type === 'out',
                            onClick: () => {
                              dispatch(
                                setFetchOption({
                                  ...tag,
                                  type: 'out',
                                }),
                              )
                            },
                          }}
                        />
                      </ButtonGroup>
                    </div>
                  </div>
                </div>
              }
            />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default TransferInventory
