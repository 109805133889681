import CustomButton from 'components/Buttons/CustomButton'
import PMBSelect from 'components/PMBSelect'
import { useEffect, useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap'
import { packingAddStock, packingStock } from '../../API/manageStockAction'
import { enqueueSnackbar } from 'notistack'

export default function ModalPacking({
  isOpen,
  onClose,
  data,
  setIsFetch,
  setSelectedInventoryList,
  toggleClearRows,
}) {
  const dispatch = useDispatch()
  const { groupsInventory } = useSelector((s) => s.manageStock)
  const [label, setLabel] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [isExist, setIsExist] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const obj = {
        ...(!isExist && {
          label: label,
        }),
        ...(isExist && {
          id: label?.id,
        }),
        addedInventoryIds: data?.map((e) => e?.id),
      }
      const res = isExist
        ? await dispatch(packingAddStock(obj))
        : await dispatch(packingStock(obj))
      if (res?.error) {
        throw res
      } else {
        setIsFetch('all')
        onClose()
        enqueueSnackbar(`${data?.length} inventori berhasil dibuatkan grup`, {
          variant: 'success',
        })
        setSelectedInventoryList([])
        toggleClearRows()
      }
    } catch (error) {
      setErrMsg(error?.message ?? error?.payload)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!isOpen) {
      setLabel('')
      setErrMsg('')
    }
  }, [isOpen])

  return (
    <Modal className="p-4" isOpen={isOpen} centered>
      <ModalBody>
        <h5>Buatkan Grup</h5>
        <FormGroup>
          <Label>Label grup</Label>
          {!isExist ? (
            <Input
              name="label"
              id="label"
              autoFocus={true}
              value={label ?? ''}
              onChange={(e) => {
                setLabel(e.target.value)
                setErrMsg('')
              }}
              placeholder="Contoh: Packingan 1"
            />
          ) : (
            <PMBSelect
              placeholder="Pilih grup..."
              options={groupsInventory?.items}
              value={label}
              onChange={(e) => {
                setLabel(e)
              }}
            />
          )}
        </FormGroup>
        <FormGroup>
          <Input
            name="isExist"
            id="isExist"
            value={label ?? ''}
            onChange={(e) => {
              if (isExist) {
                setIsExist(false)
              } else {
                setIsExist(true)
              }
              setErrMsg('')
            }}
            type="checkbox"
            className="me-2"
          />
          Masukkan ke grup yang sudah ada
        </FormGroup>
        {errMsg && (
          <div className="mb-2">
            <small className="text-danger">
              <AlertCircle size={15} /> {errMsg}
            </small>
          </div>
        )}
        <div className="d-flex gap-3">
          <CustomButton
            onClick={() => {
              onClose()
              setIsExist(false)
              setErrMsg('')
            }}
            disabled={loading}
            color="primary"
            outline
            block
          >
            Batal
          </CustomButton>
          <CustomButton
            disabled={loading || !label}
            onClick={handleSubmit}
            color="primary"
            block
          >
            Buatkan Grup
          </CustomButton>
        </div>
      </ModalBody>
    </Modal>
  )
}
