import SidebarDetails from 'components/SidebarDetails'
import { STATUS_TYPE } from 'constants'
import { Card, CardText } from 'reactstrap'
import { formatDatePMBV2 } from 'utils/formatDate'

const SidebarDetailWhistleBlowing = ({
  isOpenSidebar,
  onHandleOpenCloseSidebar,
  detailRow,
}) => {
  // HANDLE NOTE BASED ON STATUS TYPE
  const NoteColumn = ({ row }) => {
    const getNote = (row) => {
      switch (row?.status) {
        case STATUS_TYPE.ASSIGNED:
          return row?.assignNotes ?? '-'
        case STATUS_TYPE.RESOLVED:
          return row?.resolveNotes ?? '-'
        case STATUS_TYPE.UNRESOLVED:
          return row?.unresolveNotes ?? '-'
        default:
          return '-'
      }
    }

    return <span>{getNote(row)}</span>
  }

  return (
    <>
      {/* SIDEBAR DETAIL */}
      <SidebarDetails
        size="lg"
        open={isOpenSidebar}
        title="Detail Pengaduan"
        headerClassName="mb-1"
        contentClassName="pt-0"
        toggleSidebar={() => {
          onHandleOpenCloseSidebar()
        }}
        style={{
          backgroundColor: 'white',
        }}
      >
        <>
          <div
            body
            className="mb-3 mt-3"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CardText tag="h5" className="fw-semibold">
              {detailRow?.name ?? '-'}
            </CardText>
          </div>
          <Card
            body
            className="mb-3 border-0 shadow-sm"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor:
                detailRow?.status === STATUS_TYPE?.RESOLVED
                  ? '#198754'
                  : '#006386',
              color: 'white',
            }}
          >
            <div>
              <CardText tag="h6" className="fw-semibold">
                {detailRow?.status === STATUS_TYPE?.RESOLVED
                  ? detailRow?.status
                  : 'OPEN'}
              </CardText>
            </div>
          </Card>
          <Card body className="mb-3 mt-3 border-0 shadow-sm">
            <div>
              <CardText tag="h6" className="fw-semibold">
                Nama Pelapor
              </CardText>
              <p className="m-0">{detailRow?.user?.name ?? '-'}</p>
            </div>
          </Card>
          <Card body className="mb-3 mt-3 border-0 shadow-sm">
            <div>
              <CardText tag="h6" className="fw-semibold">
                Laporan Diterima
              </CardText>
              <p className="m-0">{formatDatePMBV2(detailRow?.createdAt)}</p>
            </div>
          </Card>
          {detailRow?.status === STATUS_TYPE?.RESOLVED && (
            <Card body className="mb-3 mt-3 border-0 shadow-sm">
              <div>
                <CardText tag="h6" className="fw-semibold">
                  Laporan Selesai
                </CardText>
                <p className="m-0">{formatDatePMBV2(detailRow?.updatedAt)}</p>
              </div>
            </Card>
          )}
          <Card body className="mb-3 mt-3 border-0 shadow-sm">
            <div>
              <CardText tag="h6" className="fw-semibold">
                Deskripsi
              </CardText>
              <p className="m-0">{detailRow?.description ?? '-'}</p>
            </div>
          </Card>
          <Card body className="mb-3 mt-3 border-0 shadow-sm">
            <div>
              <CardText tag="h6" className="fw-semibold">
                Petugas
              </CardText>
              <p className="m-0">{detailRow?.assigned?.name ?? '-'}</p>
            </div>
          </Card>
          <Card body className="mb-3 mt-3 border-0 shadow-sm">
            <div>
              <CardText tag="h6" className="fw-semibold">
                Catatan
              </CardText>
              <p className="m-0">{<NoteColumn row={detailRow} />}</p>
            </div>
          </Card>
        </>
      </SidebarDetails>
    </>
  )
}

export default SidebarDetailWhistleBlowing
