import Logo from 'assets/images/pmg-logo-beta.png'
import CustomButton from 'components/Buttons/CustomButton'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Card, CardText, Container } from 'reactstrap'
import ItemsDetail from './Details'
import { getDataTrx } from './requestTransactionAction'
import { toggleModal } from './requestTransactionSlice'
import './style.scss'
import UserInfo from './UserInfo'
import ModalConfirm from './ModalConfirm'

const ConfirmPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { detail } = useSelector((s) => s.reqTrxSell)

  let code = searchParams.get('code')
  let amount = searchParams.get('totalAmount')

  useEffect(() => {
    if (!code || !amount) navigate('/')
  }, [code, amount, navigate])

  useEffect(() => {
    if (code && amount) {
      dispatch(getDataTrx({ code, amount }))
    }
  }, [code, amount])

  return (
    <div>
      <ModalConfirm />
      <Container>
        <Card
          style={{ maxWidth: 480 }}
          className="px-2 mx-auto d-flex justify-content-center align-items-center"
        >
          <img
            src={Logo}
            alt="Logo PasarMikro"
            className="text-center"
            style={{
              marginTop: 32,
              marginBottom: 24,
              width: '100%',
              maxWidth: '177px',
              height: 'auto',
            }}
          />
          <CardText tag="h4" className="mb-3">
            Transaksi sedang diproses
          </CardText>

          <UserInfo sellerDetail={detail?.sellerDetail} />
          <ItemsDetail
            itemDetail={detail?.itemDetail}
            totalAmount={detail?.totalAmount}
          />
          <CustomButton
            disabled={
              !detail?.escrowpaymentId && detail?.orderStatus !== 'ON_GOING'
            }
            onClick={() => dispatch(toggleModal())}
            className="mb-3"
            size="sm"
            block
            color="primary"
          >
            Konfirmasi Barang Sampai
          </CustomButton>
        </Card>
      </Container>
    </div>
  )
}

export default ConfirmPage
