import { createAppSlice } from 'app/createAppSlice'
import { confirmTrx, getDataTrx } from './requestTransactionAction'

const initialState = {
  approvedAt: null,
  canceledAt: null,
  createdAt: null,
  isLoading: false,
  isApproved: false,
  linkApproved: '',
  detail: null,
  isOpenModal: false,
}

export const requestTransactionSlice = createAppSlice({
  name: 'purchase/commodity',
  initialState,
  reducers: (create) => ({
    toggleModal: create.reducer((state, { payload }) => {
      state.isOpenModal = !state.isOpenModal
    }),
  }),
  extraReducers: (builder) => {
    builder
      .addCase(confirmTrx.pending, (state) => {
        state.isLoading = true
      })
      .addCase(confirmTrx.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isApproved = true
        state.linkApproved = payload.link
      })
      .addCase(confirmTrx.rejected, (state, { payload }) => {
        state.isLoading = false
        state.isApproved = false
        state.linkApproved = ''
      })
      .addCase(getDataTrx.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getDataTrx.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.detail = payload
      })
      .addCase(getDataTrx.rejected, (state, { payload }) => {
        state.isLoading = false
      })
  },
})

export const { toggleModal } = requestTransactionSlice.actions

export default requestTransactionSlice.reducer
