import ICPemasukan2 from 'assets/icons/pemasukan-2.svg'
import ICPengeluaran2 from 'assets/icons/pengeluaran-2.svg'
import CustomButton from 'components/Buttons/CustomButton'
import SidebarDetails from 'components/SidebarDetails'
import { Trash2 } from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Card } from 'reactstrap'
import { formatDatePMBV2 } from 'utils/formatDate'
import ModalDelete from './ModalDelete'
import { toggleModalDelete } from './cashFlowSlice'
import { Can } from 'context/Acl'
import { BUSINESS_BOOKKEEPING, MANAGE } from 'utils/subjectActions'

const SidebarCashFlow = ({
  row: data,
  isOpen,
  toggle,
  handleFinishedDelete,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { isOpenModalDelete } = useSelector((s) => s.cashflow)

  const extractNumberFromString = (str) => {
    const match = str.match(/\d+/)
    return match ? parseInt(match[0], 10) : null
  }

  return (
    <>
      <ModalDelete
        isOpen={isOpenModalDelete}
        toggle={() => dispatch(toggleModalDelete())}
        row={data}
        handleFinishedDelete={handleFinishedDelete}
      />
      <SidebarDetails
        open={isOpen}
        title={`Detail Catatan`}
        size="lg"
        headerClassName="mb-1"
        contentClassName="pt-0"
        toggleSidebar={toggle}
      >
        <div className="d-flex gap-3 flex-column">
          {/* Status */}
          <div
            className={`rounded p-3 text-center d-block w-100 fw-600 mt-3 ${data?.manual ? 'badge-warning' : 'badge-process'}`}
          >
            {data?.manual ? 'Catat Manual' : 'Transaksi'}
          </div>

          {/* Judul */}
          <div>
            <Card className="border-0 shadow-sm" body>
              <span className="fw-600">Judul pencatatan transaksi</span>
              <div>
                <img
                  height={24}
                  width={24}
                  src={
                    data?.incomeorexpense === 'income'
                      ? ICPemasukan2
                      : ICPengeluaran2
                  }
                  alt={
                    data?.incomeorexpense === 'income'
                      ? 'Pemasukan'
                      : 'Pengeluaran'
                  }
                />
                <span> {data?.extradata?.label ?? data?.label}</span>
              </div>
            </Card>
          </div>

          {/* Rincian Catatan */}
          <Card className="border-0 shadow-sm" body>
            <span className="fw-600">Rincian Catatan</span>
            <div className="gap-1">
              {data?.extradata?.items ? (
                data?.extradata?.items?.map((e, i) => {
                  return (
                    <div
                      key={i}
                      className="d-flex justify-content-between align-items-center"
                    >
                      <div className="d-flex flex-column mb-2">
                        <span>{e?.name}</span>
                        <span>
                          <span className="fw-light">{e?.qty} • </span>{' '}
                          <span className="text-primary">
                            Rp{' '}
                            {intl.formatNumber(
                              data?.amount /
                                extractNumberFromString(e?.qty ?? 0),
                              {
                                useGrouping: 'always',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              },
                            )}
                          </span>
                        </span>
                      </div>
                      <span>
                        Rp
                        {intl.formatNumber(data?.amount ?? 0, {
                          useGrouping: 'always',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </span>
                    </div>
                  )
                })
              ) : (
                <div className="d-flex justify-content-between align-items-center pt-3">
                  <span className="fw-600">Total Pemasukan</span>
                  <span className="fw-600">
                    Rp{' '}
                    {intl.formatNumber(data?.amount ?? 0, {
                      useGrouping: 'always',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </span>
                </div>
              )}
              <hr></hr>
              <div className="d-flex justify-content-between align-items-center">
                <span className="fw-600">Tanggal transaksi</span>
                <span className="fw-600">
                  {formatDatePMBV2(
                    `${data?.createddate + ' ' + data?.createdhour}`,
                  )}
                </span>
              </div>
            </div>
          </Card>

          {/* Catatan */}
          <div>
            <Card className="border-0 shadow-sm" body>
              <span className="fw-600">Catatan</span>
              <span>{data?.notes ? data?.notes : '-'}</span>
            </Card>
          </div>

          <Can I={MANAGE} this={BUSINESS_BOOKKEEPING}>
            {data?.manual ? (
              <CustomButton
                onClick={() => dispatch(toggleModalDelete())}
                color="danger"
                outline
                block
              >
                <div className="flex-centered gap-2">
                  <Trash2 size={17} /> Hapus Catatan
                </div>
              </CustomButton>
            ) : (
              <></>
            )}
          </Can>
        </div>
      </SidebarDetails>
    </>
  )
}

export default SidebarCashFlow
