import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap'
import { useUpdateCatchApproveByIdMutation } from './catchesListApiSlice'
import { setFetchOption, setIsOpenModalApprove } from './catchesListSlice'

const ModalApprove = ({ open, row, toggle, handleRefetch, catchId = null }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [updateApproved, { isLoading: isLoadingStatusMutation }] =
    useUpdateCatchApproveByIdMutation()

  const inputRef = useRef(null)
  const [notes, setNotes] = useState('')

  const currentYear = new Date().getFullYear()
  const startDateDefault = new Date(currentYear, 0, 1)
  const endDateDefault = new Date(currentYear, 11, 31)
  const [selectedDate] = useState([startDateDefault, endDateDefault])

  const resetState = () => {
    setNotes('')
  }

  const { enqueueSnackbar } = useSnackbar()

  // HANDLE SUBMIT
  const handleSubmit = async () => {
    try {
      const res = await updateApproved({
        id: row?.id,
        notes,
      }).unwrap()

      if (res && res.ok) {
        dispatch(setFetchOption({ page: 0, size: 10 }))
        dispatch(setIsOpenModalApprove())

        resetState()

        enqueueSnackbar('Verifikasi berhasil!', { variant: 'success' })

        handleRefetch()

        const targetPath = catchId ? `/catches/${catchId}` : '/catches'
        navigate(targetPath)
      } else {
        const errorMessage = res?.message || 'Verifikasi gagal!'
        enqueueSnackbar(errorMessage, { variant: 'error' })
      }
    } catch (error) {
      const errorMessage =
        error?.data?.message || 'Terjadi kesalahan saat verifikasi.'
      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }

  // HANDLE AUTO FOCUS IN TEXT AREA
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus()
        }
      }, 100)
    }
  }, [open])

  return (
    <Modal
      className="p-4"
      centered
      isOpen={open}
      toggle={() => {
        toggle()
      }}
      autoFocus={false}
    >
      <ModalBody>
        <FormGroup>
          <div className="mb-2">
            <h6>Verifikasi Data Perjalanan</h6>
            <span className="text-sm" style={{ color: '#475467' }}>
              Setelah disetujui, data tidak bisa diubah. Yakin ingin
              melanjutkan?
            </span>
          </div>

          <Label className="text-sm">Catatan Verifikasi (opsional)</Label>

          <div
            style={{
              width: '100%',
              border: '1px solid #d1d5db',
              borderRadius: '4px',
            }}
          >
            <Input
              innerRef={inputRef}
              style={{
                border: 'none',
                minHeight: 128,
                borderRadius: '4px',
                padding: '8px',
                boxSizing: 'border-box',
              }}
              value={notes}
              className="border-input"
              type="textarea"
              placeholder="Masukkan catatan persetujuan..."
              onChange={(e) => {
                const value = e.target.value
                if (value.trim() !== '') {
                  setNotes(value)
                } else {
                  setNotes('')
                }
              }}
            />
          </div>
        </FormGroup>

        <div className="d-flex gap-3">
          <Button
            onClick={() => {
              toggle()
              resetState()
            }}
            color="primary"
            outline
            block
          >
            Batal
          </Button>
          <Button
            disabled={isLoadingStatusMutation}
            color="primary"
            block
            onClick={handleSubmit}
          >
            {isLoadingStatusMutation ? 'Proses...' : 'Verifikasi'}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalApprove
