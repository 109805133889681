import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiEndpoint } from 'configs'

const getBalanceHistory = createAsyncThunk(
  'balance/getHistory',
  async (
    { page = 0, size = 50, descending = true, filter = '', date },
    { rejectWithValue, getState },
  ) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      let url = `${apiEndpoint}/api/v1/bussines/depohistory?page=${page}&size=${size}&sort=${descending ? 'DESC' : 'ASC'}`

      if (filter) {
        url += `&filter=${filter}`
      } else {
        url += `&filter=""`
      }
      if (date?.from) {
        url += `&from=${date?.from}&to=${date?.to}`
      }

      const { data } = await axios.get(url, config)

      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export { getBalanceHistory }
