import React from 'react'
import { Card, CardText, Container } from 'reactstrap'
import Logo from 'assets/images/pmg-logo-beta.png'
import successTrx from 'assets/images/success-trx.png'

const SuccessPage = () => {
  return (
    <Container className="mt-2">
      <Card
        style={{ maxWidth: 480 }}
        className="px-2 mx-auto d-flex justify-content-center align-items-center"
      >
        <img
          src={Logo}
          alt="Logo PasarMikro"
          className="text-center"
          style={{
            marginTop: 32,
            marginBottom: 24,
            width: '100%',
            maxWidth: '177px',
            height: 'auto',
          }}
        />
        <img
          style={{ width: '280px', height: '182px' }}
          alt="success transaction"
          src={successTrx}
        />
        <h6 className="fw-bold mt-3">Konfirmasi diterima</h6>
        <span className="mb-4">Transaksi anda telah selesai!</span>
      </Card>
    </Container>
  )
}

export default SuccessPage
