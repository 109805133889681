import { useState } from 'react'
import {
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap'

const PMBModalImagePreview = ({
  isOpen,
  toggle,
  images = [],
  startIndex = 0,
}) => {
  const [activeIndex, setActiveIndex] = useState(startIndex)
  const [animating, setAnimating] = useState(false)

  const next = () => {
    if (animating || images?.length <= 1) return
    const nextIndex = activeIndex === images?.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating || images?.length <= 1) return
    const nextIndex = activeIndex === 0 ? images?.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = (newIndex) => {
    if (animating || images?.length <= 1) return
    setActiveIndex(newIndex)
  }

  const slides = images?.map((url, index) => (
    <CarouselItem
      key={index}
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
    >
      <img
        src={url}
        style={{ width: '100%', height: '80vh', objectFit: 'cover' }}
        alt={`carousel-item-${index}`}
      />
    </CarouselItem>
  ))

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} />
      <ModalBody>
        {images?.length > 0 ? (
          <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            interval={false}
          >
            {images?.length > 1 && (
              <>
                <CarouselIndicators
                  items={images}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                <CarouselControl
                  direction="prev"
                  directionText="Previous"
                  onClickHandler={previous}
                />
                <CarouselControl
                  direction="next"
                  directionText="Next"
                  onClickHandler={next}
                />
              </>
            )}
            {slides}
          </Carousel>
        ) : (
          <p>No images to display</p>
        )}
      </ModalBody>
    </Modal>
  )
}

export default PMBModalImagePreview
