import { Modal, ModalBody, ModalHeader } from 'reactstrap'

const PMBModalTraceability = ({ isOpen, toggle, productId }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} fullscreen keyboard={false}>
      <ModalHeader toggle={toggle}>Traceability</ModalHeader>
      <ModalBody
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxHeight: '100vh',
          overflowY: 'auto',
          padding: 0,
        }}
      >
        <iframe
          src={`${process.env.REACT_APP_INVENTORY_URL}?productId=${productId}`}
          title="Traceability"
          style={{
            marginTop: '62px',
            width: '100%',
            height: '100vh',
            border: 'none',
          }}
        />
      </ModalBody>
    </Modal>
  )
}

export default PMBModalTraceability
