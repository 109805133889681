import CustomButton from 'components/Buttons/CustomButton'
import { useEffect, useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useDispatch } from 'react-redux'
import { FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap'
import { packingUpdate } from '../../API/manageStockAction'
import { enqueueSnackbar } from 'notistack'

export default function ModalRenamePacking({
  isOpen,
  onClose,
  data,
  setIsFetch,
}) {
  const dispatch = useDispatch()
  const [label, setLabel] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const obj = {
        label: label,
        id: data?.id,
      }
      const res = await dispatch(packingUpdate(obj))
      if (res?.error) {
        throw res
      } else {
        setIsFetch('grup')
        onClose()
        enqueueSnackbar(`Label berhasil diubah.`, {
          variant: 'success',
        })
      }
    } catch (error) {
      setErrMsg(error?.message ?? error?.payload)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      setLabel(data?.label)
    } else {
      setLabel('')
    }
  }, [isOpen])

  return (
    <Modal className="p-4" isOpen={isOpen} toggle={onClose} centered>
      <ModalBody>
        <h5>Ubah Grup</h5>
        <FormGroup>
          <Label>Label grup</Label>
          <Input
            name="label"
            id="label"
            autoFocus={true}
            value={label ?? ''}
            onChange={(e) => {
              setLabel(e.target.value)
              setErrMsg('')
            }}
            placeholder="Contoh: Packingan 1"
          />
        </FormGroup>

        {errMsg && (
          <div className="mb-2">
            <small className="text-danger">
              <AlertCircle size={15} /> {errMsg}
            </small>
          </div>
        )}
        <div className="d-flex gap-3">
          <CustomButton
            onClick={() => {
              onClose()
              setErrMsg('')
            }}
            disabled={loading}
            color="primary"
            outline
            block
          >
            Batal
          </CustomButton>
          <CustomButton
            disabled={loading ?? !label}
            onClick={handleSubmit}
            color="primary"
            block
          >
            Ubah
          </CustomButton>
        </div>
      </ModalBody>
    </Modal>
  )
}
