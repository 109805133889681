import LoadingV2 from 'components/LoadingV2'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Card, CardBody, Container, Spinner } from 'reactstrap'
import {
  useConfirmInviteAgentMutation,
  useGetInviteAgentQuery,
} from '../farmers-list/farmersListApiSlice'
import ModalConfirmation from './ModalConfirmation'

const AgentApproval = () => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')

  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false)
  const [confirmationMethod, setConfirmationMethod] = useState(null)

  const { data, isLoading } = useGetInviteAgentQuery({ id })

  const [confirmInviteAgent, { isLoading: isLoadingMutation }] =
    useConfirmInviteAgentMutation()

  const handleApprove = async () => {
    try {
      const response = await confirmInviteAgent({ id, approved: 1 }).unwrap()
      if (response || response?.data?.id) {
        enqueueSnackbar('Permintaan menjadi agen berhasil diterima!', {
          variant: 'success',
        })
        navigate('/')
      }
    } catch (error) {
      console.warn(error)
      enqueueSnackbar(
        error?.data?.meta?.message ||
          error?.data?.message ||
          error?.message ||
          'Permintaan menjadi agen gagal diterima!',
        {
          variant: 'error',
        },
      )
    }
  }

  const handleReject = async () => {
    try {
      const response = await confirmInviteAgent({ id, approved: 0 }).unwrap()
      if (response || response?.data?.id) {
        enqueueSnackbar('Permintaan menjadi agen berhasil ditolak!', {
          variant: 'success',
        })
      }
      navigate('/')
    } catch (error) {
      console.warn(error)
      enqueueSnackbar(
        error?.data?.meta?.message ||
          error?.data?.message ||
          error?.message ||
          'Permintaan menjadi agen gagal ditolak!',
        {
          variant: 'error',
        },
      )
    }
  }

  const handleToggleConfirmation = (method = null) => {
    setConfirmationMethod(method)
    setIsOpenConfirmation((prev) => !prev)
  }

  const handleConfirmationAction = (method) => {
    if (method === 'approve') {
      handleApprove()
    } else if (method === 'reject') {
      handleReject()
    }
  }

  const dataUserRequested = data?.items?.[0] || null
  const isDisabledButton =
    dataUserRequested?.sellerApprovedAt ||
    dataUserRequested?.sellerRejectedAt ||
    dataUserRequested?.agentApprovedAt ||
    dataUserRequested?.agentRejectedAt

  if (isLoading) return <LoadingV2 />

  return (
    <Container className="pb-3" style={{ width: '640px' }}>
      {/* MODAL CONFIRMATION */}
      <ModalConfirmation
        isOpen={isOpenConfirmation}
        toggle={() => handleToggleConfirmation()}
        title={
          confirmationMethod === 'approve'
            ? 'Terima permintaan menjadi agen'
            : 'Tolak permintaan menjadi agen'
        }
        subTitle={
          confirmationMethod === 'approve'
            ? `Anda akan menjadi agen untuk pembayaran kepada User ${dataUserRequested?.sellerverifiedprofile?.name ?? '-'}`
            : `Anda yakin ingin menolak permintaan User ${dataUserRequested?.sellerverifiedprofile?.name ?? '-'}?`
        }
        methodName={confirmationMethod}
        onConfirm={handleConfirmationAction}
        isApprove={confirmationMethod === 'approve' ? true : false}
        btnColor={confirmationMethod === 'approve' ? 'primary' : 'danger'}
      />
      {/* END MODAL CONFIRMATION */}
      <div className="d-flex flex-column gap-3">
        <div className="mt-4">
          <h5>Permintaan Menjadi Agen</h5>
        </div>
        <Card className="border-0 shadow-sm">
          <CardBody className="d-flex flex-column gap-2">
            <h5>Pengguna yang mengajukan</h5>
            <span>{dataUserRequested?.sellerverifiedprofile?.name ?? '-'}</span>
          </CardBody>
        </Card>

        {/* CARD BANK */}
        <Card className="border">
          <CardBody className="d-flex flex-column gap-2">
            <div className="d-flex justify-content-between">
              <span className="fw-semibold mb-2">
                Rekening yang Anda gunakan
              </span>
            </div>
            {dataUserRequested?.agentverifiedprofile?.bank?.logo && (
              <img
                width={50}
                height={50}
                src={dataUserRequested?.agentverifiedprofile?.bank?.logo}
                alt="Bank Logo"
                style={{ marginBottom: '6px' }}
              />
            )}
            <span className="fw-semibold text-sm">Nomor rekening</span>
            <span className="text-sm">
              {dataUserRequested?.agentverifiedprofile?.bankAccountNumber ??
                '-'}
            </span>
            <span className="fw-semibold text-sm">Nama pemilik rekening</span>
            <span className="text-sm">
              {dataUserRequested?.agentverifiedprofile?.bankAccountName ?? '-'}
            </span>
          </CardBody>
          <div className="d-flex gap-3 m-3">
            <Button
              className="btn-cancel"
              outline
              block
              disabled={isLoadingMutation || isDisabledButton}
              onClick={() => handleToggleConfirmation('reject')}
            >
              {isLoadingMutation ? <Spinner size="sm" /> : 'Tolak'}
            </Button>
            <Button
              color="primary"
              block
              disabled={isLoadingMutation || isDisabledButton}
              onClick={() => handleToggleConfirmation('approve')}
            >
              {isLoadingMutation ? <Spinner size="sm" /> : 'Terima'}
            </Button>
          </div>
        </Card>
        {/* END CARD BANK */}
      </div>
    </Container>
  )
}

export default AgentApproval
