import { useState } from 'react'
import { Button, Spinner } from 'reactstrap'

const CustomButton = ({ onClick, children, debounceTime = 1000, ...props }) => {
  const [isClicked, setIsClicked] = useState(false)

  const handleClick = async (e) => {
    if (isClicked) {
      return
    }

    setIsClicked(true)

    try {
      await onClick?.(e)
    } catch (error) {
      console.error(error)
    } finally {
      setIsClicked(false)
    }
  }

  return (
    <Button
      {...props}
      onClick={handleClick}
      disabled={isClicked || props.disabled}
    >
      {isClicked ? (
        <>
          <Spinner size="sm" />
        </>
      ) : (
        children
      )}
    </Button>
  )
}

export default CustomButton
