import { X } from 'react-feather'
import { Modal, ModalBody } from 'reactstrap'
import FormAddFisheries from '../forms'

const ModalEdit = ({
  row,
  isOpenModalEdit,
  onHandleOpenCloseModalEdit,
  handleRefetch,
}) => {
  return (
    <Modal
      centered
      isOpen={isOpenModalEdit}
      toggle={() => onHandleOpenCloseModalEdit()}
    >
      <ModalBody>
        <div className="d-flex justify-content-between align-item-center">
          <h6>Ubah Data Fishery</h6>
          <X
            style={{ cursor: 'pointer' }}
            onClick={() => onHandleOpenCloseModalEdit()}
          />
        </div>
        <FormAddFisheries
          row={row}
          onHandleOpenCloseModalEdit={onHandleOpenCloseModalEdit}
          handleRefetch={handleRefetch}
        />
      </ModalBody>
    </Modal>
  )
}

export default ModalEdit
