import { createAppSlice } from 'app/createAppSlice'

const initialState = {
  isOpenSidebar: false,
  tag: {
    size: 10,
    page: 0,
  },
  isOpenBanner: false,
  details: null,
  detailVessel: {},
  isOpenModalPemetaan: false,
}

export const vesselsListSlice = createAppSlice({
  name: 'vesselsList',
  initialState,
  reducers: (create) => ({
    setDetailVessel: create.reducer((state, { payload }) => {
      state.detailVessel = payload
    }),
    toggleSidebarWithdraw: create.reducer((state, { payload }) => {
      state.isOpenSidebar = !state.isOpenSidebar
    }),
    setFetchOption: create.reducer((state, { payload }) => {
      state.tag.size = payload.size
      state.tag.page = payload.page
    }),
    toggleBanner: create.reducer((state, { payload }) => {
      state.isOpenBanner = !state.isOpenBanner
    }),
    toggleModalPemetaan: create.reducer((state, { payload }) => {
      state.isOpenModalPemetaan = !state.isOpenModalPemetaan
    }),
  }),
})

export const {
  setDetailVessel,
  toggleSidebarWithdraw,
  setFetchOption,
  toggleBanner,
  toggleModalPemetaan,
} = vesselsListSlice.actions

export default vesselsListSlice.reducer
