import axios, { all } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiEndpoint } from 'configs'
import { UncontrolledCollapse } from 'reactstrap'
import { ChevronDown } from 'react-feather'

const checkSellerInfo = createAsyncThunk(
  'cekSellerInfo/commodity',
  async ({ search, type }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/transaction/checkuser?search=${search}&type=${type}`,
        config,
      )
      let finalData = {
        fullName: data.fullName,
        phoneNumber: data.phoneNumber,
        email: data.email,
        profileImg: data.profileImg,
        profileStatus: data.profileStatus ?? data.status,
        userId: data.userId,
      }

      return finalData
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const createOrder = createAsyncThunk(
  'sell/create/order/commodity',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      let result = {
        ...payload,
        item: payload.item?.map((i) => ({
          orderItemId: i.orderItemId,
          SKU: i.SKU,
          amount: i.amount,
          imageUrl: i.imageUrl,
          nameItem: i.nameItem,
          price: i.price,
          totalPrice: i.totalPrice,
          unit: i.unit.value,
          ...(i?.inventoryId && { inventoryId: i.inventoryId }),
        })),
      }

      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/bussines/transaction/sell`,
        result,
        config,
      )

      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const uploadFile = createAsyncThunk(
  'sell/create/order/upload',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth.token}`,
        },
      }

      const formData = new FormData()
      formData.append('file', payload)

      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/upload`,
        formData,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getComodities = createAsyncThunk(
  'sell/commodity/comodities',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      let frequentItems = []

      const storedFrequentItems =
        JSON.parse(localStorage.getItem('lastPickedCommoditiesSell')) || []

      try {
        const resFrequent = await axios.get(
          `${apiEndpoint}/api/v1/bussines/item-frequent?type=sell`,
          config,
        )

        frequentItems = resFrequent.data.map((e) => ({
          value: e.id,
          label: e.nameItem,
          id: e.id,
          img: e.linkFoto,
          sku: e.SKU,
        }))

        frequentItems = [...storedFrequentItems, ...frequentItems]

        frequentItems = frequentItems.filter(
          (item, index, self) =>
            index === self.findIndex((i) => i.id === item.id),
        )

        frequentItems = frequentItems.slice(0, 5)

        localStorage.setItem(
          'lastPickedCommoditiesSell',
          JSON.stringify(frequentItems),
        )
      } catch (error) {
        console.log(error)
      }

      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/items?origin=true&sortby=nameItem&sortdir=asc`,
        config,
      )

      let allItems = data.items

      if (data.totalItems > data.items.length) {
        const res = await axios.get(
          `${apiEndpoint}/api/v1/bussines/items?origin=true&sortby=nameItem&sortdir=asc&size=${data.totalItems}`,
          config,
        )
        allItems = res.data.items
      }

      const allMappedItems = allItems
        .map((e) => ({
          value: e.id,
          label: e.nameItem,
          id: e.id,
          img: e.linkFoto,
          sku: e.SKU,
        }))
        .filter(
          (item) => !frequentItems.some((frequent) => frequent.id === item.id),
        )

      return [
        {
          label: 'TERAKHIR/SERING DIPILIH',
          options: frequentItems,
        },
        {
          label: 'SEMUA KOMODITAS',
          options: allMappedItems,
        },
      ]
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getPackings = createAsyncThunk(
  'sell/commodity/packings',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }

      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/inventorygroups?isForTransaction=1`,
        config,
      )

      let allItems = data.items

      if (data.totalItems > data.items.length) {
        const res = await axios.get(
          `${apiEndpoint}/api/v1/bussines/inventorygroups?isForTransaction=1&size=${data.totalItems}`,
          config,
        )
        allItems = res.data.items
      }

      const allMappedItems = allItems.map((e) => ({
        value: e.id,
        isPacking: true,
        ...e,
      }))

      return allMappedItems
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getInventories = createAsyncThunk(
  'sell/commodity/inventories',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/inventory?sortby=nameItem&sortdir=asc&groupId=null&isForTransaction=1`,
        config,
      )

      let allItems = data.items

      if (data.totalItems > data.items.length) {
        const res = await axios.get(
          `${apiEndpoint}/api/v1/bussines/inventory?sortby=nameItem&sortdir=asc&size=${data.totalItems}&groupId=null&isForTransaction=1`,
          config,
        )
        allItems = res.data.items
      }

      const allMappedItems = allItems.map((e) => ({
        value: e.id,
        nameItem: e?.item?.nameItem,
        amount: e?.amount,
        label2: e?.item?.nameItem,
        unit: e?.unit ?? e?.item?.unit,
        id: e.id,
        img: e?.item?.linkFoto,
        sku: e?.item?.SKU,
      }))

      return allMappedItems
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getPackingDetails = createAsyncThunk(
  'sell/commodity/detailPackings',
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }

      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/inventorygroups/${id}`,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

// Export the functions
export {
  checkSellerInfo,
  createOrder,
  uploadFile,
  getComodities,
  getInventories,
  getPackings,
  getPackingDetails,
}
