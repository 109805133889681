import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiEndpoint } from 'configs'

const confirmTrx = createAsyncThunk(
  'confirm/trx/sell',
  async ({ status, amount, code }, { rejectWithValue }) => {
    try {
      let config = {
        headers: {
          Authorization: `d2hhdHNhcHBXZWJob29rOlBNWFlXQUlTYW5kQm94`,
          'Content-Type': 'application/json',
        },
      }
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/public/transaction-confirm?code=${code}&amount=${amount}&status=${status}`,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const releaseTrx = createAsyncThunk(
  'release/trx/sell',
  async ({ code }, { rejectWithValue }) => {
    try {
      let config = {
        headers: {
          Authorization: `d2hhdHNhcHBXZWJob29rOlBNWFlXQUlTYW5kQm94`,
          'Content-Type': 'application/json',
        },
      }
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/public/transaction-release?code=${code}`,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getDataTrx = createAsyncThunk(
  'get/trx/sell',
  async ({ amount, code }, { rejectWithValue }) => {
    try {
      let config = {
        headers: {
          Authorization: `d2hhdHNhcHBXZWJob29rOlBNWFlXQUlTYW5kQm94`,
          'Content-Type': 'application/json',
        },
      }
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/public/transaction?code=${code}&amount=${amount}`,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

// Export the functions
export { confirmTrx, getDataTrx, releaseTrx }
