import PMBSelect from 'components/PMBSelect'
import SidebarDetails from 'components/SidebarDetails'
import { WPP_OPTIONS } from 'constants'
import { useState } from 'react'
import { Calendar } from 'react-feather'
import Flatpickr from 'react-flatpickr'
import { useDispatch } from 'react-redux'
import { Button, FormGroup, Label } from 'reactstrap'

const SidebarFilter = ({
  open,
  toggle,
  handleFilter,
  selectedDate,
  setSelectedDate,
  handleSearchChange,
  selectedWPP,
  setSelectedWPP,
  setCurrentPage,
  setRowsPerPage,
}) => {
  const dispatch = useDispatch()

  const WPPOptions = [{ value: '', label: 'Semua' }, ...WPP_OPTIONS]

  const [tempSelectedDate, setTempSelectedDate] = useState(selectedDate)
  const [tempSelectedWPP, setTempSelectedWPP] = useState(selectedWPP)

  const currentYear = new Date().getFullYear()
  const startDateDefault = new Date(currentYear, 0, 1)
  const endDateDefault = new Date(currentYear, 11, 31)

  const handleReset = () => {
    setCurrentPage(0)
    setRowsPerPage(10)
    handleSearchChange('')
    setTempSelectedWPP(null)
    setSelectedWPP(null)
    setTempSelectedDate([startDateDefault, endDateDefault])
    setSelectedDate([startDateDefault, endDateDefault])
    handleFilter()
  }

  const handleSubmit = () => {
    setCurrentPage(0)
    setRowsPerPage(10)
    setSelectedDate(tempSelectedDate)
    // setSelectedWPP(tempSelectedWPP) // this code for multiple wpp, currently just one wpp
    const wppNumbers = Array.isArray(tempSelectedWPP)
      ? tempSelectedWPP
          .map((item) => item.value.match(/\d+/)?.[0])
          .filter(Boolean)
      : tempSelectedWPP?.value.match(/\d+/)?.[0]

    setSelectedWPP(wppNumbers)
    toggle()
  }

  return (
    <SidebarDetails
      open={open}
      size="lg"
      title="Filter"
      headerClassName="mb-1 justify-content-between"
      contentClassName="py-0"
      toggleSidebar={toggle}
    >
      <div
        style={{ minHeight: '90vh' }}
        className="d-flex flex-column justify-content-between"
      >
        <div className="my-4">
          <FormGroup>
            <Label>Tanggal Mulai & Akhir</Label>
            <div className="d-flex align-items-center justify-content-center border rounded">
              <Calendar size={17} className="ms-3" />
              <Flatpickr
                options={{
                  mode: 'range',
                  dateFormat: 'd-m-Y',
                  defaultDate: tempSelectedDate,
                  onClose: (selectedDates) => {
                    setTempSelectedDate(selectedDates)
                  },
                }}
                className="form-control flat-picker bg-transparent border-0 shadow-none"
              />
            </div>
          </FormGroup>
          <FormGroup>
            <Label className="text-sm">WPP-RI</Label>
            <PMBSelect
              value={tempSelectedWPP}
              onChange={(e) => setTempSelectedWPP(e)}
              options={WPPOptions}
              placeholder="Pilih WPP"
            />
          </FormGroup>
        </div>
        <div className="d-flex gap-2">
          <Button onClick={handleReset} color="primary" outline block>
            Reset Filter
          </Button>
          <Button onClick={handleSubmit} color="primary" block>
            Terapkan
          </Button>
        </div>
      </div>
    </SidebarDetails>
  )
}

export default SidebarFilter
