// ** Third Party Components
import DataTable from 'react-data-table-component'
import LoadingAnimation from './LoadingAnimation'
import { ChevronDown } from 'react-feather'

export const customStyles = {
  headRow: {
    style: {
      color: '#667085',
      backgroundColor: '#F9FAFB',
    },
  },
  cells: {
    style: {},
  },
  rows: {
    style: {
      '&:hover': {
        backgroundColor: '#F9FAFB',
      },
    },
  },
}

export const TableComponent = ({ ...props }) => {
  return (
    <DataTable
      persistTableHead
      responsive
      customStyles={props?.customStyles ?? customStyles}
      progressComponent={<LoadingAnimation />}
      className={props?.className ?? 'border p-0 border-1 rounded-top'}
      sortIcon={<ChevronDown />}
      sortServer
      noDataComponent={
        <span className="my-1">
          Belum ada data. Buat transaksi untuk memulai.
        </span>
      }
      {...props}
    />
  )
}
