import ICThunder from 'assets/icons/thunder-icon.svg'
import CustomButton from 'components/Buttons/CustomButton'
import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalBody } from 'reactstrap'
import { useConfirmTransferMutation } from './transferInventoryApiSlice'
import { toggleModal, toggleSidebar } from './transferInventorySlice'

function ModalConfirmation({ data, handleCallback }) {
  const dispatch = useDispatch()
  const { isOpenModal } = useSelector((s) => s.transferInventory)
  const [errMsg, setErrMsg] = useState('')
  const [confirmTransfer, resulConfirmTransfer] = useConfirmTransferMutation()

  const handleConfirm = async (id) => {
    try {
      const result = await confirmTransfer({ id: id, body: 1 })
      if (result?.error) {
        throw result
      } else {
        dispatch(toggleModal())
        dispatch(toggleSidebar())
        enqueueSnackbar('Berhasil dikonfirmasi.', { variant: 'success' })
        handleCallback()
      }
    } catch (error) {
      setErrMsg(error?.error?.data?.message ?? error?.message)
    }
  }

  return (
    <Modal
      isOpen={isOpenModal}
      toggle={() => dispatch(toggleModal())}
      centered
      style={{ maxWidth: '400px' }}
    >
      <ModalBody>
        <div>
          <div className="text-center">
            <img src={ICThunder} alt="icon" width={48} height={48} />
            <h5 className="fw-bold text-md text-center py-3">
              Terima inventori ini?
            </h5>
          </div>
          {errMsg && (
            <div>
              <small className="text-danger">
                <AlertCircle size={15} /> {errMsg}
              </small>
            </div>
          )}
        </div>
        <div className="d-flex gap-2 w-100">
          <CustomButton
            color="secondary"
            onClick={() => {
              dispatch(toggleModal())
            }}
            block
            size="sm"
            className="p-2"
            disabled={resulConfirmTransfer?.isLoading}
          >
            <span className="text-normal">Batal</span>
          </CustomButton>
          <CustomButton
            color="primary"
            onClick={() => {
              handleConfirm(data?.id)
            }}
            block
            size="sm"
            className="p-2"
            disabled={resulConfirmTransfer?.isLoading}
          >
            <span className="text-normal">Ya, Lanjut</span>
          </CustomButton>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalConfirmation
