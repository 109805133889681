import pakTani from 'assets/icons/Pak-Tani.png'
import AmountInput from 'components/AmountInput'
import CustomButton from 'components/Buttons/CustomButton'
import { useEffect, useState } from 'react'
import {
  AlertCircle,
  ArrowLeft,
  Bookmark,
  Check,
  Edit2,
  Trash2,
} from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Spinner,
} from 'reactstrap'
import { URL_PIUTANG, URL_UTANG } from 'utils/pathUrl'
import { usePostReceivableMutation } from '../receivable-notes/receivableNotesApiSlice'
import {
  toggleBannerReceivable,
  toggleModalCategoryReceivable,
  toggleModalContactsReceivable,
  toggleModalDeleteReceivable,
} from '../receivable-notes/receivableNotesSlice'
import ModalCategory from './ModalCategory'
import ModalDelete from './ModalDelete'
import ModalSavedContacts from './ModalSavedContacts'
import {
  useGetCategoriesQuery,
  useLazyCheckPhoneNumberPayableQuery,
  usePostPayableMutation,
} from './payableNotesApiSlice'
import {
  setBannerMsg,
  toggleBanner,
  toggleModalCategory,
  toggleModalContacts,
  toggleModalDelete,
} from './payableNotesSlice'
import BreadCrumbs from 'components/breadcrumbs'
import { Can } from 'context/Acl'
import { BUSINESS_AR_AP, MANAGE } from 'utils/subjectActions'

const FormPayableNotes = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { state } = useLocation()
  const [payload, setPayload] = useState()
  const [amount, setAmount] = useState(null)
  const [errMsg, setErrMsg] = useState('')
  const [errMsgPhoneNumber, setErrMsgPhoneNumber] = useState('')
  const [category, setCategory] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [dataUser, setDataUser] = useState(null)
  const { ownerData } = useSelector((s) => s.dashboard)
  const { isOpenModalCategory, isOpenModalDelete, isOpenModalContacts } =
    useSelector((state) => state.payableNotes)
  const {
    isOpenModalCategory: isOpenModalCategoryReceivable,
    isOpenModalDelete: isOpenModalDeleteReceivable,
    isOpenModalContacts: isOpenModalContactsReceivable,
  } = useSelector((state) => state.receivableNotes)

  const { data: dataCategory, isLoading } = useGetCategoriesQuery(
    {
      type: state?.isReceivable ? 'receivable' : 'payable',
      active: 1,
      userId: ownerData?.userId,
    },
    { skip: !ownerData },
  )
  const [postPayable, result] = usePostPayableMutation()
  const [postReceivable, resultReceivable] = usePostReceivableMutation()
  const [checkPhoneNumber, res] = useLazyCheckPhoneNumberPayableQuery()

  const handleEditCategory = () => {
    if (!isEdit) {
      setIsEdit(true)
      setPayload((prev) => {
        return {
          ...prev,
          category: null,
        }
      })
    } else {
      setIsEdit(false)
    }
  }

  const handleCheckPhoneNumber = async () => {
    try {
      if (
        payload?.phoneNumber &&
        payload?.phoneNumber.length >= 6 &&
        payload?.phoneNumber.length < 16
      ) {
        const res = await checkPhoneNumber({
          search: payload?.phoneNumber,
          type: state?.isReceivable ? 'SELL' : 'BUY',
        })
        if (res?.error) {
          setErrMsg(res?.error?.data?.message)
        } else if (res?.data?.status === 'UNREGISTERED') {
          setErrMsgPhoneNumber(
            'Nomor tidak terdaftar. Kami hanya akan mencatat nama yang dimasukkan.',
          )
        } else {
          setDataUser(res?.data)
          setPayload((prev) => {
            return {
              ...prev,
              name: res?.data?.fullName,
            }
          })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = async () => {
    try {
      let body = {}
      if (state?.isReceivable) {
        body = {
          amount: Number(
            amount?.replace?.(/\./g, '')?.replace?.(/,/g, '.')?.slice?.(0, 15),
          ),
          notes: payload?.notes,
          categoryId: payload?.category?.id,
          payerName: payload?.name ?? payload?.relationName,
          ...((dataUser?.relationUserId ||
            dataUser?.status === 'REGISTERED') && {
            payerUserId: dataUser?.relationUserId ?? dataUser?.userId,
            payerPhoneNumber:
              payload?.phoneNumber ?? payload?.relationPhoneNumber,
          }),
          saveRelation: payload?.saveRelation,
        }
      } else {
        body = {
          payerUserId: ownerData?.userId,
          amount: Number(
            amount?.replace?.(/\./g, '')?.replace?.(/,/g, '.')?.slice?.(0, 15),
          ),
          notes: payload?.notes,
          accountReceiveName: payload?.name ?? payload?.relationName,
          ...((dataUser?.relationUserId ||
            dataUser?.status === 'REGISTERED') && {
            userId: dataUser?.relationUserId ?? dataUser?.userId,
            accountReceivePhoneNumber:
              payload?.phoneNumber ?? payload?.relationPhoneNumber,
          }),
          categoryId: payload?.category?.id,
          saveRelation: payload?.saveRelation,
        }
      }
      const resp = state?.isReceivable
        ? await postReceivable(body).unwrap()
        : await postPayable(body).unwrap()
      if (!resp?.data?.error) {
        navigate(state?.isReceivable ? URL_PIUTANG : URL_UTANG)
        dispatch(
          state?.isReceivable
            ? setBannerMsg('Piutang baru berhasil dicatat.')
            : setBannerMsg('Utang baru berhasil dicatat.'),
        )
        dispatch(
          state?.isReceivable ? toggleBannerReceivable() : toggleBanner(),
        )
      } else {
        throw resp
      }
    } catch (error) {
      setErrMsg(error?.data?.message ?? error?.message)
      console.log(error)
    }
  }

  const handleSelectedContact = (res) => {
    if (res?.relationUserId) {
      setDataUser(res)
      setPayload((prev) => {
        return {
          ...prev,
          ...res,
        }
      })
    } else {
      setDataUser(null)
      setPayload((prev) => {
        return {
          ...prev,
          ...res,
        }
      })
    }
  }

  useEffect(() => {
    setErrMsg('')
  }, [amount])

  return (
    <Container className="custom-container d-flex flex-column gap-3">
      <div className="d-flex align-items-start gap-2 pt-3">
        <BreadCrumbs
          breadCrumbActive={
            state?.isReceivable ? 'Catat Piutang' : 'Catat Utang'
          }
          breadCrumbParent={state?.isReceivable ? 'Piutang' : 'Utang'}
          breadCrumbParentLink={
            state?.isReceivable ? '/catatan-piutang' : '/catatan-utang'
          }
        />
      </div>
      <ModalCategory
        isReceivable={state?.isReceivable}
        isOpen={
          state?.isReceivable
            ? isOpenModalCategoryReceivable
            : isOpenModalCategory
        }
        toggle={() =>
          dispatch(
            state?.isReceivable
              ? toggleModalCategoryReceivable()
              : toggleModalCategory(),
          )
        }
        row={category}
      />
      <ModalSavedContacts
        isReceivable={state?.isReceivable}
        isOpen={
          state?.isReceivable
            ? isOpenModalContactsReceivable
            : isOpenModalContacts
        }
        toggle={() =>
          dispatch(
            state?.isReceivable
              ? toggleModalContactsReceivable()
              : toggleModalContacts(),
          )
        }
        cb={handleSelectedContact}
      />{' '}
      <ModalDelete
        isReceivable={state?.isReceivable}
        isOpen={
          state?.isReceivable ? isOpenModalDeleteReceivable : isOpenModalDelete
        }
        toggle={() =>
          dispatch(
            state?.isReceivable
              ? toggleModalDeleteReceivable()
              : toggleModalDelete(),
          )
        }
        row={category}
      />
      <Card className="border-0 shadow-sm">
        <CardBody>
          <h5 className="fw-semibold">
            <ArrowLeft
              style={{ cursor: 'pointer' }}
              onClick={() =>
                navigate(state?.isReceivable ? URL_PIUTANG : URL_UTANG)
              }
            />{' '}
            Catat {state?.isReceivable ? 'Piutang' : 'Utang'}
          </h5>

          <Label className="fw-500 d-flex justify-content-between mt-4">
            <span>
              Kategori<span className="text-danger">*</span>
            </span>
            <Can I={MANAGE} this={BUSINESS_AR_AP}>
              <span
                onClick={() => handleEditCategory()}
                className={isEdit ? 'text-success' : 'text-primary'}
                style={{ cursor: 'pointer' }}
              >
                {isEdit ? 'Selesai' : 'Atur Kategori'}
              </span>
            </Can>
          </Label>
          {isLoading ? (
            <div className="flex-centered">
              {' '}
              <Spinner />{' '}
            </div>
          ) : (
            dataCategory?.map((e, i) => {
              return (
                <div
                  key={i}
                  className="py-2"
                  onClick={() => {
                    if (!isEdit) {
                      setPayload((prev) => {
                        return {
                          ...prev,
                          category: e,
                        }
                      })
                    }
                  }}
                >
                  <div
                    style={{
                      backgroundColor:
                        payload?.category?.id === e?.id ? '#ECFAFF' : '',
                    }}
                    className={`border rounded p-3 d-flex ${isEdit ? 'justify-content-between' : 'gap-2'} ${payload?.category?.id === e?.id ? 'border-primary' : ''}`}
                  >
                    {isEdit ? (
                      <></>
                    ) : (
                      <Input
                        type="radio"
                        id="category"
                        name="category"
                        value={payload?.category?.id ?? ''}
                        onChange={() => {
                          if (!isEdit) {
                            setPayload((prev) => {
                              return {
                                ...prev,
                                category: e,
                              }
                            })
                          }
                        }}
                        checked={payload?.category?.id === e?.id}
                        autoComplete="off"
                      />
                    )}
                    {e?.label}
                    {isEdit ? (
                      <div className="d-flex gap-3">
                        <Edit2
                          onClick={() => {
                            setCategory(e)
                            dispatch(
                              state?.isReceivable
                                ? toggleModalCategoryReceivable()
                                : toggleModalCategory(),
                            )
                          }}
                          style={{ cursor: 'pointer' }}
                          size={16}
                        />
                        <Trash2
                          onClick={() => {
                            setCategory(e)
                            dispatch(
                              state?.isReceivable
                                ? toggleModalDeleteReceivable()
                                : toggleModalDelete(),
                            )
                          }}
                          style={{ cursor: 'pointer' }}
                          size={16}
                          className="text-danger"
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              )
            })
          )}
          <hr></hr>
          {isEdit ? (
            <CustomButton
              onClick={() => {
                setCategory(null)
                dispatch(
                  state?.isReceivable
                    ? toggleModalCategoryReceivable()
                    : toggleModalCategory(),
                )
              }}
              outline
              color="primary"
            >
              + Tambah Kategori
            </CustomButton>
          ) : (
            <></>
          )}
        </CardBody>
      </Card>
      <Card className="border-0 shadow-sm">
        <CardBody>
          <div className="d-flex justify-content-between align-items-center pb-3">
            <span className="fw-600">
              {state?.isReceivable ? 'Piutang' : 'Utang'} kepada{' '}
            </span>
            <CustomButton
              onClick={() =>
                dispatch(
                  state?.isReceivable
                    ? toggleModalContactsReceivable()
                    : toggleModalContacts(),
                )
              }
              outline
              color="primary"
              size="sm"
            >
              {' '}
              <Bookmark size={20} /> Daftar Tersimpan
            </CustomButton>
          </div>
          <FormGroup>
            <Label>
              Nama
              <span className="text-danger">*</span>
            </Label>
            <Input
              id="name"
              name="name"
              placeholder="Masukkan nama"
              value={payload?.relationName ?? payload?.name ?? ''}
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target?.value,
                  relationName: null,
                  relationPhoneNumber: null,
                }))
                setDataUser(null)
                setErrMsg('')
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Nomor handphone (Jika Pengguna PasarMIKRO)</Label>
            <Input
              placeholder="Masukkan nomor handphone"
              value={payload?.relationPhoneNumber ?? payload?.phoneNumber ?? ''}
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                  relationName: null,
                  relationPhoneNumber: null,
                }))
                setErrMsg('')
                setErrMsgPhoneNumber('')
                setDataUser(null)
              }}
              name="phoneNumber"
              id="phoneNumber"
              onBlur={handleCheckPhoneNumber}
            />
          </FormGroup>
          {dataUser ? (
            <FormGroup>
              <div className="flex-centered justify-content-between border p-2 rounded bg-success-25 border-success">
                <div className="flex-centered gap-2">
                  <img
                    src={pakTani}
                    alt="ava"
                    width={36}
                    height={35}
                    className="rounded-circle"
                  />
                  <span className="text-sm fw-semibold">
                    {dataUser?.fullName ?? dataUser?.relationName}
                  </span>
                </div>
                <>
                  <Check className="text-success" />
                </>
                <Badge size="sm" disabled className={'badge-warning'}></Badge>
              </div>
            </FormGroup>
          ) : (
            <></>
          )}
          {errMsgPhoneNumber && (
            <FormGroup>
              <small className="text-warning-700 fw-500">
                <AlertCircle size={15} /> {errMsgPhoneNumber}
              </small>
            </FormGroup>
          )}
          <FormGroup style={{ opacity: payload?.relationName ? '60%' : '' }}>
            <Input
              id="saveRelation"
              type="checkbox"
              name="saveRelation"
              value={payload?.saveRelation ?? ''}
              checked={payload?.saveRelation === 1}
              disabled={payload?.relationName}
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: 1,
                }))
              }}
            />{' '}
            <small>Simpan Ke Daftar</small>
          </FormGroup>
        </CardBody>
      </Card>
      <Card className="border-0 shadow-sm mb-3">
        <CardBody>
          <FormGroup>
            <Label className="fw-500">
              Jumlah {state?.isReceivable ? 'piutang' : 'utang'}
              <span className="text-danger">*</span>
            </Label>

            <InputGroup>
              <InputGroupText className="bg-transparent">Rp</InputGroupText>
              <AmountInput
                cb={(res) => {
                  setAmount(res)
                }}
                placeholder="0"
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label className="fw-500">Catatan</Label>
            <Input
              type="textarea"
              style={{ minHeight: '128px' }}
              name="notes"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target?.value,
                }))
              }}
              value={payload?.notes}
              placeholder="Tambah catatan jika ada..."
            />
          </FormGroup>
          {errMsg && (
            <FormGroup>
              <small className="text-danger">
                <AlertCircle size={15} /> {errMsg}
              </small>
            </FormGroup>
          )}
          <div className="d-flex gap-3">
            <Button
              onClick={() =>
                navigate(state?.isReceivable ? URL_PIUTANG : URL_UTANG)
              }
              outline
              color="primary"
              block
            >
              Batal
            </Button>
            <Can I={MANAGE} this={BUSINESS_AR_AP}>
              <CustomButton
                onClick={() => handleSubmit()}
                disabled={
                  !payload ||
                  !payload?.category ||
                  !amount ||
                  result?.isLoading ||
                  resultReceivable?.isLoading ||
                  !(payload?.name || payload?.relationName) ||
                  res?.isLoading ||
                  res?.status === 'pending'
                }
                block
                color="primary"
              >
                Simpan
              </CustomButton>
            </Can>
          </div>
        </CardBody>
      </Card>
    </Container>
  )
}

export default FormPayableNotes
