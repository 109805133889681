import PMBSelect from 'components/PMBSelect'
import { TableComponent as DataTable } from 'components/TableComponent'
import Chart from 'react-apexcharts'

const AnnualTab = ({
  yearOptionsAnnual,
  selectedYearAnnual,
  dataHandline,
  dataPoleLine,
  loading,
  handleYearChangeAnnual,
  columnsAnnual,
  dataResultAnnual,
  options,
  series,
}) => {
  return (
    <div>
      <div className="d-flex flex-column flex-sm-row align-items-start justify-content-md-between">
        <h5 className="mb-0">Summary Tangkapan Tahunan</h5>
        <div style={{ width: 300 }}>
          <PMBSelect
            value={selectedYearAnnual}
            onChange={handleYearChangeAnnual}
            options={yearOptionsAnnual}
            isMulti={false}
            placeholder="Pilih tahun"
          />
        </div>
      </div>
      <div
        style={{
          overflowX: 'auto',
          width: '100%',
        }}
        className="no-scrollbar"
      >
        <div style={{ minWidth: '1500px' }}>
          <Chart options={options} height={400} series={series} />
        </div>
      </div>
      {/* TABLE */}
      <div style={{ marginTop: '24px' }}>
        <DataTable
          columns={columnsAnnual}
          data={dataResultAnnual}
          progressPending={loading}
          pagination={false}
          responsive
        />
      </div>
    </div>
  )
}

export default AnnualTab
