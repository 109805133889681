import CustomButton from 'components/Buttons/CustomButton'
import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { FormGroup, Modal, ModalBody } from 'reactstrap'
import { toggleModalChangeProfile } from '../detail/detailSlice'
import {
  useLazyFetchFarmersDetailQuery,
  useLazyFetchFarmersQuery,
} from '../farmers-list/farmersListApiSlice'
import {
  useChangeProfileMutation,
  useRegisterFarmerMutation,
  useRegisterFarmerV2Mutation,
} from './formApiSlice'
import { toggleModal } from './formSlice'

const ModalConfirmation = ({ row }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const dataState = location.state
  const { isOpenModal, payload } = useSelector((s) => s.farmersForm)
  const { tag } = useSelector((s) => s.farmersList)
  let [page, setPage] = useState({ page: tag.page })
  const [register, result] = useRegisterFarmerMutation()
  const [registerV2, resultV2] = useRegisterFarmerV2Mutation()
  const [editProfile, res] = useChangeProfileMutation()
  const [fetchDetailFarmers] = useLazyFetchFarmersDetailQuery()
  const [fetchFarmers] = useLazyFetchFarmersQuery()
  const [fetchPendingFarmers] = useLazyFetchFarmersQuery()
  const [errMsg, setErrMsg] = useState('')

  const handleSubmit = async () => {
    try {
      const obj = {
        ...payload,
        province: payload?.province?.value,
        city: payload?.city?.value,
        district: payload?.district?.value,
        subDistrict: payload?.subDistrict?.value,
        postalCode: payload?.postalCode?.value,
        groupCode: row ? row?.groupCode : dataState?.code,
      }

      const res = row
        ? await editProfile({ payload: obj, id: row?.userId })
        : await registerV2(obj)
      if (res?.error) {
        setErrMsg(res?.error?.data?.meta?.message)
      } else if (!row) {
        fetchFarmers({
          page: page?.page,
          status: 'JOIN',
          groupCode: dataState?.cod,
        })
        fetchPendingFarmers({
          page: page?.page,
          status: 'PENDING',
          groupCode: dataState?.code,
        })
        navigate(`/farmers/groups/${dataState?.code}`)
        enqueueSnackbar('Anggota berhasil ditambahkan.', { variant: 'success' })
      } else if (row) {
        fetchDetailFarmers({ id: row?.userId })
        dispatch(toggleModal())
        dispatch(toggleModalChangeProfile())
        enqueueSnackbar('Data akun berhasil diubah.', { variant: 'success' })
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal
      className="p-4"
      centered
      isOpen={isOpenModal}
      toggle={() => {
        dispatch(toggleModal())
        setErrMsg('')
      }}
    >
      <ModalBody>
        <h6>Konfirmasi {row ? 'Pengubahan' : 'Pendaftaran'}</h6>
        <p className="text-muted">
          Pastikan semua data yang dimasukkan sudah benar dan sesuai.
        </p>
        {errMsg && (
          <FormGroup>
            <small className="text-danger">
              <AlertCircle size={15} /> {errMsg}
            </small>
          </FormGroup>
        )}
        <div className="d-flex gap-3">
          <CustomButton
            onClick={() => {
              dispatch(toggleModal())
              setErrMsg('')
            }}
            color="primary"
            className="btn-cancel"
            outline
            block
          >
            Batal
          </CustomButton>
          <CustomButton
            disabled={resultV2?.isLoading}
            onClick={handleSubmit}
            color="primary"
            block
          >
            {row ? 'Ubah' : 'Daftarkan'}
          </CustomButton>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalConfirmation
