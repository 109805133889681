import BreadCrumbs from 'components/breadcrumbs'
import PMBSelect from 'components/PMBSelect'
import { useSnackbar } from 'notistack'
import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  Container,
  FormGroup,
  Input,
  Label,
} from 'reactstrap'
import {
  useCreationFisheriesMutation,
  useFetchAllBaitSpeciesQuery,
  useFetchAllCompaniesQuery,
  useFetchAllGearsQuery,
  useFetchAllSpeciesQuery,
  useUpdateFisheriesMutation,
} from './formApiSlice'
import { setPayloadSlice } from './formSlice'
import './style.css'

const FormAddFisheries = ({
  row,
  onHandleOpenCloseModalEdit,
  handleRefetch,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // const { tag } = useSelector((s) => s.fisheriesList)

  const [creationFishery, { isLoading: isLoadingCreate }] =
    useCreationFisheriesMutation()
  const [updateFishery, { isLoading: isLoadingUpdate }] =
    useUpdateFisheriesMutation()

  const { enqueueSnackbar } = useSnackbar()

  const [payload, setPayload] = useState({})
  const [isClick, setIsClick] = useState(false)
  const [searchCompany, setSearchCompany] = useState('')
  const [selectedSpecies, setSelectedSpecies] = useState([])
  const [selectedBaitSpecies, setSelectedBaitSpecies] = useState([])
  const [selectedGear, setSelectedGear] = useState(null)
  const [supportingOffices, setSupportingOffices] = useState([{}])

  // GET ALL SPECIES
  const { data: dataSpecies } = useFetchAllSpeciesQuery()
  // GET ALL BAIT SPECIES
  const { data: dataBaitSpecies } = useFetchAllBaitSpeciesQuery()
  // GET ALL GEARS
  const { data: dataGears } = useFetchAllGearsQuery()
  // GET DATA COMPANIES
  const { data: dataCompanies, refetch: refetchCompanies } =
    useFetchAllCompaniesQuery({
      page: 0,
      size: 50,
      searchvalue: searchCompany,
    })

  // HANDLE FOR SEARCHING COMPANY
  const handleCompanySearch = (text) => {
    if (text?.trim() !== '' && text?.length > 3) {
      setSearchCompany(text)
      refetchCompanies()
    } else {
      setSearchCompany('')
      refetchCompanies()
    }
  }

  // HANDLE FOR ADD NEW COMPANY
  const handleAddSupportingOffice = () => {
    setSupportingOffices([...supportingOffices, {}])
  }

  // HANDLE CHANGES ON DROPDOWN OPTION BASED ON INDEX
  const handleSupportingOfficeChange = (selectedOption, index) => {
    const updatedOffices = [...supportingOffices]
    updatedOffices[index] = selectedOption
    setSupportingOffices(updatedOffices)
  }

  // REMOVE COMPANY BASED ON INDEX
  const handleRemoveSupportingOffice = (index) => {
    const updatedOffices = [...supportingOffices]
    updatedOffices.splice(index, 1)
    setSupportingOffices(updatedOffices)
  }

  // VALIDATE ALREADY SELECTED OR NOT
  const validateSupportingOffices = () => {
    return supportingOffices.some((office) => !office?.value)
  }

  // FILTER REMOVE FROM OPTIONS IF THE DATA ALREADY SELECTED
  const getFilteredOptions = () => {
    const selectedCompanyNames = supportingOffices
      .map((office) => office?.value)
      .filter(Boolean)
    return (
      dataCompanies?.items?.filter(
        (company) => !selectedCompanyNames.includes(company.name),
      ) || []
    )
  }

  const handleHasAnswered = () => {
    const fieldRequireds = ['fisheryCode', 'fisheryName']

    const fieldRequired = fieldRequireds

    const data = fieldRequired?.map((e) => {
      return Object.prototype.hasOwnProperty.call(payload, e) && payload[e]
    })
    return data.every((e) => e)
  }

  const isEdit = row?.id

  const handleSubmit = async () => {
    try {
      if (
        !selectedSpecies?.length ||
        !selectedBaitSpecies?.length ||
        !selectedGear ||
        supportingOffices.length === 0 ||
        supportingOffices.every((office) => Object.keys(office).length === 0)
      ) {
        enqueueSnackbar('Semua kolom wajib diisi!', { variant: 'error' })
        return
      }

      const adjustedPayload = {
        code: payload.fisheryCode ?? null,
        name: payload.fisheryName ?? null,
        targetSpecies: selectedSpecies?.length
          ? selectedSpecies?.map((item) => item?.value)?.join(',')
          : null,
        baitsList: selectedBaitSpecies?.length
          ? selectedBaitSpecies?.map((item) => item?.value)
          : null,
        gears: selectedGear?.value ?? null,
        supportingOffice: supportingOffices?.length
          ? supportingOffices?.map((item) => item?.value)?.join(',')
          : null,
      }

      const result = row
        ? await updateFishery({ payload: adjustedPayload, id: row?.id })
        : await creationFishery(adjustedPayload)

      if (result?.error) {
        enqueueSnackbar(`${isEdit ? 'Ubah' : 'Tambah'} fishery gagal!`, {
          variant: 'error',
        })
      } else if (!result?.error) {
        if (isEdit) {
          onHandleOpenCloseModalEdit()
        }

        if (handleRefetch) handleRefetch()

        enqueueSnackbar(
          `Fishery berhasil ${isEdit ? 'dirubah' : 'ditambahkan'}!`,
          {
            variant: 'success',
          },
        )

        navigate('/fisheries')
      }
    } catch (error) {
      console.warn(error)
      enqueueSnackbar(`Fishery gagal ${isEdit ? 'dirubah' : 'ditambahkan'}!`, {
        variant: 'error',
      })
    }
  }

  useMemo(() => {
    if (row) {
      // SET SPECIES
      const speciesArray = row?.targetSpecies?.split(',')?.map((species) => ({
        value: species,
        label: species,
      }))
      setSelectedSpecies(speciesArray || [])
      // SET BAIT SPECIES
      const baitSpeciesArray = row?.baitsList?.map((item) => ({
        value: item?.baitData?.id,
        label: `${item?.baitData?.code} - ${item?.baitData?.name}`,
      }))
      setSelectedBaitSpecies(baitSpeciesArray || [])
      // SET GEARS
      setSelectedGear({
        value: row?.gears,
        label: row?.gears,
      })
      // SET SUPPORTING OFFICE
      const officesArray = row?.supportingOffice?.split(',')?.map((office) => ({
        value: office,
        label: office,
      }))
      setSupportingOffices(officesArray)

      setPayload(() => ({
        fisheryCode: row?.code ?? '',
        fisheryName: row?.name ?? '',
        // baitSpecies: row?.baitSpecies ?? '',
      }))
    }
  }, [row])

  // SPECIES OPTIONS
  const speciesOptions =
    dataSpecies?.items?.map((species) => ({
      value: species.attribute.code,
      label: species.attribute.code,
    })) || []

  // BAITS SPECIES OPTIONS
  const baitSpeciesOptions =
    dataBaitSpecies?.items?.map((item) => ({
      value: item?.id,
      label: `${item?.code} - ${item?.name}`,
    })) || []

  // GEARS OPTIONS
  const gearsOptions =
    dataGears?.items?.map((gear) => ({
      value: gear.name,
      label: gear.name,
    })) || []

  const handleSpeciesChange = (selectedOptions) => {
    setSelectedSpecies(selectedOptions)
  }

  const handleBaitSpeciesChange = (selectedOptions) => {
    setSelectedBaitSpecies(selectedOptions)
  }

  const handleGearsChange = (selectedOptions) => {
    setSelectedGear(selectedOptions)
  }

  return (
    <Container className="custom-container d-flex flex-column gap-3 py-3">
      {!isEdit && (
        <div className="mt-3">
          <BreadCrumbs
            breadCrumbParent2="Data Fishery"
            breadCrumbParent2Link={'/fisheries'}
            breadCrumbActive="Tambah Fishery"
          />
        </div>
      )}

      <Card className="border-0 shadow-sm">
        <CardBody className="d-flex flex-column gap-2">
          <h5 className="mb-2">{isEdit ? 'Ubah Fishery' : 'Tambah Fishery'}</h5>

          <FormGroup>
            <Label className="text-sm">Fishery Code</Label>
            <Input
              placeholder="Masukkan fishery code"
              autoComplete="off"
              name="fisheryCode"
              id="fisheryCode"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }))
              }}
              value={payload?.fisheryCode ?? ''}
              invalid={isClick && !payload?.fisheryCode}
            />
          </FormGroup>
          <FormGroup>
            <Label className="text-sm">Fishery Name</Label>
            <Input
              placeholder="Masukkan fishery name"
              autoComplete="off"
              name="fisheryName"
              id="fisheryName"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value?.toUpperCase(),
                }))
              }}
              value={payload?.fisheryName ?? ''}
              invalid={isClick && !payload?.fisheryName}
            />
          </FormGroup>
          <FormGroup>
            <Label className="text-sm">Target Species</Label>
            <PMBSelect
              value={selectedSpecies}
              onChange={handleSpeciesChange}
              options={speciesOptions}
              isMulti={true}
              isInvalid={isClick && selectedSpecies.length === 0}
              placeholder="Pilih target species"
            />
          </FormGroup>
          <FormGroup>
            <Label className="text-sm">Bait Species</Label>
            <PMBSelect
              value={selectedBaitSpecies}
              onChange={handleBaitSpeciesChange}
              options={baitSpeciesOptions}
              isMulti={true}
              isInvalid={isClick && selectedBaitSpecies.length === 0}
              placeholder="Pilih target species"
            />
          </FormGroup>
          {/* <FormGroup>
            <Label className="text-sm">Bait Species</Label>
            <Input
              placeholder="Masukkan bait species"
              autoComplete="off"
              name="baitSpecies"
              id="baitSpecies"
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }))
              }}
              value={payload?.baitSpecies ?? ''}
              invalid={isClick && !payload?.baitSpecies}
            />
          </FormGroup> */}
          <FormGroup>
            <Label className="text-sm">Gear</Label>
            <PMBSelect
              value={selectedGear}
              onChange={handleGearsChange}
              options={gearsOptions}
              isInvalid={isClick && !selectedGear}
              placeholder="Pilih gear yang digunakan"
            />
          </FormGroup>

          {/* SUPPORTING OFFICE */}
          <FormGroup>
            <Label className="text-sm">Supporting Office</Label>
            {supportingOffices?.map((office, index) => (
              <div
                key={index}
                className="d-flex align-items-center justify-content-between mb-2"
              >
                <div style={{ flexGrow: 1 }}>
                  <PMBSelect
                    value={office}
                    onChange={(selectedOption) =>
                      handleSupportingOfficeChange(selectedOption, index)
                    }
                    options={getFilteredOptions()?.map((company) => ({
                      value: company.name,
                      label: company.name,
                    }))}
                    onInputChange={handleCompanySearch}
                    placeholder="Pilih perusahaan..."
                    isInvalid={isClick && !office?.value}
                  />
                </div>
                <Button
                  color="danger"
                  className="ms-2"
                  onClick={() => handleRemoveSupportingOffice(index)}
                  disabled={supportingOffices.length === 1}
                >
                  X
                </Button>
              </div>
            ))}

            {/* ADD NEW SUPPORTING OFFICE */}
            <Button
              color="primary"
              onClick={handleAddSupportingOffice}
              className="mt-2 text-sm"
              disabled={validateSupportingOffices()}
            >
              Tambah Supporting Office
            </Button>
          </FormGroup>
        </CardBody>
      </Card>

      <span className="text-sm fw-semibold">
        Dengan {isEdit ? 'merubah' : 'mendaftarkan'} fishery, saya menjamin
        kebenaran data yang dimasukkan dan bertanggung jawab atas
        ketidaksesuaian data.
      </span>

      <div className="d-flex gap-3">
        {!row && (
          <Button
            onClick={() => navigate('/fisheries')}
            outline
            color="primary"
            block
          >
            Batal
          </Button>
        )}
        <Button
          disabled={isLoadingCreate || isLoadingUpdate}
          onClick={() => {
            const res = handleHasAnswered()
            setIsClick(true)
            if (res) {
              setIsClick(false)
              dispatch(setPayloadSlice(payload))
              handleSubmit()
            }
          }}
          color="primary"
          block
        >
          Simpan
        </Button>
      </div>
    </Container>
  )
}

export default FormAddFisheries
