import LogoUangBelanja from 'assets/icons/uang-belanja-wallet.png'
import CustomButton from 'components/Buttons/CustomButton'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import { Card, CardBody, CardHeader, CardTitle, Container } from 'reactstrap'
import { URL_406 } from 'utils/pathUrl'
import ModalPasswordInvoice from '../ModalPasswordInvoice'
import { getDetailRollover } from '../talanginInvoiceAction'
import { toggleSidebarTalangin } from '../talanginInvoiceSlice'
import FooterRollover from './FooterRollover'
import { formatDateOnlyPMB } from 'utils/formatDate'

const ConfirmRollover = ({ action, subject }) => {
  const abilityCreate = useAbilityAccess({ action, subject })

  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false)
  const { balance } = useSelector((s) => s.dashboard)
  const { selectedRow, detailRollover, selectedTenor } = useSelector(
    (s) => s.talanginInvoice,
  )
  const toggleModal = () => {
    setOpenModal(!openModal)
  }

  const totalAmountToPay =
    detailRollover?.creditVersion === 2
      ? detailRollover?.rolloverCost?.totalFee + selectedTenor?.margin
      : detailRollover?.rolloverCost?.totalFee

  const dueDateFee = selectedTenor?.margin

  const newAmountToPay = selectedTenor?.amountPlusInterest

  const adminFee = detailRollover?.rolloverCost?.totalFee

  useEffect(() => {
    if (!selectedRow) {
      dispatch(toggleSidebarTalangin())
      navigate('/tagihan-talangin')
    } else {
      dispatch(
        getDetailRollover({
          userpinjamanId: selectedRow?.escrowpayment?.userpinjamanId,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow])

  if (!abilityCreate) {
    return <Navigate to={URL_406} replace={true} />
  }

  return (
    <div className="w-100 d-flex flex-column justify-content-between">
      <ModalPasswordInvoice
        isOpen={openModal}
        toggle={toggleModal}
        isRollover={true}
      />

      <Container className="custom-container p-3">
        <Card className="border-0 shadow-sm">
          <CardHeader
            className="p-4"
            style={{
              backgroundColor:
                balance?.uangBelanja < totalAmountToPay ? '#FFFBFA' : '#ECFAFF',
            }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div>
                  <img
                    alt="Uang Belanja"
                    src={LogoUangBelanja}
                    style={{ maxWidth: '50%', maxHeight: '50%' }}
                  />
                </div>
                <div className="d-flex flex-column">
                  <span>
                    Uang Belanja: Rp
                    {intl.formatNumber(balance?.uangBelanja ?? 0, {
                      useGrouping: 'always',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </span>
                  {balance?.uangBelanja < totalAmountToPay && (
                    <span className="text-danger">
                      (Kurang Rp
                      {intl.formatNumber(
                        Math.abs(balance?.uangBelanja - totalAmountToPay) ?? 0,
                        {
                          useGrouping: 'always',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        },
                      )}
                      )
                    </span>
                  )}
                </div>
              </div>
              {balance?.uangBelanja < totalAmountToPay && (
                <div>
                  <CustomButton
                    size="sm"
                    style={{ backgroundColor: 'white', color: 'black' }}
                    onClick={() =>
                      navigate('/topup', { state: { rollover: true } })
                    }
                  >
                    Tambah
                  </CustomButton>
                </div>
              )}
            </div>
          </CardHeader>
          <CardBody className="p-4">
            <CardTitle className="fw-semibold">
              Informasi perpanjangan
            </CardTitle>
            <div className="d-flex flex-column gap-3 mb-4">
              <div className="d-flex justify-content-between">
                <small>Tanggal jatuh tempo</small>
                <small className="fw-semibold">
                  {formatDateOnlyPMB(
                    moment(detailRollover?.dueDate).add(
                      selectedTenor?.loanDays,
                      'days',
                    ),
                  )}
                </small>
              </div>
              <div className="d-flex justify-content-between">
                <small>Total tagihan baru</small>
                <small className="fw-semibold">
                  Rp
                  {intl.formatNumber(newAmountToPay ?? 0, {
                    useGrouping: 'always',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </small>
              </div>
            </div>

            <CardTitle className="fw-semibold">Rincian pembayaran</CardTitle>
            <div className="d-flex flex-column gap-3">
              <div className="d-flex justify-content-between">
                <small>Biaya admin</small>
                <small className="fw-semibold">
                  Rp
                  {intl.formatNumber(adminFee ?? 0, {
                    useGrouping: 'always',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </small>
              </div>
              <div className="d-flex justify-content-between">
                <small>
                  Biaya jatuh tempo ({selectedTenor?.loanDays} hari)
                </small>
                <small className="fw-semibold">
                  {' '}
                  Rp
                  {intl.formatNumber(dueDateFee ?? 0, {
                    useGrouping: 'always',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </small>
              </div>
            </div>
            <hr></hr>
            <div className="d-flex justify-content-between">
              <small className="fw-semibold">Total pembayaran</small>
              <small className="fw-semibold">
                Rp
                {intl.formatNumber(totalAmountToPay ?? 0, {
                  useGrouping: 'always',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </small>
            </div>
          </CardBody>
        </Card>
      </Container>
      <FooterRollover
        text="Selesaikan Pembayaran"
        handleClick={() => {
          toggleModal()
        }}
        total={totalAmountToPay}
      />
    </div>
  )
}

export default ConfirmRollover
