import directTrx from 'assets/icons/transaksi-langsung.png'
import Sidebar from 'components/sidebar'
import { Card, CardBody } from 'reactstrap'

const SidebarConfirm = ({ open, toggleSidebar }) => {
  return (
    <Sidebar
      size="lg"
      open={open}
      title={'Detail Transaksi'}
      headerClassName="mb-1"
      contentClassName="pt-0"
      toggleSidebar={toggleSidebar}
    >
      <Card className="border-0 shadow-sm">
        <CardBody>
          <div>
            <img alt="Transaksi Langsung" src={directTrx} />
          </div>
        </CardBody>
      </Card>
    </Sidebar>
  )
}

export default SidebarConfirm
