import successTrx from 'assets/images/success-trx.png'
import CustomButton from 'components/Buttons/CustomButton'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import { Navigate } from 'react-router-dom'
import { Card, CardBody, CardText, Container } from 'reactstrap'
import { URL_406 } from 'utils/pathUrl'
import { BUSINESS_TRANSACTION_SELL, CREATE, MAKE } from 'utils/subjectActions'

const SuccessTrx = () => {
  const createSell = useAbilityAccess({
    action: CREATE,
    subject: BUSINESS_TRANSACTION_SELL,
  })
  const makeSell = useAbilityAccess({
    action: MAKE,
    subject: BUSINESS_TRANSACTION_SELL,
  })

  const handleClick = () => {
    window.location.href = '/'
  }

  if (createSell || makeSell) {
    return (
      <Container fluid className="custom-container mt-5">
        <Card className="border-0 shadow-sm">
          <CardBody>
            <div className="d-flex justify-content-center">
              <img
                style={{ width: '280px', height: '182px' }}
                alt="success transaction"
                src={successTrx}
              />
            </div>
            <div className="w-75 mx-auto my-3">
              <CardText tag="p" className="text-center">
                Transaksi akan dilanjutkan jika pembeli sudah mengkonfirmasi dan
                melakukan pembayaran.
              </CardText>
            </div>
            <div className="d-flex justify-content-center">
              <CustomButton onClick={handleClick} color="primary">
                Kembali ke Beranda
              </CustomButton>
            </div>
          </CardBody>
        </Card>
      </Container>
    )
  }
  return <Navigate to={URL_406} replace={true} />
}

export default SuccessTrx
