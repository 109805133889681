import { useRef, useState } from 'react'
import { FilePlus } from 'react-feather'
import { Card, CardText, Col, Row, Spinner } from 'reactstrap'
import { useUploadRepoDocMutation } from '../commodityRepoApiSlice'
import CardPDF from '../components/CardPDF'
import RepoCommodityForm from './RepoCommodityForm'

const SubmissionRepo = () => {
  const [upload, { isLoading }] = useUploadRepoDocMutation()

  const fileInputRef = useRef(null)
  const [fileNames, setFileNames] = useState([])
  const [filesUpload, setFilesUpload] = useState([])
  const [errorMessage, setErrorMessage] = useState([])
  const [invoiceLinks, setInvoiceLinks] = useState([])

  const handleFileChange = async (e) => {
    let tempName = {}
    let tempFile = {}

    if (fileNames.length > 2) {
      return setErrorMessage('Dokumen melebihi batas maksimum unggahan')
    }

    let isDuplicateSize = fileNames.some((d) => d.id === e.target.files[0].size)
    let isDuplicateFName = fileNames.some(
      (d) => d.name === e.target.files[0].name,
    )

    if (isDuplicateSize && isDuplicateFName) {
      setErrorMessage('Dokumen sama telah diupload')
    } else {
      for (let i = 0; i < e.target.files.length; i++) {
        tempName = { id: e.target.files[i].size, name: e.target.files[i].name }
        tempFile = { id: e.target.files[i].size, file: e.target.files[i] }
      }
      try {
        let data = await upload(tempFile.file).unwrap()
        setInvoiceLinks((prev) => [
          ...prev,
          {
            ...data,
            id: tempFile?.id,
          },
        ])
        setFileNames((prev) => [...prev, tempName])
        setFilesUpload((prev) => [...prev, tempFile])
      } catch (error) {
        console.log(error)
      }
    }
    e.target.value = null
  }

  return (
    <>
      <Card
        body
        className="mb-3 mt-0 border-0 shadow-sm"
        style={{ padding: 32 }}
      >
        <CardText className="text-md fw-bold">Dokumen Repo</CardText>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
          accept="application/pdf"
          disabled={isLoading}
        />
        <div
          className="d-flex flex-column text-wrap w-auto"
          onClick={() =>
            fileNames.length > 2
              ? console.log('')
              : fileInputRef.current.click()
          }
          style={{
            cursor:
              fileNames.length > 2 || isLoading ? 'not-allowed' : 'pointer',
          }}
        >
          <div
            className="border border-1 rounded mb-1"
            style={{ padding: '12px 20px' }}
          >
            <FilePlus
              size={18}
              className="me-2"
              style={{
                color:
                  fileNames.length > 2 || isLoading ? '#D0D5DD' : '#101828',
              }}
            />
            <span
              className="text-sm fw-bold"
              style={{
                color:
                  fileNames.length > 2 || isLoading ? '#D0D5DD' : '#101828',
              }}
            >
              {isLoading ? (
                <>
                  <Spinner size="sm" className="ms-2" /> Uploading ...
                </>
              ) : (
                'Unggah Dokumen PO, Invoice, Atau Yang Berkaitan Dengan Repo'
              )}
            </span>
          </div>
          <span className="text-sm">
            Jenis file yang bisa berupa PDF. Maksimal 3 dokumen
          </span>
        </div>

        <Row>
          {fileNames?.map((data, i) => {
            return (
              <Col key={i} lg={4} md={6} sm={12} className="my-1">
                <CardPDF
                  name={data?.name}
                  callBack={(e) => {
                    let dataName = fileNames.filter((data) => data.id !== e)
                    let dataFile = filesUpload.filter((data) => data.id !== e)
                    let dataFinishUpload = invoiceLinks.filter(
                      (data) => data.id !== e,
                    )
                    setFilesUpload(dataFile)
                    setFileNames(dataName)
                    setInvoiceLinks(dataFinishUpload)
                  }}
                  fileIndex={data?.id}
                />
              </Col>
            )
          })}
        </Row>
      </Card>
      <RepoCommodityForm invoiceLinks={invoiceLinks} />
    </>
  )
}

export default SubmissionRepo
