import { createApiSlice } from 'app/createApiSlice'
import { API_WAREHOUSE } from 'utils/apiList'

export const inventoryTransferApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'inventory-transfer/api',
  endpoints(builder) {
    return {
      fetchInventory: builder.query({
        query({ isForTransaction, size, groupId }) {
          return {
            url: `api/v1/bussines/inventory`,
            params: {
              isForTransaction: isForTransaction,
              size: size,
              groupId,
            },
          }
        },
      }),
      fetchPacking: builder.query({
        query({ size, isForTransaction }) {
          return {
            url: `api/v1/bussines/inventorygroups`,
            params: {
              isForTransaction: isForTransaction,
              size: size,
            },
          }
        },
      }),
      fetchPackingDetail: builder.query({
        query({ id }) {
          return {
            url: `api/v1/bussines/inventorygroups/${id}`,
          }
        },
      }),
      fetchWarehouses: builder.query({
        query({ size }) {
          return {
            url: `${API_WAREHOUSE}?isForTransfer=1${size ? `&size=${size}` : ''}`,
          }
        },
        transformResponse: (response, _meta, arg) => {
          const newData = response?.items?.map((e) => {
            return {
              value: e.id,
              label: e.name,
              ...e,
            }
          })
          return {
            ...response,
            newData,
          }
        },
      }),
      postTransfer: builder.mutation({
        query(payload) {
          return {
            url: `api/v1/bussines/warehousetransfer`,
            method: 'POST',
            body: payload,
          }
        },
      }),
      confirmTransfer: builder.mutation({
        query(payload) {
          return {
            url: `api/v1/bussines/warehousetransfer/${payload?.id}/confirm`,
            method: 'POST',
            body: {
              approved: payload?.body,
            },
          }
        },
      }),
      fetchInventoryTransfer: builder.query({
        query({ page, size = 10, sortby, sortdir, type }) {
          return {
            url: `api/v1/bussines/warehousetransfer`,
            params: {
              page,
              size,
              sortby,
              sortdir,
              type,
            },
          }
        },
      }),
    }
  },
})

export const {
  useFetchWarehousesQuery,
  useLazyFetchWarehousesQuery,
  useFetchInventoryQuery,
  useLazyFetchInventoryQuery,
  useFetchPackingQuery,
  useLazyFetchPackingQuery,
  usePostTransferMutation,
  useFetchInventoryTransferQuery,
  useLazyFetchInventoryTransferQuery,
  useFetchPackingDetailQuery,
  useConfirmTransferMutation,
} = inventoryTransferApiSlice
