import checkedBox from 'assets/icons/checked-box-bordered.svg'
import document from 'assets/icons/document.svg'
import greenWallet from 'assets/icons/green-wallet.svg'
import redWallet from 'assets/icons/red-wallet.svg'
import unCheckedBox from 'assets/icons/unchecked-box-bordered.svg'
import { P } from 'components'
import CustomButton from 'components/Buttons/CustomButton'
import LoadingAnimation from 'components/LoadingAnimation'
import SidebarDetails from 'components/SidebarDetails'
import BreadCrumbs from 'components/breadcrumbs'
import { filterTransaction } from 'constants'
import { useFetchSaldoQuery } from 'features/private-views/dashboard/dashboardApiSlice'
import { groupTransactionsByDate } from 'helpers'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Calendar, Filter, X } from 'react-feather'
import Flatpickr from 'react-flatpickr'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Card, Modal } from 'reactstrap'
import { formatNumber } from 'utils/formatNumber'
import { getBalanceHistory } from './balanceApiAction'
import './index.css'

export default function Budget() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isLoading, isSuccess, data, isError } = useFetchSaldoQuery()
  const [isShowFilter, setIsShowFilter] = useState(false)
  const [isShowFilterDate, setIsShowFilterDate] = useState(false)
  const [isShowTransactionInfo, setIsShowTransactionInfo] = useState(false)
  const [selectedFilterItems, setSelectedFilterItems] = useState([])
  const [confirmedSelectedFilterItems, setConfirmedSelectedFilterItems] =
    useState(filterTransaction)
  const [selectedFilterByDate, setSelectedFilterByDate] = useState([])
  const [page, setPage] = useState(0)
  const [isFetching, setIsFetching] = useState(false)
  const [items, setItems] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [emptyMessage, setEmptyMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [convertSelectedFilterByDate, setConvertSelectedFilteredByDate] =
    useState([])
  const [selectedTransaction, setSelectedTransaction] = useState({})

  useEffect(() => {
    fetchDepoHistory(0, confirmedSelectedFilterItems)
  }, [])

  const fetchDepoHistory = async (page, filters, date) => {
    setIsFetching(true)
    const combinedFilter = filters?.map((filter) => filter?.filterKey).join(',')

    try {
      const result = await dispatch(
        getBalanceHistory({
          page: page,
          pageSize: 50,
          filter: combinedFilter || '',
          date: date,
        }),
      ).unwrap()
      if (page === 0) {
        setItems(result?.items)
      } else {
        setItems((prevItems) => [...prevItems, ...result.items])
      }
      setHasMore(page < result.totalPages - 1)
      if (result?.totalItems === 0 && filters?.length) {
        if (filters?.length) {
          setEmptyMessage('Data dengan filter yang dipilih tidak ditemukan.')
        } else {
          setEmptyMessage('Belum ada data yang ditemukan.')
        }
      }
    } catch (error) {
      console.error('Failed to fetch deposit history:', error)
    } finally {
      setLoading(false)
      setIsFetching(false)
    }
  }

  const loadMore = () => {
    if (!hasMore || isFetching) return

    const newPage = page + 1
    setPage(newPage)
    fetchDepoHistory(
      newPage,
      confirmedSelectedFilterItems,
      convertSelectedFilterByDate,
    )
  }

  const onPressToggle = () => {
    if (isShowFilterDate) {
      setIsShowFilterDate(false)
    } else {
      setConfirmedSelectedFilterItems(selectedFilterItems)
      setIsShowFilterDate(true)
    }
  }

  const onToggleSelection = (item) => {
    setSelectedFilterItems((prevState) => {
      if (prevState.find((el) => el.id === item.id)) {
        return prevState.filter((el) => el.id !== item.id)
      } else {
        return [...prevState, item]
      }
    })
  }

  const applySelection = () => {
    setPage(0)
    setConfirmedSelectedFilterItems(selectedFilterItems)
    fetchDepoHistory(0, selectedFilterItems, convertSelectedFilterByDate)
    setIsShowFilter(false)
  }

  const onSelectFilterByDate = (data) => {
    const timeZone = 'Asia/Jakarta'

    const convertedDate = {
      from: moment.tz(data[0], timeZone).startOf('day').toISOString(),
      to: moment.tz(data[1], timeZone).endOf('day').toISOString(),
    }
    setConvertSelectedFilteredByDate(convertedDate)
    setSelectedFilterByDate(data)
    fetchDepoHistory(0, selectedFilterItems, convertedDate)
    setIsShowFilterDate(false)
  }

  return (
    <>
      <div className="d-flex justify-content-center" style={{ width: '100%' }}>
        <div style={{ width: '100%', maxWidth: '1000px' }} className="px-3">
          <div className="d-flex align-items-start gap-2 pt-3">
            <BreadCrumbs breadCrumbParent={'Uang Belanja'} />
          </div>

          <Card body className="pl-3 py-4 card-custom">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <P fontSize="xlarge" color={'#FFFFFF'}>
                  Nominal
                </P>
                <P className="mt-1" size="large" bold color={'#FFFFFF'}>
                  Rp{formatNumber(data?.wallet1)}
                </P>
              </div>
              <CustomButton
                onClick={() => navigate('/topup')}
                style={{
                  backgroundColor: 'white',
                  paddingLeft: 15,
                  paddingRight: 15,
                }}
              >
                <P color={'black'}>+ Tambah</P>
              </CustomButton>
            </div>
          </Card>

          <Card body className="my-3 px-3" style={{ height: '75vh' }}>
            <P className="text-md" size="large" bold={true}>
              Riwayat Transaksi
            </P>
            <div className="mt-4">
              <div className="dropdown-container">
                <div className="d-flex align-items-center border rounded">
                  <div className="d-flex align-items-center justify-content-center ">
                    <Calendar size={17} className="ms-3" />
                    <Flatpickr
                      options={{
                        mode: 'range',
                        dateFormat: 'd-m-Y',
                        defaultDate: [new Date(), new Date()],
                        onClose: (selectedDate, dateStr, instance) => {
                          onSelectFilterByDate(selectedDate)
                        },
                      }}
                      value={
                        selectedFilterByDate?.length
                          ? selectedFilterByDate
                          : [new Date(), new Date()]
                      }
                      className="form-control flat-picker bg-transparent border-0 shadow-none p-2"
                    />
                  </div>
                  {selectedFilterByDate?.length ? (
                    <div
                      className="p-2 pointer"
                      onClick={() => {
                        setSelectedFilterByDate([])
                        fetchDepoHistory(0, selectedFilterItems)
                      }}
                    >
                      <X size={17} />
                    </div>
                  ) : null}
                </div>
                <div
                  className="my-hover-card px-3"
                  onClick={() => {
                    setIsShowFilter(true)
                    setSelectedFilterItems(confirmedSelectedFilterItems)
                  }}
                >
                  <Filter style={{ marginRight: 10 }} size={17} />
                  <P>Filter</P>
                  <div
                    style={{
                      marginLeft: 10,
                      backgroundColor: '#ECFAFF',
                      width: 25,
                      height: 25,
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                      borderRadius: 99,
                    }}
                  >
                    <P color={'#005370'} size="small">
                      {confirmedSelectedFilterItems?.length}
                    </P>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-space-between mt-3">
              <div className="responsive-height">
                {loading ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <LoadingAnimation style={{ color: '#006386' }} />
                  </div>
                ) : items?.length ? (
                  groupTransactionsByDate(items)?.map((el) => {
                    return (
                      <div className="transactionWrap">
                        <P bold>{el?.createdAt}</P>
                        {el?.list?.map((item, index) => {
                          return (
                            <div
                              className="d-flex justify-content-between mt-4 pointer"
                              onClick={() => {
                                setSelectedTransaction(item)
                                setIsShowTransactionInfo(true)
                              }}
                            >
                              <div className="d-flex">
                                <img
                                  src={item?.amountIn ? greenWallet : redWallet}
                                  id="fishery"
                                  alt="fishery"
                                  loading="lazy"
                                />
                                <div className="px-3">
                                  <P
                                    size="regular"
                                    style={{
                                      fontWeight: 600,
                                    }}
                                  >
                                    {item?.label}
                                  </P>
                                  <P size="regular" color={'#667085'}>
                                    {moment(item?.createdAt).format('HH:mm')}
                                  </P>
                                  {item?.description ? (
                                    <P size="regular" color={'#667085'}>
                                      {item?.description}
                                    </P>
                                  ) : null}
                                </div>
                              </div>
                              <div>
                                <P
                                  color={item?.amountIn ? '#027A48' : '#B42318'}
                                  size="regular"
                                  bold
                                >
                                  {item?.amountIn
                                    ? `+ Rp${formatNumber(item?.amountIn)}`
                                    : `- Rp${formatNumber(item?.amountOut)}`}
                                </P>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    )
                  })
                ) : (
                  <div
                    style={{
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                  >
                    <div
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <img
                        src={document}
                        id="document"
                        alt="document"
                        loading="lazy"
                        style={{ marginBottom: 15 }}
                      />
                      <P>{emptyMessage}</P>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <Button
                  className="button-budget white w-100"
                  onClick={loadMore}
                  disabled={isFetching || !hasMore}
                >
                  {isFetching ? <LoadingAnimation /> : <P>Lihat Berikutnya</P>}
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
      {renderModal()}
    </>
  )

  function renderModal() {
    return (
      <>
        <SidebarDetails
          isOpen={isShowFilter}
          headerClassName="mb-1"
          contentClassName="pt-0"
          title="Pilih tipe transaksi"
          toggleSidebar={() => {
            setIsShowFilter(false)
            setSelectedFilterItems([])
          }}
        >
          <div className="col-space-between mt-3">
            <div>
              {filterTransaction?.map((el, i) => {
                let isSelected = selectedFilterItems.find(
                  (item) => item.id === el.id,
                )

                return (
                  <div
                    className="d-flex mb-4 pointer"
                    onClick={() => onToggleSelection(el)}
                  >
                    <img
                      src={isSelected ? checkedBox : unCheckedBox}
                      id="fishery"
                      alt="fishery"
                      loading="lazy"
                    />
                    <P style={{ marginLeft: 10 }}>{el?.name}</P>
                  </div>
                )
              })}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                className="button-budget white"
                onClick={() => setSelectedFilterItems([])}
              >
                <P>Reset</P>
              </Button>
              <Button className="button-budget color" onClick={applySelection}>
                Terapkan
              </Button>
            </div>
          </div>
        </SidebarDetails>
        <Modal
          isOpen={isShowTransactionInfo}
          centered
          toggle={() => {
            setIsShowTransactionInfo(false)
          }}
          backdrop={true}
        >
          <div className="p-4 w-100">
            <P bold={600} size="medium">
              Catatan
            </P>
            {console.log(selectedTransaction, 'selectedTransaction')}
            <div className="py-4">
              <P>{selectedTransaction?.description || '-'}</P>
            </div>
            <Button
              className="button-budget white w-100"
              onClick={() => setIsShowTransactionInfo(false)}
              disabled={isFetching || !hasMore}
            >
              Tutup
            </Button>
          </div>
        </Modal>
      </>
    )
  }
}
