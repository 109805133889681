import Logo from 'assets/images/pmg-logo-beta.png'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Card, CardText, Container } from 'reactstrap'
import ButtonAction from './ButtonAction'
import ItemsDetail from './Details'
import { getDataTrx } from './requestTransactionAction'
import './style.scss'
import UserInfo from './UserInfo'

const OrderInfoView = () => {
  const [searchParams] = useSearchParams()
  const { detail } = useSelector((s) => s.reqTrxSell)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  let code = searchParams.get('code')
  let amount = searchParams.get('totalAmount')

  useEffect(() => {
    if (!code || !amount) navigate('/')
  }, [code, amount, navigate])

  useEffect(() => {
    if (code && amount) {
      dispatch(getDataTrx({ code, amount }))
    }
  }, [code, amount])

  return (
    <Container>
      <Card
        style={{ maxWidth: 480 }}
        className="px-2 mx-auto d-flex justify-content-center align-items-center"
      >
        <img
          src={Logo}
          alt="Logo PasarMikro"
          className="text-center"
          style={{
            marginTop: 32,
            marginBottom: 24,
            width: '100%',
            maxWidth: '177px',
            height: 'auto',
          }}
        />
        <CardText tag="h4" className="mb-3">
          Permintaan Transaksi
        </CardText>

        <CardText
          className="head-badges w-100"
          style={{ padding: '16px 16px' }}
        >
          <span>Transaksi Pembelian</span>
        </CardText>
        <UserInfo sellerDetail={detail?.sellerDetail} />
        <ItemsDetail
          itemDetail={detail?.itemDetail}
          totalAmount={detail?.totalAmount}
        />
        <ButtonAction code={code} amount={amount} data={detail} />
      </Card>
    </Container>
  )
}

export default OrderInfoView
