import PropTypes from 'prop-types'

const RoundedComponent = ({ childern, ...props }) => {
  return (
    <div
      className="rounded-circle flex-centered"
      style={{ width: '40px', height: '40px' }}
      {...props}
    >
      {childern}
    </div>
  )
}

RoundedComponent.propTypes = {
  childern: PropTypes.node,
}

export default RoundedComponent
