import { createApiSlice } from 'app/createApiSlice'
import {
  API_WAREHOUSE,
  API_WAREHOUSE_CREATE,
  API_WAREHOUSE_STAFF,
} from 'utils/apiList'

export const warehouseApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'warehouse/api',
  endpoints(builder) {
    return {
      fetchWarehouse: builder.query({
        query({ page, size = 10, sortby, sortdir, s }) {
          return {
            url: API_WAREHOUSE,
            params: {
              page,
              size,
              sortby,
              sortdir,
              s: s ?? '',
            },
          }
        },
      }),
      fetchStaffWarehouse: builder.query({
        query({ size }) {
          return {
            url: `${API_WAREHOUSE_STAFF} ${size ? `&size=${size}` : ''}`,
          }
        },
        transformResponse: (response, _meta, arg) => {
          const newData = response?.items?.map((e) => {
            return {
              value: e.userId,
              label: e.name,
              ...e,
            }
          })
          return {
            ...response,
            newData,
          }
        },
      }),
      createWarehouse: builder.mutation({
        query(payload) {
          return {
            url: API_WAREHOUSE_CREATE,
            method: 'POST',
            body: payload,
          }
        },
      }),
      deleteWarehouse: builder.mutation({
        query(id) {
          return {
            url: `${API_WAREHOUSE}/${id}`,
            method: 'DELETE',
          }
        },
      }),
      updateWarehouse: builder.mutation({
        query({ payload, id }) {
          return {
            url: `${API_WAREHOUSE}/${id}`,
            method: 'PATCH',
            body: payload,
          }
        },
      }),
    }
  },
})

export const {
  useFetchWarehouseQuery,
  useFetchStaffWarehouseQuery,
  useCreateWarehouseMutation,
  useUpdateWarehouseMutation,
  useDeleteWarehouseMutation,
} = warehouseApiSlice
